import { HttpHeaders } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import uniqBy from 'lodash-es/uniqBy';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { hTenant } from '../../../../shared/classes/utils/utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { Config, ConfigApi, Facility, LoggerService, Signature } from '../../../../shared/sdk';
import { DESTINATIONS, DESTINATIONS_FOR_BASE } from '../../../trip-manifest/classes/enums';

@Component({
  selector: 'app-dlg-edit-origin-dest',
  templateUrl: './dlg-edit-origin-dest.component.html',
  styleUrls: ['./dlg-edit-origin-dest.component.scss'],
})
export class DlgEditOriginDestComponent {
  destDs$: any = [];

  value: { o; d };

  constructor(
    private dialogRef: MatDialogRef<DlgEditOriginDestComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      signature?: Signature;
      origin: any;
      destination: any;
    },
    protected logger: LoggerService,
    private dss: DataSourceService,
  ) {
    this.value = {
      o: oc(data).origin(),
      d: oc(data).destination(),
    };

    this.destDs$ = oc(this.data).signature.tenantId()
      ? this.getDestinations$(this.data.signature.tenantId).pipe(
          switchMap(async destinations => {
            return new DataSource({
              store: new ArrayStore({
                data: destinations,
                key: 'ID',
              }),
              group: 'Group',
            });
          }),
        )
      : of([]);
  }

  getDestinations$(tenantId: number) {
    return this.dss
      .getApi(Facility)
      .findById<Facility>(tenantId)
      .pipe(
        withLatestFrom(this.dss.getApi<ConfigApi>(Config).getAllDestinations(hTenant(tenantId))),
        map(([tenant, destList]) => {
          destList = destList.map(dest => ({
            ID: dest.short,
            Name: `${dest.name} [${dest.address}]`,
            Group: dest.group,
          }));

          let ds: any[] = [];

          // TODO: remove harcode
          if (tenant.type === 'BASE') {
            ds = DESTINATIONS_FOR_BASE;
          } else {
            ds = DESTINATIONS;
          }

          ds = uniqBy(ds.concat(destList), 'ID');
          return ds;
        }),
      );
  }
}
