import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import {
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService } from '../../../../shared/sdk';
import moment from 'moment';

@Component({
  selector: 'app-key-performance-indicators',
  templateUrl: './key-performance-indicators.component.html',
  styleUrls: ['./key-performance-indicators.component.scss'],
})
export class KeyPerformanceIndicatorsComponent extends ABaseComponent implements OnInit, AfterViewInit {
  // isSU$: Observable<boolean>;

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;

  periods: any = [
    { name: 'Last 7 Days', value: [7, 'days'] },
    { name: 'Last Month', value: [1, 'month'] },
    { name: 'Last 3 Months', value: [3, 'months'] },
    { name: 'Last 6 Months', value: [6, 'months'] },
  ];
  period: any = this.periods[3].value;

  groups: any = [
    { name: 'Daily', value: 0 },
    { name: 'Weekly', value: 1 },
    { name: 'Monthly', value: 2 },
  ];
  group: any = this.groups[0].value;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private sss: StateStoreService,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '7276d037-36fa-5e37-ab13-31076ee1b3b9',
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.rebuild();
  }

  rebuild() {
    this.dso = this.buildDataSource();
  }

  grid_onInitialized(e) {}

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      ...{ name: 'refresh', locateInMenu: 'auto', location: 'after', widget: 'dxButton', showText: 'inMenu' },
      options: { icon: 'refresh', text: 'Refresh', hint: 'Refresh', onClick: () => e.component.refresh() },
    });
  }

  ngAfterViewInit(): void {}

  getCellClass(val, dayRange) {
    if (!val) return '';
    const days = [1, 5, 22];
    const range = [days[this.group] * dayRange[0], days[this.group] * dayRange[1]];
    if (val < range[0]) return 'red';
    if (val >= range[0] && val < range[1]) return 'orange';
    return 'green';
  }

  private buildDataSource() {
    const self = this;
    const col = 'EmployeeOnDuty';
    const dayly = {
      $concat: [{ $substr: ['$day', 0, 4] }, '-', { $substr: ['$day', 4, 2] }, '-', { $substr: ['$day', 6, 2] }],
    };
    const $week = {
      $dateFromString: {
        dateString: {
          $concat: [{ $substr: ['$day', 0, 4] }, '-', { $substr: ['$day', 4, 2] }, '-', { $substr: ['$day', 6, 2] }],
        },
      },
    };
    const groupWeekly = {
      $concat: [
        { $substr: ['$day', 0, 4] },
        '-W',
        {
          $cond: {
            if: { $lt: [{ $week }, 10] },
            then: { $concat: ['0', { $toString: { $week } }] },
            else: { $toString: { $week } },
          },
        },
      ],
    };
    const monthly = { $concat: [{ $substr: ['$day', 0, 4] }, '-', { $substr: ['$day', 4, 2] }] };

    const _perSize2 = (num, count) => ({
      $cond: { if: { $eq: [count, 0] }, then: 0, else: { $round: [{ $divide: [num, count] }, 2] } },
    });

    const aggregate = [
      { $match: { day: { $gte: +moment().subtract(this.period[0], this.period[1]).format('YYYYMMDD') } } },
      {
        $group: {
          _id: [dayly, groupWeekly, monthly][this.group],
          uniqueVehicles: { $addToSet: '$vehicles' },
          employeeIds: { $addToSet: '$employeeId' },
          escortIds: { $addToSet: '$escortIds' },
          clients: { $addToSet: '$consumers' },
          actualTripsCount: { $sum: '$signaturesCount' },
          tripsOnManifestCount: { $sum: '$tripsOnManifestCount' },
          canceledTripsCount: { $sum: '$canceledTripsCount' },
          loadDuration: {
            $sum: { $toInt: { $divide: [{ $subtract: ['$onDutyDuration', '$freeTimeDuration'] }, 3600] } },
          },
          freeTimeDuration: { $sum: { $toInt: { $divide: ['$freeTimeDuration', 3600] } } },
          onDutyEngineOnDuration: { $sum: { $toInt: { $divide: ['$onDutyEngineOnDuration', 3600] } } },
          onDutyEngineOffDuration: { $sum: { $toInt: { $divide: ['$onDutyEngineOffDuration', 3600] } } },
          onDutyIdleDuration: { $sum: { $toInt: { $divide: ['$onDutyIdleDuration', 3600] } } },
          distance: { $sum: { $toInt: '$distance' } },
        },
      },
      {
        $project: {
          _id: 1,
          vehiclesCount: {
            $size: {
              $reduce: {
                input: '$uniqueVehicles',
                initialValue: [],
                in: { $setUnion: ['$$value', '$$this.internalId'] },
              },
            },
          },
          employeesCount: {
            $sum: [
              {
                $size: {
                  $reduce: {
                    input: '$escortIds',
                    initialValue: '$employeeIds',
                    in: { $setUnion: ['$$value', '$$this'] },
                  },
                },
              },
            ],
          },
          clientsCount: {
            $size: { $reduce: { input: '$clients', initialValue: [], in: { $setUnion: ['$$value', '$$this.id'] } } },
          },
          subscribedClientsCount: {
            $size: {
              $reduce: {
                input: '$clients',
                initialValue: [],
                in: { $setUnion: ['$$value', { $filter: { input: '$$this', as: 'this', cond: '$$this.subscribed' } }] },
              },
            },
          },
          actualTripsCount: 1,
          tripsOnManifestCount: 1,
          canceledTripsCount: 1,
          loadDuration: 1,
          freeTimeDuration: 1,
          onDutyEngineOnDuration: 1,
          onDutyEngineOffDuration: 1,
          onDutyIdleDuration: 1,
          distance: 1,
        },
      },
      {
        $project: {
          _id: 1,
          vehiclesCount: 1,
          employeesCount: 1,
          clientsCount: 1,
          subscribedClientsCount: 1,
          actualTripsCount: 1,
          actualTripsCountPerVehicle: _perSize2('$actualTripsCount', '$vehiclesCount'),
          tripsOnManifestCount: 1,
          tripsOnManifestCountPerVehicle: _perSize2('$tripsOnManifestCount', '$vehiclesCount'),
          canceledTripsCount: 1,
          loadDuration: 1,
          loadDurationPerVehicle: _perSize2('$loadDuration', '$vehiclesCount'),
          freeTimeDuration: 1,
          freeTimeDurationPerVehicle: _perSize2('$freeTimeDuration', '$vehiclesCount'),
          onDutyEngineOnDuration: 1,
          onDutyEngineOnDurationPerVehicle: _perSize2('$onDutyEngineOnDuration', '$vehiclesCount'),
          onDutyEngineOffDuration: 1,
          onDutyEngineOffDurationPerVehicle: _perSize2('$onDutyEngineOffDuration', '$vehiclesCount'),
          onDutyIdleDuration: 1,
          onDutyIdleDurationPerVehicle: _perSize2('$onDutyIdleDuration', '$vehiclesCount'),
          distance: 1,
          distancePerVehicle: _perSize2('$distance', '$vehiclesCount'),
        },
      },
    ];
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
    });

    return { store } as DataSourceOptions;
  }

  calculateYesFilterExpression = function (val, op, target) {
    return [this.dataField, ((op === '<>' || op === 'notcontains') && '<>') || '=', 'yes'.includes(val.toLowerCase())];
  };
}

