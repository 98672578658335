import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { EmployeeView, EmployeeViewApi, LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-clone-log-details-tab-created-schedules',
  templateUrl: './clone-log-details-tab-created-schedules.component.html',
  styleUrls: ['./clone-log-details-tab-created-schedules.component.scss'],
  providers: [],
})
export class CloneLogDetailsTabCreatedSchedulesComponent extends ABaseComponent {
  _log: any;

  @Input()
  set log(v: any) {
    this._log = v;
    this.changeHandle();
  }
  get log() {
    return this._log;
  }

  dso: DataSourceOptions;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
  ) {
    super(logger);
  }

  async changeHandle() {
    if (this.log) {
      if (this.log.createdSchedules && !this.log.createdSchedules.some(s => s.driver)) {
        this.ui.showLoading();
        const drivers = await this.dss
          .getApi<EmployeeViewApi>(EmployeeView)
          .find<EmployeeView>(
            { where: { employeeId: { inq: [...new Set(this.log.createdSchedules.map(e => e.driverId))] } } },
            hAll,
          )
          .toPromise();
        const drviersMap = drivers.reduce((p, d) => ({ ...p, [d.id]: d }), {});
        this.log.createdSchedules = this.log.createdSchedules.map(s => ({ ...s, driver: drviersMap[s.driverId] }));
        this.ui.hideLoading();
      }
      this.dso = this.log.createdSchedules || [];
    }
  }
}
