import { ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { DataSourceService } from '../../../shared/modules/my-common/services/datasource.service';
import { CustomFieldsComponent } from '../../../shared/modules/ui/components/custom-fields/custom-fields.component';
import { getValuePropNameByFieldType } from '../../../shared/modules/ui/components/custom-fields/utils';
import { FormHelperService } from '../../../shared/modules/ui/services/form-helper.service';
import { UploadHelperService } from '../../../shared/modules/ui/services/upload-helper.service';
//
import {
  CustomField,
  Document,
  DocumentApi,
  DocumentType,
  DocumentTypeApi,
  LoggerService,
  LoopBackFilter,
  ObjectCFV,
  ObjectCFVApi,
  Vehicle,
  VehicleApi,
} from '../../../shared/sdk';
import { ABaseDocumentFormComponentWithUploader } from './a-base-document-form-with-uploader.component';

export abstract class ABaseDocumentFormComponentWithCustomsFields extends ABaseDocumentFormComponentWithUploader {
  customFields: CustomField[] = [];

  @ViewChild(CustomFieldsComponent, { static: true }) customFieldsCtrl: CustomFieldsComponent;

  protected constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected uploadHelper: UploadHelperService,
    protected dss: DataSourceService,
    protected helper: FormHelperService<Document>,
  ) {
    super(logger, fb, uploadHelper, dss, helper);

    this.form
      .get('documentTypeId')
      .valueChanges.pipe(takeUntil(this.$onDestroy$))
      .subscribe(async docTypeId => {
        // this.customFields = [];
        this.customFields = await this.getDocTypeCustomFields(docTypeId);
      });
  }

  protected get ModelClass(): any {
    return Document;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: ['documentType', 'owner', 'forObject', { customFieldValues: ['customField'] }, 'customFields'],
    };
  }

  protected buildForm(): void {
    this.formConfigMap.set('', {
      id: [],
      name: ['', Validators.required],
      notes: [],
      objectType: [],
      objectId: [],
      documentTypeId: ['', Validators.required],

      _cfv: this.fb.array([]),
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }

  protected setFormValues(model: Document, isReset: boolean = true, resetValidators: boolean = true): void {
    super.setFormValues(model, isReset, resetValidators);

    if (this.customFieldsCtrl && isReset) {
      this.customFieldsCtrl.resetForm();
    }
  }

  protected async beforeSubmittingAsync(data: any): Promise<void> {
    await super.beforeSubmittingAsync(data);

    const documentApi: DocumentApi = this.dss.getApi<DocumentApi>(Document);
    const objectCFVApi: ObjectCFVApi = this.dss.getApi<ObjectCFVApi>(ObjectCFV);

    // clear model custom fields
    if (this.model) {
      const cfvs: ObjectCFV[] = (await documentApi.getCustomFieldValues(this.modelId).toPromise()) || [];

      await Promise.all(cfvs.map(async (cfv: ObjectCFV) => await objectCFVApi.deleteById(cfv.id).toPromise()));
    }
  }

  protected async afterSubmittedAsync(data: any, obj: any): Promise<void> {
    await super.afterSubmittedAsync(data, obj);

    const documentApi: DocumentApi = this.dss.getApi<DocumentApi>(Document);
    const objectCFVApi: ObjectCFVApi = this.dss.getApi<ObjectCFVApi>(ObjectCFV);

    // reset model custom fields
    const cfs: CustomField[] = this.customFields;
    await Promise.all(
      cfs
        .map(cf => [cf, this.buildCustomFieldValue(cf)])
        .filter(([cf, cfv]) => !!cfv)
        .map(async ([cf, cfv]) => {
          if (cf['field'] === 'regCardExpDate' && obj.objectType == 'Vehicle') {
            const api = await this.dss.getApi<VehicleApi>(Vehicle);
            const veh: Vehicle = await api.findById<Vehicle>(obj.objectId).toPromise();
            veh['registrationExp'] = cfv['valueDate'];
            await api.mySaveWithRelated(veh.id, veh, hAll).toPromise().catch(console.error);
          }
          await documentApi.linkCustomFields(obj.id, cf.id, cfv).toPromise().catch(console.error);
        }),
    );
  }

  private async getDocTypeCustomFields(docTypeId: number): Promise<CustomField[]> {
    const api: DocumentTypeApi = this.dss.getApi<DocumentTypeApi>(DocumentType);
    return docTypeId ? (await api.getCustomFields(docTypeId).toPromise()) || [] : [];
  }

  private buildCustomFieldValue(cf: CustomField): ObjectCFV {
    const idx = this.customFields.findIndex(_cf => _cf.id === cf.id);
    const valuePropName = getValuePropNameByFieldType(cf.type);
    let value = this.customFieldsCtrl ? this.customFieldsCtrl.formArray.at(idx).get(cf.field).value : undefined;

    if (!value) {
      return undefined;
    }

    if (['DATE', 'EXPIRATION_DATE'].includes(cf.type)) {
      value = moment(value).format('YYYY-MM-DD');
    }

    return {
      objectType: Document.getModelName(),
      [valuePropName]: value,
    } as ObjectCFV;
  }
}
