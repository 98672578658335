import { HttpHeaders } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DxToolbarComponent } from 'devextreme-angular/ui/toolbar';
import moment from 'moment';
import { takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { headersAllTenantsAppend } from '../../../../shared/classes/utils/utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import { LoggerService, LoopBackFilter, VehicleCheckUp } from '../../../../shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { DailyChecksDetailsTabsComponent } from '../daily-checks-details-tabs/daily-checks-details-tabs.component';

@Component({
  selector: 'app-daily-checks-details',
  templateUrl: './daily-checks-details.component.html',
  styleUrls: ['./daily-checks-details.component.scss'],
})
export class DailyChecksDetailsComponent extends ABaseModelLoaderComponent<VehicleCheckUp> {
  toolbarItems: any[] = [];

  @ViewChild('toolbar', { static: true }) toolbar: DxToolbarComponent;
  @ViewChild('details', { static: true }) details: DailyChecksDetailsTabsComponent;

  constructor(
    protected logger: LoggerService,
    protected dss: DataSourceService,
    protected empHelper: EmployeeHelperService,
  ) {
    super(logger, dss);

    this.buildToolbarItems();

    this.afterLoaded
      .pipe(
        tap((model: VehicleCheckUp) => this.buildToolbarItems(model)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  protected get ModelClass(): any {
    return VehicleCheckUp;
  }

  protected customHeaders(headers: HttpHeaders): HttpHeaders {
    headers = super.customHeaders(headers);
    headers = headersAllTenantsAppend(headers);
    return headers;
  }

  protected get filter(): LoopBackFilter {
    return { include: ['vehicle', { employee: ['person'] }] };
  }

  private buildToolbarItems(model: VehicleCheckUp = null) {
    if (model) {
      this.toolbarItems = [
        {
          location: 'before',
          locateInMenu: 'never',
          text:
            [
              '#' + oc(model).vehicle.internalId(),
              oc(model).vehicle.state(),
              oc(model).vehicle.type(),
              `${oc(model).vehicle.year()} ${oc(model).vehicle.make()} ${oc(model).vehicle.model()}`,
              `VIN ${oc(model.vehicle)
                .vin('')
                .substr(oc(model.vehicle).vin('').length - 6)}`,
            ].join(' - ') + ' -',
        },
        {
          location: 'before',
          locateInMenu: 'never',
          text: `by ${this.empHelper.displayExpr(oc(model).employee())} -`,
        },
        {
          location: 'before',
          locateInMenu: 'never',
          text: `for ${moment(model.dateTime).format('YYYY-MM-DD HH:mm')} -`,
        },
      ];
    } else {
      this.toolbarItems = [
        {
          location: 'center',
          locateInMenu: 'never',
          text: this.errorMessage || 'Not selected',
        },
      ];
    }
  }

  repaint() {
    this.toolbar.instance.repaint();
    this.details.repaint();
  }
}
