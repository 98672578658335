import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import moment from 'moment';
import { asShortDate } from 'src/app/shared/classes/utils/time.utils';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from 'src/app/shared/modules/ui/services/grid-helper.service';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { Employee, EmployeeApi, EmployeeWorkingTime, EmployeeWorkingTimeApi, LoggerService } from 'src/app/shared/sdk';
import { PERSON_SEXES } from '../../../classes/enums';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-employee-working-time-grid',
  templateUrl: './employee-working-time-grid.component.html',
  styleUrls: ['./employee-working-time-grid.component.scss'],
  providers: [HelperService],
})
export class EmployeeWorkingTimeGridComponent extends ABaseComponent implements OnInit {
  @ViewChild('eventsPopupContent', { static: false }) eventsPopupContent: TemplateRef<any>;
  @ViewChild('tardinessPopupContent', { static: false }) tardinessPopupContent: TemplateRef<any>;

  popupContent: TemplateRef<any>;

  sexes = PERSON_SEXES;
  weeks: { name: string; value: string }[] = [];
  week: string;
  days: { caption: string; fieldName: string; checked: boolean; estimated: boolean }[] = [];
  @Input() selectedDate: string;

  dataSource: any[] = [];
  employees: Employee[] = [];
  workingTimes: EmployeeWorkingTime[] = [];

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private common: CommonService,
    private dss: DataSourceService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    private ui: UiService,
  ) {
    super(logger);
  }

  ngOnInit() {
    super.ngOnInit();
    this.generateWeeks();
    this.generateDays();
    this.loadData();
  }

  async loadData() {
    this.ui.showLoading();
    const [employees, workingTimes]: [any[], any[]] = await Promise.all([
      (!this.employees.length && this.getEmployees()) || this.employees,
      this.getEmployeeWorkingTimes(this.week),
    ]);
    this.employees = employees;
    this.workingTimes = workingTimes;
    this.buildData();
    this.ui.hideLoading();
  }

  buildData() {
    let [wtMap, otMax, otMin] = this.workingTimes.reduce(
      (p, v) => {
        const regMin = 40 * 60;
        const dd = this.days.filter(d => d.checked);
        const totalMinutes = dd.reduce((p, d) => v[d.fieldName] + p, 0);
        const [rm, om] = totalMinutes > regMin ? [regMin, totalMinutes - regMin] : [totalMinutes, 0];
        const days = dd.filter(d => v[d.fieldName] > 0).length;
        const otMax = Math.max(p[1], om);
        const otMin = om === 0 ? p[2] : Math.min(p[2], om);
        return [
          { ...p[0], [v.employeeId]: { ...v, totalMinutes, regularMinutes: rm, overtimeMinutes: om, days } },
          otMax,
          otMin,
        ];
      },
      [{}, 0, Number.MAX_SAFE_INTEGER],
    );
    if (otMin > otMax) otMin = otMax;
    this.dataSource = this.employees
      .filter(e => wtMap[e.id])
      .map(e => ({ ...e, ...wtMap[e.id], class: this.getOvertimeClass(wtMap[e.id].overtimeMinutes, otMax, otMin) }));
  }

  getOvertimeClass(overtimeMinutes, otMax, otMin): string {
    if (!otMax || !overtimeMinutes) return '';
    const range = otMax - otMin;
    const quarter = range / 3;
    if (overtimeMinutes < otMin + quarter) return 'bg-success';
    else if (overtimeMinutes < otMin + 2 * quarter) return 'bg-warning';
    else return 'bg-danger';
  }

  generateWeeks() {
    const currentDate = moment(this.selectedDate);
    this.week = asShortDate(currentDate.startOf('week'));
    const previousMonth = currentDate.clone().subtract(1, 'months');
    const nextMonth = currentDate.clone().add(1, 'months');

    const startOfPreviousMonth = previousMonth.clone().startOf('month').startOf('week');
    const endOfNextMonth = nextMonth.clone().endOf('month').endOf('week');

    let current = startOfPreviousMonth.clone();

    while (current.isBefore(endOfNextMonth)) {
      const startOfWeek = current.clone().startOf('week');
      const endOfWeek = current.clone().endOf('week');

      const weekName = `${startOfWeek.format('YYYY')}-${startOfWeek.isoWeek()} ${startOfWeek.format(
        'MMM DD',
      )} - ${endOfWeek.format('MMM DD')}`;
      const weekValue = asShortDate(startOfWeek);

      this.weeks.push({ name: weekName, value: weekValue });

      current.add(1, 'week');
    }
  }

  generateDays() {
    this.days = [];
    for (let i = 0; i < 7; i++) {
      const [caption, fieldName, estimated] = this.getDay(i, true) as [string, string, boolean];
      const selected = moment(this.selectedDate).startOf('day');
      const day = moment(this.week).add(i - 1, 'days');
      const checked = !estimated ? true : day.isBefore(selected);
      this.days.push({ caption, fieldName, checked, estimated });
    }
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  calendar_onValueChanged(e) {
    this.generateDays();
    this.loadData();
  }

  day_onChanged(event: Event, day) {
    const inputElement = event.target as HTMLInputElement;
    day.checked = inputElement.checked;
    this.buildData();
  }

  getDay(dayNum: number, full = false) {
    const day = moment(this.week).add(dayNum, 'days');
    const estimated = day.isAfter(moment().subtract(1, 'day')) ? ' (estimated)' : '';
    const ddd = day.format('ddd');
    const fieldName = `${ddd.toLocaleLowerCase()}Minutes`;
    const caption = `${ddd}, ${day.format('MMM DD')}${estimated}`;
    if (full) return [caption, fieldName, !!estimated];
    return caption;
  }

  getHours(minutes: number) {
    if (!minutes) return '00:00';
    let hh = Math.floor(minutes / 60);
    let mm = minutes % 60;
    return `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`;
  }

  getEmployees() {
    return this.dss
      .getApi<EmployeeApi>(Employee)
      .find({
        where: { employeePositionId: { inq: [39, 40, 273] } },
        include: ['employeePosition', 'person'],
      })
      .toPromise();
  }

  getEmployeeWorkingTimes(date: string): Promise<EmployeeWorkingTime[]> {
    return this.dss.getApi<EmployeeWorkingTimeApi>(EmployeeWorkingTime).findAllByDate(date, hAll).toPromise();
  }
}
