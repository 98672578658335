import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import DxDataGrid from 'devextreme/ui/data_grid';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';
import {
  dxStoreLoadHooks,
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import {
  Consumer,
  ConsumerApi,
  EmployeeView,
  EmployeeViewApi,
  Facility,
  FacilityApi,
  LoggerService,
  MyUser,
  MyUserApi,
} from '../../../../shared/sdk';
import { DlgManifestGridComponent } from '../dlg-manifest-grid/dlg-manifest-grid.component';

@Component({
  selector: 'app-clone-log-grid',
  templateUrl: './clone-log-grid.component.html',
  styleUrls: ['./clone-log-grid.component.scss'],
})
export class CloneLogGridComponent extends ABaseComponent implements OnInit, AfterViewInit {
  modifiedSubscription: Subscription;
  @Output() mySelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions;
  tenantsMap: any = null;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    protected dialog: MatDialog,
    private dss: DataSourceService,
    private gridHelper: GridHelperService,
    protected ui: UiService,
  ) {
    super(logger);

    // this.grid_stateStoring = {
    //   enabled: false,
    //   type: 'localStorage',
    //   storageKey: '7776d037-26fa-4e37-ab41-14076ee8b4s1',
    // };

    this.dso = this.buildDataSource();
  }

  ngOnInit() {
    this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
      // if ([AutoDispatchLog.getModelName()].includes(modelName)) {
      //   if (this.grid) {
      //     this.grid.instance.refresh();
      //   }
      // }
    });
  }

  ngOnDestroy(): void {
    this.modifiedSubscription.unsubscribe();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });

    (e.component as DxDataGrid).deselectAll();
    this.mySelected.emit([]);
  }

  grid_onSelectionChanged(event: any): void {
    const items = (event.component as DxDataGrid).getDataSource().items();

    if (items.find(i => event.currentSelectedRowKeys.some(k => k._id === i._id))) {
      this.mySelected.emit(event.selectedRowsData);
    }
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      ...{ name: 'refresh', locateInMenu: 'auto', location: 'after', widget: 'dxButton', showText: 'inMenu' },
      options: { icon: 'refresh', text: 'Refresh', hint: 'Refresh', onClick: () => e.component.refresh() },
    });
  }
  mode_calculateValue = (row: any) => (row.isManual ? 'Manual' : 'Auto');

  cloningContent_calculateFilterExpression = (filterValue, selectedFilterOperation) => {
    if (filterValue === 'Manifest') return [['createdManifest', '<>', null], 'and', ['createdSchedules', '=', null]];
    if (filterValue === 'Schedules') return [['createdSchedules', '<>', null], 'and', ['createdManifest', '=', null]];
    if (filterValue === 'Mixt') return [['createdSchedules', '<>', null], 'and', ['createdManifest', '<>', null]];

    return [];
  };

  ngAfterViewInit(): void {}

  async manifest_onClick(manifest: any, tenant) {
    this.ui.showLoading();
    const [consumers, drivers] = await Promise.all([
      this.dss
        .getApi<ConsumerApi>(Consumer)
        .find<Consumer>(
          {
            where: { id: { inq: [...new Set(manifest.data.map(({ c }) => c))] } },
            include: [{ person: { contact: ['addresses', 'phones'] } }],
          },
          hAll,
        )
        .toPromise(),
      this.dss
        .getApi<EmployeeViewApi>(EmployeeView)
        .find<EmployeeView>({ where: { employeeId: { inq: [...new Set(manifest.data.map(({ e }) => e))] } } }, hAll)
        .toPromise(),
    ]);
    this.ui.hideLoading();

    const driversMap = drivers.reduce((p, d) => ({ ...p, [d.id]: d }), {});
    const consumersMap = consumers.reduce((p, c) => ({ ...p, [c.id]: c }), {});
    const d = moment(manifest.date).format('M/D/YYYY');
    const title = `${tenant} Manifest for ${d}`;

    void this.dialog.open<any, any, number>(DlgManifestGridComponent, {
      hasBackdrop: true,
      data: { title, manifest, consumersMap, driversMap },
      minWidth: 1200,
    });
  }

  private buildDataSource() {
    const self = this;
    const col = 'ManifestScheduleCloneLog';
    const aggregate = [{ $sort: { createdAt: -1 } }];
    const store = new CustomStore({
      useDefaultSearch: true,
      cacheRawData: false,
      load: async (loadOptions: LoadOptions): Promise<any> => {
        return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
      },
      byKey: async (key: any | string | number): Promise<any> => {
        return gqlMongoByKey(self.dss, col, key).toPromise();
      },
      totalCount: async (loadOptions: LoadOptions): Promise<number> => {
        return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
      },
    });

    dxStoreLoadHooks(store, undefined, async (args: any[], [rows]: any[]) => {
      if (!this.tenantsMap) {
        const tenants = await this.dss.getApi<FacilityApi>(Facility).find<Facility>().toPromise();
        this.tenantsMap = tenants.reduce((p, t) => ({ ...p, [t.id]: t.shortname }), {});
      }
      const users: MyUser[] = await this.dss
        .getApi<MyUserApi>(MyUser)
        .find<MyUser>({
          where: { id: { inq: rows.map(r => r.userId) } },
          include: [{ employee: ['person'] }],
        })
        .toPromise();
      const usersMap = users.reduce(
        (p, u) => ({ ...p, [u.id]: new FullNamePipe(this.config).transform(u.employee) }),
        {},
      );
      return [
        rows.map(r => ({
          ...r,
          user: usersMap[r.userId],
          tenant: r.createdManifest && this.tenantsMap[r.createdManifest.tenantId],
        })),
      ];
    });

    return { store } as DataSourceOptions;
  }
}
