import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import { takeUntil, tap } from 'rxjs/operators';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { CommonService } from 'src/app/shared/modules/my-common/services/common.service';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { StateStoreService } from 'src/app/shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from 'src/app/shared/modules/ui/services/grid-helper.service';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { Document, DocumentApi, Employee, Facility, FacilityApi, LoggerService, Person } from 'src/app/shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../../employee/services/helper.service';
import { EMPLOYEE_STATUSES, EMPLOYEE_TYPES, PERSON_SEXES } from '../../../classes/enums';
import { HelperService } from '../../../services/helper.service';
import { EmployeeFormComponent } from '../../employee-form/employee-form.component';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-employee-ext-grid',
  templateUrl: './employee-ext-grid.component.html',
  styleUrls: ['./employee-ext-grid.component.scss'],
  providers: [HelperService],
})
export class EmployeeExtGridComponent extends ABaseComponent implements OnInit {
  sexes = PERSON_SEXES;
  types = EMPLOYEE_TYPES;
  statuses = EMPLOYEE_STATUSES;

  dso: DataSourceOptions;

  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<Employee[]> = new EventEmitter<Employee[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    public helper: HelperService,
    public employeeHelper: EmployeeHelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);
    const self = this;

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'b64648cd-7c24-4455-a355-7ea404328314',
    };

    // this.dso = dss.getDataSourceOptions(Employee);

    const so = this.dss.getStoreOptions(Employee, Employee, false);
    so.customFilter = { include: ['employeePosition', { person: { contact: ['phones', 'emails'] } }] };
    const store = new CustomStore(so);

    dxStoreLoadHooks(store, undefined, async (args: any[], [empls]: any[]) => {
      const [facilities, lastDateOnDutyMap, counts, docs] = await Promise.all([
        this.dss.getApi<FacilityApi>(Facility).find<Facility>().toPromise(),
        this.employeeHelper.getLastDateOnDutyMap(),
        gqlMongoLoad(this.dss, 'VehicleGeotabEvent', {}, this.getEventCountsAggregate(empls)).pipe().toPromise(),
        this.dss
          .getApi<DocumentApi>(Document)
          .find<Document>(
            {
              where: { objectType: 'Employee' },
              include: ['customFieldValues', 'customFields'],
            },
            hAll,
          )
          .toPromise(),
      ]);

      const fMap = facilities.reduce((p, v) => ({ ...p, [v.id]: v }), {});
      let map = lastDateOnDutyMap;
      map = counts.reduce((p, v) => ({ ...p, [v._id]: { ...v, ...map[v._id] } }), map);
      map = docs.reduce((p, v) => {
        const cfMap = (v.customFields || []).reduce((pcf, { id, field }) => ({ ...pcf, [id]: field }), {});
        return {
          ...p,
          [v.objectId]: {
            ...{
              ...p[v.objectId],
              ...v.customFieldValues.reduce((pcf, cfv) => ({ ...pcf, [cfMap[cfv.customFieldId]]: cfv.value }), {}),
            },
            ...map[v.objectId],
          },
        };
      }, map);
      return [
        empls.map(e => ({
          ...e,
          ...map[e.id],
          lastTenant: map[e.id] && map[e.id].last && fMap[map[e.id].last.tenantId],
        })),
      ];
    });

    this.dso = { store } as DataSourceOptions;

    this.dso.postProcess = (data: Array<any>) => {
      data.forEach(rec => (rec._anotherTenant = this.common.auth.getCurrentTenant() !== rec.tenantId));
      return data;
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.dss.modifiedEvent
      .pipe(
        tap(modelName => {
          if ([Employee.getModelName(), Person.getModelName()].includes(modelName)) {
            if (this.grid) {
              this.grid.instance.refresh();
            }
          }
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      name: 'newEmployee',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'inMenu',
      options: {
        icon: 'fas fa-user-plus',
        text: 'New employee',
        hint: 'Create new employee',
        onClick: this.grid_toolbar_newEmployee_onClick.bind(this),
      },
    });
  }

  grid_onSelectionChanged(event: any): void {
    this.mySelected.emit(event.selectedRowsData);
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;
    const title = `Edit: ${this.helper.displayExpr(e.data)}`;

    this.ui.openEditDialog({
      modelId: e.key,
      ModelClass: Employee,
      FormComponentClass: EmployeeFormComponent,
      title,
    });
  }

  grid_toolbar_newEmployee_onClick() {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: Employee,
      FormComponentClass: EmployeeFormComponent,
    });
  }

  grid_onCellPrepared(e) {
    // console.log(e);

    if (e.rowType === 'data') {
      if (e.data._anotherTenant) {
        (e.cellElement as HTMLElement).style.color = 'darkgray';
      }
    }
  }

  getPhoneCellValue = (e: Employee) => e.person.contact.phones[0] && e.person.contact.phones[0].value;

  getEmailCellValue = (e: Employee) => e.person.contact.emails[0] && e.person.contact.emails[0].value;

  isEditDeleteVisible(e) {
    return !e.row.data._anotherTenant;
  }

  getEventCountsAggregate(empls: any[]) {
    return [
      {
        $match: {
          employeeId: { $in: empls.map(e => e.id) },
          $expr: {
            $gte: ['$dateTime', { $dateFromString: { dateString: moment().subtract(90, 'days').toISOString() } }],
          },
        },
      },
      {
        $project: {
          employeeId: 1,
          compliance: { $cond: [{ $eq: ['$eventType', 'Compliance'] }, 1, 0] },
          safety: { $cond: [{ $eq: ['$eventType', 'Safety'] }, 1, 0] },
        },
      },
      {
        $group: { _id: '$employeeId', compliance: { $sum: '$compliance' }, safety: { $sum: '$safety' } },
      },
    ];
  }
}
