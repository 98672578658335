import * as tslib_1 from "tslib";
import { ApplicationRef, NgZone, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
// import { captureException } from '@sentry/browser';
import { alert } from 'devextreme/ui/dialog';
import { concat, interval, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, exhaustMap, first, takeUntil, tap } from 'rxjs/operators';
import { LoggerService } from '../shared/sdk';
import * as i0 from "@angular/core";
import * as i1 from "../shared/sdk/services/custom/logger.service";
import * as i2 from "@angular/service-worker";
/**
 * SwUpdatesService
 *
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 1 minute.
 * 3. Whenever an update is available, it activates the update.
 */
export class SwUpdatesService {
    constructor(logger, appRef, swu, zone) {
        this.logger = logger;
        this.appRef = appRef;
        this.swu = swu;
        this.zone = zone;
        this.$onDestroy$ = new Subject();
        this.checkInterval = 30000; // 30 sec
        if (!this.swu.isEnabled) {
            this.log('SwUpdate not enabled');
            return;
        }
        const appIsStable$ = this.appRef.isStable.pipe(distinctUntilChanged(), tap(v => this.log('stable: ' + v)), first(v => v === true));
        const errHandler = err => {
            try {
                console.log('Error checking for update:', err);
                // captureException(err);
            }
            catch (e) {
                console.log('Error capturing exception:', e);
            }
            return of(null);
        };
        // Activate available updates.
        this.swu.available
            .pipe(tap(evt => this.log('Update available:', evt)), exhaustMap(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield alert('There is app update available. The page will be reloaded.', 'Update available');
            yield this.swu.activateUpdate();
        })), catchError(errHandler), takeUntil(this.$onDestroy$))
            .subscribe();
        // Activated updates.
        this.swu.activated
            .pipe(tap(evt => {
            this.log('Update activated:', evt);
            document.location.reload();
        }), catchError(errHandler), takeUntil(this.$onDestroy$))
            .subscribe();
        this.zone.runOutsideAngular(() => {
            // Periodically check for updates (after the app is stabilized).
            concat(appIsStable$, interval(this.checkInterval))
                .pipe(exhaustMap(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.log('Checking for update...');
                console.time('Checked for update');
                yield this.swu.checkForUpdate();
                console.timeEnd('Checked for update');
            })), catchError(errHandler), takeUntil(this.$onDestroy$))
                .subscribe();
        });
    }
    ngOnDestroy() {
        this.$onDestroy$.next();
    }
    log(...message) {
        const timestamp = new Date().toString();
        console.log(`[SwUpdates - ${timestamp}]:`, ...message);
    }
}
SwUpdatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SwUpdatesService_Factory() { return new SwUpdatesService(i0.ɵɵinject(i1.LoggerService), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i2.SwUpdate), i0.ɵɵinject(i0.NgZone)); }, token: SwUpdatesService, providedIn: "root" });
