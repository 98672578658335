import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import {
  Consumer,
  ConsumerApi,
  EmployeeView,
  EmployeeViewApi,
  LoggerService,
  MyUser,
  MyUserApi,
  TripManifestRec,
  Vehicle,
  VehicleApi,
} from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-dlg-trip-history',
  templateUrl: './dlg-trip-history.component.html',
  styleUrls: ['./dlg-trip-history.component.scss'],
  providers: [ConsumerHelperService, HelperService],
})
export class DlgTripHistoryComponent implements OnInit {
  dso$: Observable<any[]> = of([]);
  rows: any[] = [];
  driversMap: any = {};
  consumersMap: any = {};
  @Input() gridColumns: any[] = [
    { dataField: 'ctime', caption: 'Date', dataType: 'datetime', allowFiltering: false },
    { dataField: 'device', caption: 'Device' },
    { dataField: 'user', caption: 'user' },
    { dataField: 'caller', caption: 'Tool' },
    { dataField: 'data.length', caption: 'Manifest', cellTemplate: 'manifest_cell', textAlign: 'center' },
  ];

  @Input() gridSelection = { mode: 'single', showCheckBoxesMode: 'none' };
  @Input() gridPaging = { enabled: true, pageSize: 10 };
  @Input() gridFilterRow = { visible: true };
  @Input() gridScrolling = { mode: 'infinite' };

  constructor(
    private dialogRef: MatDialogRef<DlgTripHistoryComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      manifestId: number;
      date: string;
      trip: TripManifestRec;
    },
    protected logger: LoggerService,
    private dss: DataSourceService,
    public config: ConfigService,
    private ui: UiService,
    public helper: HelperService,
    protected dialog: MatDialog,
    protected sanitizer: DomSanitizer,
    public consumerHelper: ConsumerHelperService,
  ) {}

  ngOnInit() {
    this.buildDso();
  }

  getDriverFullName = (trip: TripManifestRec): string => {
    const empl = this.driversMap[trip.e];
    return empl ? `${empl.person_firstname} ${empl.person_lastname}` : '';
  };

  getEscFullName = (trip: TripManifestRec): string => {
    const empl = this.driversMap[trip.esc];
    return empl ? `${empl.person_firstname} ${empl.person_lastname}` : '';
  };

  getConsumerFullName = (trip: TripManifestRec): string => this.consumerHelper.displayExpr(this.consumersMap[trip.c]);

  async restoreManifest([record]) {
    if (!record) {
      this.dialogRef.close(false);
      return;
    }
    console.log('restoreManifest', record);
    this.ui.showLoading();
    await this.helper.api.updateManifest(this.data.manifestId, record.data || []).toPromise();
    this.ui.hideLoading();
    this.dialogRef.close(true);
  }

  errorStyle = 'background-color: #c1ffc1; color: #333; padding: 2px 3px; border-radius: 4px;margin-left: -3px;';
  getUpdatedCell(cellInfo) {
    const curRow = this.rows[cellInfo.rowIndex];
    const curVal = curRow && curRow[cellInfo.column.dataField];
    const nextRow = this.rows[cellInfo.rowIndex + 1];
    const nextVal = nextRow && nextRow[cellInfo.column.dataField];
    let html = `<span style="${nextRow && nextVal != curVal && this.errorStyle}">${cellInfo.text}</span>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  async buildDso() {
    this.ui.showLoading();
    const stages = [{ $match: { entityId: this.data.manifestId } }, { $sort: { ctime: 1 } }];
    const logRows = await gqlMongoLoad(this.dss, 'EntityActionLog_TripManifest', {}, stages).pipe().toPromise();
    let prevTrip = {};
    const rows = logRows
      .map(v => {
        const data = (v.entity && v.entity.after && v.entity.after.data) || [];
        const trip = data.find(({ id }) => id === this.data.trip.id);
        if (!trip) return null;
        trip.rid = null; // exclude from comparison
        if (!Object.keys(trip).some(k => trip[k] !== prevTrip[k])) return null;
        prevTrip = trip;
        return trip && { ...v, ...trip, entity: undefined };
      })
      .filter(v => v)
      .reverse();
    console.log('rows', rows);

    const users: MyUser[] = await this.dss
      .getApi<MyUserApi>(MyUser)
      .find<MyUser>({ where: { id: { inq: [...new Set(rows.map(r => r.userId))] } } })
      .toPromise();
    const usersMap = users.reduce((p, u) => ({ ...p, [u.id]: u.username }), {});

    const [consumers, drivers, vehs] = await Promise.all([
      this.dss
        .getApi<ConsumerApi>(Consumer)
        .find<Consumer>(
          {
            where: { id: { inq: [...new Set(rows.map(({ c }) => c))] } },
            include: [{ person: { contact: ['addresses', 'phones'] } }],
          },
          hAll,
        )
        .toPromise(),
      this.dss
        .getApi<EmployeeViewApi>(EmployeeView)
        .find<EmployeeView>(
          { where: { employeeId: { inq: [...new Set(rows.flatMap(({ e, esc }) => [e, esc]))] } } },
          hAll,
        )
        .toPromise(),
      this.dss.getApi<VehicleApi>(Vehicle).find<Vehicle>().toPromise(),
    ]);

    this.driversMap = drivers.reduce((p, d) => ({ ...p, [d.id]: d }), {});
    this.consumersMap = consumers.reduce((p, c) => ({ ...p, [c.id]: c }), {});
    const vehsMap = vehs.reduce((p, v) => ({ ...p, [v.id]: v }), {});
    console.log('rows', rows);
    rows.forEach((r, i) => {
      r.device =
        (r.headers && !r.headers['x-caller'] && ((r.headers['x-application-name'] && 'MobileApp') || 'WebPortal')) ||
        'System';
      r.user = usersMap[r.userId];
      r.caller = r.headers && r.headers['x-caller'];
      r.internalId = (vehsMap[r.v] && vehsMap[r.v].internalId) || '';
    });
    this.dso$ = of(rows);
    this.rows = rows;
    this.ui.hideLoading();
  }

  grid_onSelectionChanged(e) {}
}
