import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { InternalStorage } from 'src/app/shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Facility, Person, Signature, Vehicle, VehicleGeotab } from '../../../../shared/sdk/models';
import {
  FacilityApi,
  LoggerService,
  MyUtilsApi,
  SignatureApi,
  VehicleGeotabApi,
} from '../../../../shared/sdk/services/custom';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';

export const TIMEZONE = 'America/New_York';

const EVENTS = ['Pickups', 'Dropoffs'];
const SOURCES = ['Tablet GPS', 'Geotab GPS'];

@Component({
  selector: 'app-vehicle-details-tab-routes',
  templateUrl: './vehicle-details-tab-routes.component.html',
  styleUrls: ['./vehicle-details-tab-routes.component.scss'],
})
export class VehicleDetailsTabRoutesComponent
  extends ABaseModelPaneWToolbarComponent<Vehicle>
  implements OnInit, OnDestroy
{
  model: Vehicle;
  markerBaseUrl = '/assets/images/';
  markers = [];
  routes = [];
  selectedDate = new Date();
  positions = [];
  signatures = [];
  events = [...EVENTS];
  selectedEvents = [...EVENTS];
  sources = [...SOURCES];
  selectedSources = [...SOURCES];
  clients = [];
  selectedClients = [];
  loops = [];
  selectedLoop = undefined;
  tenants = [];
  tenantsMap = {};

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    public config: ConfigService,
    private internalStorage: InternalStorage,
    private myUtilsAPI: MyUtilsApi,
  ) {
    super(logger, ui, dss);
    this.caption = 'Trips History';
  }

  protected get ModelClass(): any {
    return Vehicle;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Vehicle>> {
    return VehicleFormComponent;
  }
  protected async afterModelLoadedAsync(model: any): Promise<void> {
    await super.afterModelLoadedAsync(model);
    this.model = model;
    this.initData();
    await this.getData();
    await this.prepareMapData();
  }

  async onDateValueChanged() {
    this.initData();
    await this.getData();
    await this.prepareMapData();
  }

  initData() {
    this.loops = [];
    this.selectedLoop = undefined;
    this.tenantsMap = {};
  }

  async onValueChanged(e) {
    if (e.event) await this.prepareMapData(false);
  }

  async getData() {
    const start = { $dateFromString: { dateString: moment(this.selectedDate).startOf('day').toISOString() } };
    const end = { $dateFromString: { dateString: moment(this.selectedDate).endOf('day').toISOString() } };
    const stages = [
      { $match: { vin: this.model.vin, month: +moment(this.selectedDate).format('YYYYMM') } },
      {
        $project: {
          ...{ vin: 1, deviceId: 1, month: 1, internalId: 1 },
          positions: {
            $filter: {
              ...{ input: '$positions', as: 'position' },
              cond: { $and: [{ $gte: ['$$position.dateTime', start] }, { $lte: ['$$position.dateTime', end] }] },
            },
          },
        },
      },
    ];
    const data = await this.dss.getApi<VehicleGeotabApi>(VehicleGeotab).aggregate(stages).toPromise();
    this.positions = (data[0] || {}).positions || [];
    this.signatures = await this.dss
      .getApi<SignatureApi>(Signature)
      .find<Signature>(
        {
          where: {
            vehicleId: this.model.id,
            vdate: moment(this.selectedDate).format('YYYY-MM-DD'),
          },
          include: [{ employee: 'person' }, { consumer: 'person' }],
        },
        hAll,
      )
      .toPromise();

    if (this.signatures[0]) {
      const clientsMap = this.signatures.reduce((p, s) => {
        if (s.consumer && s.consumer.person) {
          const { firstname, lastname } = s.consumer.person;
          return { ...p, [s.consumer.id]: `${firstname} ${lastname}` };
        }
        return p;
      }, {});
      this.clients = Object.values(clientsMap);
      this.selectedClients = [...this.clients];
    }
  }

  async prepareMapData(populateLoops = true) {
    console.log('prepareMapData');
    console.log(this.selectedLoop);
    let routes = [];
    let locations = [];
    let markers = [];
    const { markers: signMarkers, ePerson } = this.getSignatureMarkers();
    if (this.positions.length > 1) {
      markers = [...markers, ...this.getStartFinishMarkers(this.positions, ePerson)];
    }
    let loopNumber = 0;
    let loopRoutes = [];
    let loopMarkers = [];
    let loops = [];
    let prevPos = null;

    this.positions.forEach((pos, i) => {
      const location = [pos.latitude, pos.longitude];
      locations.push(location);
      if (pos.speed === 0 && pos.duration > 90) {
        loopMarkers.push(this.getStopMarker(pos, ePerson));
      }

      const isNewLoop = prevPos && prevPos.loop && pos && pos.loop && prevPos.loop.loopId !== pos.loop.loopId;
      if (isNewLoop) {
        const endDate = pos.loop.startDate;
        loops.push({ ...prevPos.loop, endDate, name: moment(prevPos.loop.startDate).format('LT') });
        this.tenantsMap[prevPos.tenantId] = {
          tenantId: prevPos.tenantId,
          latitude: prevPos.latitude,
          longitude: prevPos.longitude,
          ...prevPos.tenantPosition,
        };
      }
      if (isNewLoop || locations.length > 24 || i === this.positions.length - 1) {
        loopRoutes.push(this.getRoute(locations, loopNumber));
        locations = [location];
      }
      if (isNewLoop || i === this.positions.length - 1) {
        if (!this.selectedLoop || (isNewLoop && prevPos.loop.loopId == this.selectedLoop.loopId)) {
          routes = [...routes, ...loopRoutes];
          markers = [...markers, ...loopMarkers];
        }
        loopRoutes = [];
        loopMarkers = [];
        loopNumber++;
      }
      prevPos = pos;
    });

    if (populateLoops) {
      this.loops = [...loops];
      this.tenants = [];
      if (Object.keys(this.tenantsMap).length) {
        this.tenants = await this.dss
          .getApi<FacilityApi>(Facility)
          .find<Facility>(
            {
              where: { id: { inq: Object.keys(this.tenantsMap) } },
            },
            hAll,
          )
          .toPromise();
      }
    }

    this.routes = [...routes];
    this.markers = [...this.getTenantMarkers(), ...markers, ...signMarkers];
  }

  private getRoute(locations, loopNumber) {
    const colors = ['red', 'green', 'blue', 'brown', '#0083ff', '#3cbc4d', '#a02370', '#7f7213', '#12677c'];
    return {
      weight: 4,
      color: colors[loopNumber % colors.length],
      opacity: 0.8,
      mode: '',
      locations: [...locations],
    };
  }

  private getSignatureMarkers(): any {
    let ePerson = <Person>{};
    let markers = [];

    this.signatures.forEach(s => {
      ePerson = (s.employee && s.employee.person) || ePerson;
      const cPerson = (s.consumer && s.consumer.person) || {};
      const { firstname, lastname } = cPerson;
      if (
        s.meta.pickUpDeviceLocation &&
        this.selectedEvents.includes('Pickups') &&
        this.selectedClients.includes(`${firstname} ${lastname}`)
      ) {
        const tc = moment.tz(`${s.vdate} ${s.pickupTime}`, TIMEZONE).utc().format();
        if (
          this.selectedSources.includes('Tablet GPS') &&
          (!this.selectedLoop || (this.selectedLoop.startDate < tc && tc < this.selectedLoop.endDate))
        ) {
          markers.push(this.getSignatureMarker(true, s.meta.pickUpDeviceLocation, tc, ePerson, cPerson));
        }
        if (this.selectedSources.includes('Geotab GPS')) {
          const p = this.findStopPosition(tc);
          if (p) markers.push(this.getSignatureMarkerTmp(true, p, ePerson, cPerson));
        }
      }

      if (
        s.meta.dropOffDeviceLocation &&
        this.selectedEvents.includes('Dropoffs') &&
        this.selectedClients.includes(`${firstname} ${lastname}`)
      ) {
        const tc = moment.tz(`${s.vdate} ${s.dropoffTime}`, TIMEZONE).utc().format();
        if (
          this.selectedSources.includes('Tablet GPS') &&
          (!this.selectedLoop || (this.selectedLoop.startDate < tc && tc < this.selectedLoop.endDate))
        ) {
          markers.push(this.getSignatureMarker(false, s.meta.dropOffDeviceLocation, tc, ePerson, cPerson));
        }
        if (this.selectedSources.includes('Geotab GPS')) {
          const p = this.findStopPosition(tc);
          if (p) markers.push(this.getSignatureMarkerTmp(false, p, ePerson, cPerson));
        }
      }
    });
    return { markers, ePerson };
  }

  private findStopPosition(time: string) {
    return (
      time &&
      this.positions.find(
        ({ dateTime, duration, speed, loop }) =>
          !speed &&
          moment(dateTime).add(duration + 60, 'seconds') > moment(time) &&
          (!this.selectedLoop || (loop && loop.loopId == this.selectedLoop.loopId)),
      )
    );
  }

  private getSignatureMarker(isPickUp, { lat, lng, isGeoTab }, tc, ePerson, cPerson): any {
    return {
      iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}.png`,
      location: `${lat}, ${lng}`,
      tooltip: {
        text:
          `<strong>${this.model.internalId} ${ePerson.firstname} ${ePerson.lastname}</strong>` + // Header
          `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} ${cPerson.firstname} ${cPerson.lastname}` +
          `<br/><em>Time:</em> ${moment(tc).format('LT')}` +
          `<br/>${(isGeoTab && 'GeoTab') || ''}`,
        isShown: false,
      },
    };
  }

  private getSignatureMarkerTmp(
    isPickUp,
    { latitude, longitude, dateTime, duration, speed, loop },
    ePerson,
    cPerson,
  ): any {
    return {
      iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}-tmp.png`,
      location: `${latitude}, ${longitude}`,
      tooltip: {
        text:
          `${this.model.internalId} ${ePerson.firstname} ${ePerson.lastname}` +
          `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} ${cPerson.firstname} ${cPerson.lastname}` +
          `<br/><em>Time:</em> ${moment(dateTime).format('LT')}` +
          `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}` +
          `<br/><em>Speed:</em> ${speed}` +
          `<br/><em>Loop Id:</em> ${(loop && loop.loopId) || ''}` +
          `<br/><em>Loop Start:</em> ${(loop && moment(loop.startDate).format('LT')) || ''}` +
          `<br/>GeoTab`,
        isShown: false,
      },
    };
  }

  private getStopMarker({ latitude, longitude, dateTime, duration, loop }, ePerson): any {
    return {
      iconSrc: this.markerBaseUrl + 'marker-stop.png',
      location: `${latitude}, ${longitude}`,
      tooltip: {
        text:
          `${this.model.internalId} ${ePerson.firstname} ${ePerson.lastname}` +
          `<br/><em>Time:</em> ${moment(dateTime).format('LT')}` +
          `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}` +
          `<br/><em>Loop Id:</em> ${(loop && loop.loopId) || ''}` +
          `<br/><em>Loop Start:</em> ${(loop && moment(loop.startDate).format('LT')) || ''}`,
        isShown: false,
      },
    };
  }

  private getTenantMarkers(): any[] {
    return this.tenants.map(t => ({
      iconSrc: `${this.markerBaseUrl}marker-facility.png`,
      location: `${this.tenantsMap[t.id].latitude}, ${this.tenantsMap[t.id].longitude}`,
      tooltip: { text: `${t.name}`, isShown: false },
    }));
  }

  private getStartFinishMarkers(positions: any, ePerson: Person): any[] {
    const isToday = moment(this.selectedDate).isSame(new Date(), 'day');
    this.selectedDate;
    return [
      { ...positions[0], iconSrc: this.markerBaseUrl + 'marker-start.png', isStart: true },
      {
        ...positions[positions.length - 1],
        iconSrc: this.markerBaseUrl + `marker-${isToday ? 'current' : 'finish'}.png`,
      },
    ].map(({ dateTime, speed, currentStateDuration, isDriving, latitude, longitude, iconSrc, isStart }) => ({
      iconSrc,
      location: `${latitude}, ${longitude}`,
      tooltip: {
        text:
          `${this.model.internalId} ${ePerson.firstname} ${ePerson.lastname}` +
          `<br/><em>${isStart ? 'Start' : isToday ? '' : 'Finish'} Time:</em> ${moment(dateTime).format('LT')}` +
          `<br/><em>Driving:</em> ${(isDriving && 'yes') || 'not'}` +
          `<br/><em>Speed:</em> ${speed}` +
          `<br/><em>State Duration:</em> ${moment.duration(currentStateDuration, 'seconds').humanize()}`,
        isShown: false,
      },
    }));
  }

  async getGeoTabZonePoints(id: string, attempt = 0): Promise<{ [key: string]: any }> {
    try {
      const geoTabAuth = this.internalStorage.get('geoTabAuth') || (await this.myUtilsAPI.geoTabAuth().toPromise());
      this.internalStorage.set('geoTabAuth', geoTabAuth);

      const api = new GeotabApi(geoTabAuth);
      const [[zone]] = await api.multiCall([['Get', { typeName: 'Zone', search: { id } }]]);
      return (zone && zone.points.map(({ x, y }) => ({ latitude: y, longitude: x }))) || null;
    } catch (err) {
      const msg = 'You’ve reached a limit of 10 geolocation requests per minute, please wait to refresh';
      if (err.message === 'JSONRPCError - API calls quota exceeded. Maximum admitted 10 per 1m.') throw msg;
      if (attempt) return {};
      this.internalStorage.remove('geoTabAuth');
      return await this.getGeoTabZonePoints(id, 1);
    }
  }
}
