import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { hAll } from '../../../../classes/utils/utils';
import {
  Consumer,
  LoggerService,
  Signature,
  SignatureConsUniqImgView,
  SignatureConsUniqImgViewApi,
} from '../../../../sdk';
import { ABaseComponent } from '../abstract/a-base.component';

@Component({
  selector: 'app-dlg-select-signature',
  templateUrl: './dlg-select-signature.component.html',
  styleUrls: ['./dlg-select-signature.component.scss'],
})
export class DlgSelectSignatureComponent extends ABaseComponent implements OnInit, AfterViewInit {
  ds$: any;

  loading = false;
  totalSigns$: Observable<number>;
  pageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(48);

  constructor(
    protected logger: LoggerService,
    public dialogRef: MatDialogRef<DlgSelectSignatureComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      signature?: Signature;
      consumer?: Consumer;
    },
    private dialog: MatDialog,
    private sapi: SignatureConsUniqImgViewApi,
  ) {
    super(logger);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // console.log(oc(this.data).consumer.id());
  }

  get consumerId() {
    return oc(this.data).consumer.id() || oc(this.data).signature.consumerId();
  }

  ngAfterViewInit(): void {
    // console.log(this.data.consumer);

    this.totalSigns$ = this.sapi
      .count(
        {
          consumerId: this.consumerId,
        },
        hAll,
      )
      .pipe(
        map(res => res.count),
        takeUntil(this.$onDestroy$),
      );

    merge(of(null), this.pageIndex$.asObservable(), this.pageSize$.asObservable())
      .pipe(
        delay(100),
        tap(() => (this.loading = true)),
        switchMap(() =>
          this.sapi.find(
            {
              where: { consumerId: this.consumerId },
              limit: this.pageSize$.value,
              offset: this.pageIndex$.value * this.pageSize$.value,
              order: 'id DESC',
            },
            hAll,
          ),
        ),
        tap(items => (this.ds$ = of(items))),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => (this.loading = false)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  on_page(
    e:
      | {
          length: number;
          pageIndex: number;
          pageSize: number;
          previousPageIndex: number;
        }
      | any,
  ) {
    if (e.pageIndex !== this.pageIndex$.value) {
      this.pageIndex$.next(e.pageIndex);
    }

    if (e.pageSize !== this.pageSize$.value) {
      this.pageSize$.next(e.pageSize);
    }
  }

  tile_onItemClick(e: { itemData: SignatureConsUniqImgView }) {
    // console.log(e);

    this.dialog
      .open(this.data.signature ? DlgConfirmSignatureSelectComponent : DlgConfirmSignatureForClientSelectComponent, {
        hasBackdrop: true,
        data: e.itemData,
      })
      .afterClosed()
      .toPromise()
      .then(
        async (
          res:
            | {
                data: SignatureConsUniqImgView;
                toAllSignatures?: boolean;
                toClient?: boolean;
              }
            | undefined,
        ) => {
          if (oc(res).data()) {
            this.dialogRef.close(res);
          }
        },
      );
  }
}

@Component({
  templateUrl: 'dlg-confirm-signature-select.component.html',
})
export class DlgConfirmSignatureSelectComponent {
  value: {
    toClient: boolean;
    toAllSignatures: boolean;
    data: SignatureConsUniqImgView;
  } = {
    toClient: false,
    toAllSignatures: false,
    data: undefined,
  };

  constructor(
    public dialogRef: MatDialogRef<DlgConfirmSignatureSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignatureConsUniqImgView,
  ) {
    this.value.data = data;
  }
}

@Component({
  templateUrl: 'dlg-confirm-signature-select-for-consumer.component.html',
})
export class DlgConfirmSignatureForClientSelectComponent {
  value: {
    toAllSignatures: boolean;
    data: SignatureConsUniqImgView;
  } = {
    toAllSignatures: false,
    data: undefined,
  };

  constructor(
    public dialogRef: MatDialogRef<DlgConfirmSignatureForClientSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignatureConsUniqImgView,
  ) {
    this.value.data = data;
  }
}
