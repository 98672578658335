<div [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'SIGN_IN'">
    <mat-card>
      <mat-card-header>
        <mat-card-title><h2>Dispatch App</h2></mat-card-title>
        <mat-card-subtitle>Sign In to your account</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="form_ngSubmit()" novalidate>
          <div class="dx-fieldset">
            <mat-form-field>
              <input matInput placeholder="Username" formControlName="username" required />
              <mat-error *ngIf="form.get('username').invalid">{{
                getErrorMessage(form, 'username', 'Username')
              }}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                [type]="passHide ? 'password' : 'text'"
                placeholder="Password"
                formControlName="password"
                required />
              <mat-icon matSuffix (click)="passHide = !passHide">{{
                passHide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
              <mat-error *ngIf="form.get('password').invalid">{{
                getErrorMessage(form, 'password', 'Password')
              }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('mfaSecret').value">
              <input matInput placeholder="Verification Code" formControlName="mfaToken" />
              <mat-error *ngIf="form.get('password').invalid">{{
                getErrorMessage(form, 'password', 'Password')
              }}</mat-error>
            </mat-form-field>

            <div class="email-sent" *ngIf="form.get('mfaSecret').value && this.signedUser">
              Code was sent to your email "{{ getEmail() }}"
            </div>

            <div class="dx-field" *ngIf="form.get('mfaSecret').value">
              <div class="dx-field">
                <dx-switch
                  formControlName="mfaRememberForThisBrowser"
                  switchedOnText="YES"
                  switchedOffText="NO"></dx-switch>
                Remember for this device
              </div>
            </div>

            <p class="alert-danger" *ngIf="error">{{ error }}</p>

            <button type="submit" mat-raised-button color="primary" [disabled]="isSubmitting">LOGIN</button>

            <button type="button" mat-button color="primary" (click)="showForgotPasswordForm()">
              Forgot Password?
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <!-- Forgot Password Card -->
  <ng-container *ngSwitchCase="'FORGOT_PASSWORD'">
    <mat-card>
      <mat-card-header>
        <mat-card-title><h2>Dispatch App</h2></mat-card-title>
        <mat-card-subtitle>Forgot Password</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordSubmit()" novalidate>
          <div class="dx-fieldset">
            <mat-form-field>
              <mat-label>Send Reset Link By</mat-label>
              <mat-select formControlName="method">
                <mat-option value="email">Email</mat-option>
                <mat-option value="phone">Phone</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="forgotPasswordForm.get('method').value === 'email'">
              <input matInput placeholder="Email" formControlName="email" />
              <mat-error *ngIf="forgotPasswordForm.get('email').invalid">{{
                getErrorMessage(forgotPasswordForm, 'email', 'Email')
              }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="forgotPasswordForm.get('method').value === 'phone'">
              <input matInput placeholder="Phone" formControlName="phone" />
              <mat-error *ngIf="forgotPasswordForm.get('phone').invalid">{{
                getErrorMessage(forgotPasswordForm, 'phone', 'Phone')
              }}</mat-error>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="isSubmitting">Send Reset Link</button>
            <button type="button" mat-button color="primary" (click)="hideForgotPasswordForm()">Cancel</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <!-- Forgot Password Card -->
  <ng-container *ngSwitchCase="'RESET_PASSWORD'">
    <mat-card>
      <mat-card-header>
        <mat-card-title><h2>Dispatch App</h2></mat-card-title>
        <mat-card-subtitle>Reset Password</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPasswordSubmit()" novalidate>
          <div class="dx-fieldset">
            <mat-form-field>
              <input
                matInput
                [type]="passHide ? 'password' : 'text'"
                placeholder="New Password"
                formControlName="password"
                required />
              <mat-error *ngIf="resetPasswordForm.get('password').invalid">{{
                getErrorMessage(resetPasswordForm, 'password', 'New Password')
              }}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                [type]="passHide ? 'password' : 'text'"
                placeholder="Confirm New Password"
                formControlName="passwordConfirmation"
                required />
              <mat-icon matSuffix (click)="passHide = !passHide">{{
                passHide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
              <mat-error *ngIf="resetPasswordForm.get('passwordConfirmation').invalid">{{
                getErrorMessage(resetPasswordForm, 'passwordConfirmation', 'Confirm New Password')
              }}</mat-error>
            </mat-form-field>
            <!-- Show error if passwords do not match -->
            <p class="alert-danger" *ngIf="resetPasswordForm.hasError('passwordsMismatch')">Passwords do not match.</p>

            <button type="submit" mat-raised-button color="primary" [disabled]="isSubmitting">Reset Password</button>
            <button type="button" mat-button color="primary" (click)="hideResetPasswordForm()">Cancel</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>

