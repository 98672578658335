import * as tslib_1 from "tslib";
import { OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { intersectionBy } from 'lodash-es';
import isNil from 'lodash-es/isNil';
import uniqBy from 'lodash-es/uniqBy';
import moment, { utc } from 'moment';
import { from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoByKey } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { hAll } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { EmployeeDayService, FacilityApi, LoggerService, MobileDevice, MyUtilsApi, Person, VehicleCheckUp, } from '../../../../shared/sdk';
import { HelperService } from '../../../consumer/services/helper.service';
export class DailyChecksDetailsTabInfoComponent extends ABaseModelPaneWToolbarComponent {
    constructor(config, logger, ui, helper, dss, dialog, pusher, utilsApi, fApi) {
        super(logger, ui, dss);
        this.config = config;
        this.logger = logger;
        this.ui = ui;
        this.helper = helper;
        this.dss = dss;
        this.dialog = dialog;
        this.pusher = pusher;
        this.utilsApi = utilsApi;
        this.fApi = fApi;
        this.showAdd = false;
        this.showEdit = false;
        this.summary = {
            expirations: [],
            issues: [],
            missingStuff: [],
            other: [],
        };
        this.caption = 'Summary';
    }
    ngOnInit() {
        super.ngOnInit();
    }
    get ModelClass() {
        return VehicleCheckUp;
    }
    get filter() {
        return { include: ['vehicle', { employee: ['person'] }, 'user'] };
    }
    beforeModelLoadingAsync(id) {
        const _super = Object.create(null, {
            beforeModelLoadingAsync: { get: () => super.beforeModelLoadingAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.fullDoc = undefined;
            this.descAndReport = undefined;
            this.descAndReportMap = { ci: new Map(), co: new Map() };
            this.summary = {
                expirations: [],
                issues: [],
                missingStuff: [],
                other: [],
            };
            return _super.beforeModelLoadingAsync.call(this, id);
        });
    }
    getAdministrativeContacts() {
        const rels = oc(this.model).meta.baseEMPersons([]);
        const personApi = this.dss.getApi(Person);
        return from(Promise.all(rels.map(p => personApi.getRevision(p.personId, this.model.dateTime, hAll).toPromise()))).pipe(tap(persons => persons.forEach(p => {
            const rel = rels.find(r => r.personId === p.id);
            p.data._relation = oc(rel).relation();
        })), map(persons => persons.filter(p => ['Safety Director', 'Fleet Manager'].includes(p.data._relation))), catchError(err => of([])));
    }
    afterModelLoadedAsync(model) {
        const _super = Object.create(null, {
            afterModelLoadedAsync: { get: () => super.afterModelLoadedAsync }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.modelId) {
                try {
                    this.loading = true;
                    this.fullDoc = yield gqlMongoByKey(this.dss, 'VehicleChecks_view', this.modelId).toPromise();
                    this.descAndReport = yield this.api.getAllFieldsDescriptions(this.modelId).toPromise();
                    this.descAndReportMap = {
                        ci: new Map((this.descAndReport.ci || []).map(itm => [itm.field, itm])),
                        co: new Map((this.descAndReport.co || []).map(itm => [itm.field, itm])),
                    };
                    this.fullDoc._baseEmRelations = yield this.getAdministrativeContacts().toPromise();
                    this.fullDoc._mobileDevice = yield this.dss
                        .getApi(MobileDevice)
                        .findOne({
                        where: {
                            deviceUid: this.model.deviceUid,
                        },
                    })
                        .toPromise();
                    if (this.fullDoc.checkout) {
                        this.fullDoc.checkout._vehicleUsageDuration = utc(this.fullDoc.checkout.completedDateTime).diff(this.fullDoc.startedDateTime, 'minutes');
                    }
                    const _moment = moment(model.dateTime);
                    const shiftData = yield this.dss
                        .getApi(EmployeeDayService)
                        .getShiftData(this.model.employeeId, _moment.format('YYYY-MM-DD'), [
                        'PUNCH-IN',
                        'PUNCH-OUT',
                        'PAUSE',
                        'RESUME',
                    ])
                        .toPromise();
                    this.fullDoc._pi = shiftData.pi ? moment(shiftData.pi).toDate() : undefined;
                    this.fullDoc._po = shiftData.po ? moment(shiftData.po).toDate() : undefined;
                    this.fullDoc._shiftDuration =
                        this.fullDoc._po && this.fullDoc._pi ? moment(this.fullDoc._po).diff(this.fullDoc._pi, 'minutes') : undefined;
                    const sharedFields = intersectionBy(oc(this).descAndReport.ci([]), oc(this).descAndReport.co([]), 'field');
                    const allCiValueIssues = this.descAndReport.ci.filter(meta => !isNil(meta.value.value) && (meta.value.issues || []).some(i => i.type === 'value'));
                    const allCoValueIssues = this.descAndReport.co.filter(meta => !isNil(meta.value.value) && (meta.value.issues || []).some(i => i.type === 'value'));
                    // const filteredCiValueIssues = differenceBy(allCiValueIssues, sharedFields, 'field');
                    // const allValueIssues = [...filteredCiValueIssues, ...allCoValueIssues];
                    const allValueIssues = [...allCiValueIssues, ...allCoValueIssues];
                    this.summary.expirations = uniqBy(allValueIssues.filter(meta => meta.desc.type === 'date' && meta.desc.daysExpWarning), 'field').map(meta => [meta.field, meta.value.issues.find(i => i.type === 'value')]);
                    this.summary.issues = uniqBy(allValueIssues.filter(meta => meta.desc.reportIssue), 'field').map(meta => [meta.field, meta.value.issues.find(i => i.type === 'value')]);
                    this.summary.missingStuff = uniqBy(allValueIssues.filter(meta => meta.desc.reportMissingStuff), 'field').map(meta => [meta.field, meta.value.issues.find(i => i.type === 'value')]);
                    this.summary.other = allValueIssues
                        .filter(meta => ![...this.summary.expirations, ...this.summary.issues, ...this.summary.missingStuff]
                        .map(itm => itm[0])
                        .includes(meta.field))
                        .map(meta => [meta.field, meta.value.issues.find(i => i.type === 'value')]);
                }
                finally {
                    this.loading = false;
                }
            }
            // console.log(this.descAndReport);
            return _super.afterModelLoadedAsync.call(this, model);
        });
    }
    get FormComponent() {
        return undefined;
    }
}
