import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { ShowLoopDialogComponent } from 'src/app/shared/modules/ui/components/show-loop-dialog/show-loop-dialog.component';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Employee, LoggerService, VehicleGeotabEvent } from '../../../../shared/sdk';
import { HelperService as DocumentHelperService } from '../../../document/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { EmployeeFormComponent } from '../employee-form/employee-form.component';

@Component({
  selector: 'app-employee-details-tab-events',
  templateUrl: './employee-details-tab-events.component.html',
  styleUrls: ['./employee-details-tab-events.component.scss'],
  providers: [HelperService, DocumentHelperService],
})
export class EmployeeDetailsTabEventsComponent
  extends ABaseModelPaneWToolbarComponent<Employee>
  implements OnInit, OnDestroy
{
  dso$: Observable<DataSourceOptions> = of([]);

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    private dialog: MatDialog,
    protected helper: HelperService,
    public docHelper: DocumentHelperService,
  ) {
    super(logger, ui, dss);
    this.caption = 'GeoTab Events';
  }

  getGeoTabLocationCellValue = (v: VehicleGeotabEvent) => `${v.latitude},${v.longitude}`;

  getMapURL = (location: string) => `https://maps.google.com/?q=${location}&ll=${location}&z=11`;

  getDetailsCellValue = (v: VehicleGeotabEvent) => {
    if (v.rule == 'Idling') return 'IDLE for more than 30 minutes';
    return v.diagnostic || `Speed:${v.speed}, Limit:${v.speedLimit}`;
  };

  showLoop_onClick = (v: VehicleGeotabEvent) => {
    this.dialog.open(ShowLoopDialogComponent, {
      panelClass: 'loop-dialog',
      backdropClass: 'loop-dialog',
      width: '80%',
      height: '80%',
      hasBackdrop: true,
      data: {
        vin: v.vin,
        date: v.loop.startDate,
        loopId: v.loop.loopId,
        vehicle: {
          text: `Time: ${moment(v.dateTime).format('lll')}<br/>${v.rule}<br/>${this.getDetailsCellValue(v)}<br/>`,
          latitude: v.latitude,
          longitude: v.longitude,
          icon: 'marker-current.png',
        },
        title: `${v.tenant.name} ${v.rule} ${moment(v.dateTime).format('M/D/YYYY h:mm A')}`,
      },
    });
  };

  protected get ModelClass(): any {
    return Employee;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Employee>> {
    return EmployeeFormComponent;
  }
  protected async afterModelLoadedAsync(model: Employee): Promise<void> {
    await super.afterModelLoadedAsync(model);

    this.dso$ = this.buildDataSource(model);
  }
  private buildDataSource(model: Employee) {
    const so = this.dss.getStoreOptions(VehicleGeotabEvent, undefined, false);
    so.customHeaders = headersAllTenantsAppend;
    so.customFilter = { where: { employeeId: model.id }, include: ['tenant'] };
    const dso: DataSourceOptions = { store: new CustomStore(so) };
    return of(dso);
  }
}
