import { Inject, Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import get from 'lodash-es/get';
import intersection from 'lodash-es/intersection';
import { utc } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { getConfig, getEnvVars } from '../../../../store/reducers/core';
import { getCurrentTenant, getRoles, getTenant, isSU } from '../../../../store/reducers/sign';
import { Config, Facility, SDKModels } from '../../../sdk';
import { ExtSDKModels } from '../../ext-sdk/services/ext-sdk-models.service';
import { CommonService } from './common.service';

export enum ConfigProps {
  appTitle = 'appTitle',
  timezone = 'timezone',
  fullNameFormat = 'fullNameFormat',
  reportFullNameFormat = 'reportFullNameFormat',
  dateFormat = 'dateFormat',
  itemsPerPage = 'itemsPerPage',
  reportList = 'reportList',
  tripAutoSequence = 'tripAutoSequence',
  destinationList = 'destinationList',
  driverList = 'driverList',
  consumerMoreFields = 'consumerMoreFields',
  allowEditClientOnTab = 'allowEditClientOnTab',
  allowAdcSignatures = 'allowAdcSignatures',

  allowCollectSignsNotByDriver = 'allowCollectSignsNotByDriver',
  allowEditUnvalidatedSignsOnDApp = 'allowEditUnvalidatedSignsOnDApp',
  allowEditAutoValidatedSignsOnDApp = 'allowEditAutoValidatedSignsOnDApp',
  allowAmbuletteTrips = 'allowAmbuletteTrips',

  showInactiveClients = 'showInactiveClients',
  enableMobileDispatch = 'enableMobileDispatch',
  adcManifestAutoClone = 'adcManifestAutoClone',
  adcMealsCheck = 'adcMealsCheck',
  adcMealsDefaultBreakfastCheck = 'adcMealsDefaultBreakfastCheck',
  adcMealsDefaultSnackCheck = 'adcMealsDefaultSnackCheck',
  adcMealsDefaultLunchCheck = 'adcMealsDefaultLunchCheck',
  adcMealsDefaultBreakfastPerDayCheck = 'adcMealsDefaultBreakfastPerDayCheck',
  adcMealsDefaultSnackPerDayCheck = 'adcMealsDefaultSnackPerDayCheck',
  adcMealsDefaultLunchPerDayCheck = 'adcMealsDefaultLunchPerDayCheck',

  meals_Hot_W1759_Enabled = 'meals_Hot_W1759_Enabled',
  meals_Frozen_W1760_Enabled = 'meals_Frozen_W1760_Enabled',
  meals_Sandwich_W1761_Enabled = 'meals_Sandwich_W1761_Enabled',
  meals_Emergency_W1762_Enabled = 'meals_Emergency_W1762_Enabled',
  meals_Special_W1764_Enabled = 'meals_Special_W1764_Enabled',

  mealPrepInvoicingRates = 'mealPrepInvoicingRates',

  signatureByPictureEnabled = 'signatureByPictureEnabled',
  smsToClientOnMealDropOff = 'smsToClientOnMealDropOff',

  frequentUpdate = 'frequentUpdate',
  certificationOnSignModify = 'certificationOnSignModify',
  driverCanCollectSignatures = 'driverCanCollectSignatures',
  driverCanCollectTemperature = 'driverCanCollectTemperature',
  enableGeotabZoneDropOffReminder = 'enableGeotabZoneDropOffReminder',
  enablePrematureGeoZoneDropOffNotification = 'enablePrematureGeoZoneDropOffNotification',

  serviceType = 'serviceType',
  vehicleLocationList = 'vehicleLocationList',
  enableMtmIntegration = 'enableMtmIntegration',
  //
  fleetSetupWinterReady = 'fleetSetupWinterReady',
  fleetSetupDisinfectionRequired = 'fleetSetupDisinfectionRequired',
  backup2AssignedAfter = 'backup2AssignedAfter',
  fleetSetupCovid19Mode = 'fleetSetupCovid19Mode',
  broadcastPostCIMsg = 'broadcastPostCIMsg',
  broadcastMsgAckBtnTxt = 'broadcastMsgAckBtnTxt',
  //
  adcDaysOpen = 'adcDaysOpen',
  adcDailyShifts = 'adcDailyShifts',
  adcCovidReporting = 'adcCovidReporting',
  showUncollectedTemperaturePrompt = 'showUncollectedTemperaturePrompt',

  adcAttendanceReportingMode = 'adcAttendanceReportingMode',
  adcAttendanceSummary = 'adcAttendanceSummary',
  adcAttendanceSummary$ = 'adcAttendanceSummary$',
  adcMealsReportingMode = 'adcMealsReportingMode',
  cacfpNumber = 'cacfpNumber',
  adcCovidReportingMode = 'adcCovidReportingMode',
  adcCovidReportingMode2 = 'adcCovidReportingMode2',

  adcCovid19Measures = 'adcCovid19Measures',
  adcSignatureByQr = 'adcSignatureByQr',
  qrAdcArrivalTimeOverride = 'qrAdcArrivalTimeOverride',
  qrAdcDepartureTimeOverride = 'qrAdcDepartureTimeOverride',
  signatureByQr = 'signatureByQr',
  dropOffByQrOnly = 'dropOffByQrOnly',
  bypassCode = 'bypassCode',

  adcTimeCompliance = 'adcTimeCompliance',
  adcSignatureExtendedMode = 'adcSignatureExtendedMode',
  showAdcTimeInFacilityPrompt = 'showAdcTimeInFacilityPrompt',
  enableTimeInFacilityScreen = 'enableTimeInFacilityScreen',
  shiftRemainingTimeReminder = 'shiftRemainingTimeReminder',
  tripCancelReasons = 'tripCancelReasons',
  tripDurationMin = 'tripDurationMin',
  isDepartureEnabled = 'isDepartureEnabled',

  showGt1hourTripAlert = 'showGt1hourTripAlert',
  showGt2hourTripAlert = 'showGt2hourTripAlert',
  enforceDriverSchedule = 'enforceDriverSchedule',
}

export interface IEnvVars {
  NODE_ENV?: string;
  PORT?: number;
  tenantCtx?: any;
  // CONTAINER_PREFIX?: string;
  GOOGLE_MAPS_API_KEY_FOR_BROWSER?: string;
  PUSHER_APP_KEY?: string;
  PUSHER_APP_CLUSTER?: string;
  PUSHER_BEAM_INSTANCE_ID?: string;
  // STRIPE_PUBLISHABLE?: string;
}

@Injectable()
export class ConfigService {
  private $vars$: BehaviorSubject<IEnvVars> = new BehaviorSubject(null);
  private $config$: BehaviorSubject<Config> = new BehaviorSubject(null);
  private $tenant$: BehaviorSubject<Facility> = new BehaviorSubject(null);

  constructor(
    @Inject(CommonService) private common: CommonService,
    @Inject(SDKModels) private models: ExtSDKModels,
  ) {
    this.common.store.pipe(select(getEnvVars)).subscribe(vars => this.$vars$.next(vars));
    this.common.store.pipe(select(getConfig)).subscribe(config => this.$config$.next(config));
    this.common.store.pipe(select(getTenant)).subscribe(tenant => this.$tenant$.next(tenant));
  }

  get config(): any {
    return oc(this.$config$).value.data();
  }

  get vars(): IEnvVars {
    return this.$vars$.value;
  }

  get tenant(): Facility | undefined {
    return this.$tenant$.value;
  }

  get tenantType(): string | undefined {
    return oc(this.tenant).type();
  }

  get(prop: string, defaultValue?: any): any {
    return get(this.config, prop, defaultValue);
  }

  env(prop: string, defaultValue?: any): any {
    return get(this.vars, prop, defaultValue);
  }

  get tenant$(): Observable<Facility> {
    return this.common.store.pipe(
      select(getCurrentTenant),
      switchMap(tenantId =>
        tenantId ? this.models.getApi(Facility.getModelName()).findById<Facility>(tenantId, null) : null,
      ),
    );
  }

  get tenantType$(): Observable<string> {
    return this.tenant$.pipe(map(t => t.type));
  }

  get roles$() {
    return this.common.store.pipe(select(getRoles));
    // const api = this.models.getApi(MyUser.getModelName()) as MyUserApi;
    // return defer(() => api.getRoles(this.common.auth.getCurrentUserId()) as Observable<string[]>);
  }

  get isSU$() {
    return this.common.store.pipe(select(isSU));
    // return this.roles$.pipe(map(roles => roles.includes('SU')));
  }

  hasAnyRole$(roles: string[]) {
    return this.roles$.pipe(map(currentRoles => intersection(currentRoles, roles).length > 0));
  }

  /**
   * convert iso datetime to shortdate for grid column
   */
  customizeShortDateText(cellInfo: any): string {
    // console.log(cellInfo);

    if (cellInfo.target === 'row') {
      const momentDate = utc(cellInfo.value);

      if (
        momentDate.utc().get('seconds') === 0 &&
        momentDate.utc().get('minutes') === 0 &&
        (momentDate.utc().get('hours') === 0 || momentDate.utc().get('hours') === 12)
      ) {
        return utc(cellInfo.value).utc().format('L');
      }
    }
    return cellInfo.valueText;
  }
}
