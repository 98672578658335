import * as tslib_1 from "tslib";
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { isEmpty } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { hAll } from '../../../../shared/classes/utils/utils';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
export class ABaseConsistencyCheckComponent extends ABaseComponent {
    constructor(logger, ui, pusher, common, dss, gridHelper) {
        super(logger);
        this.logger = logger;
        this.ui = ui;
        this.pusher = pusher;
        this.common = common;
        this.dss = dss;
        this.gridHelper = gridHelper;
        this.dso = new DataSource([]);
        this.mongoSchema = { 'service date': 'datetime' };
    }
    buildDataSource() {
        return of(true).pipe(tap(() => {
            this.grid.instance.endCustomLoading();
            this.grid.instance.beginCustomLoading('Filtering...');
        }), switchMap(() => this.pusher.rpc('GET_INCONSISTENCY_CHECK', { idx: this.checkIdx, useRunJob: true }, true, hAll)), map(coll => {
            this.dsoColl = coll;
            return new DataSource(this.dss.createMongoStore(coll, this.mongoSchema));
        }), tap(() => {
            this.grid.instance.endCustomLoading();
        }), catchError(err => {
            notify(err.message, 'error', 5000);
            return of(null);
        }), tap(dso => (this.dso = dso)), takeUntil(this.$onDestroy$));
    }
    grid_onInitialized(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.buildDataSource().toPromise();
            this.gridHelper.handle(e.component, {
                onGridRefresh: () => tslib_1.__awaiter(this, void 0, void 0, function* () { return this.buildDataSource().toPromise(); }),
            });
        });
    }
    grid_toolbar_fix_onClick(type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield of(true)
                .pipe(switchMap(() => tslib_1.__awaiter(this, void 0, void 0, function* () { return this.grid.instance.getSelectedRowsData(); })), map(docs => ({ type, coll: this.dsoColl, docs })), filter(args => !isEmpty(args.coll) || !isEmpty(args.docs)), tap(() => this.ui.showLoading()), switchMap(args => this.pusher.rpc('FIX_INCONSISTENCY_CHECK', Object.assign({}, args, { useRunJob: true }), true, hAll)), tap(() => this.ui.hideLoading()), switchMap(() => this.buildDataSource()), catchError(err => of(notify(err.message, 'error', 5000))), takeUntil(this.$onDestroy$))
                .toPromise();
        });
    }
    safeGetSource(doc) {
        return oc(doc)._source() || doc || {};
    }
}
