import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import DevExpress from 'devextreme';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import intersection from 'lodash-es/intersection';
import moment, { utc } from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { hAll } from '../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import {
  ByEmployeeSignatureStats,
  ByEmployeeSignatureStatsApi,
  Employee,
  EmployeeView,
  EmployeeViewApi,
  Facility,
  FacilityApi,
  LoggerService,
  MyUserApi,
} from '../../../../shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import ArrayStoreOptions = DevExpress.data.ArrayStoreOptions;
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import PivotGridDataSourceField = DevExpress.data.PivotGridDataSourceField;
import PivotGridDataSourceOptions = DevExpress.data.PivotGridDataSourceOptions;

@Component({
  selector: 'app-employee-sign-stats',
  templateUrl: './employee-sign-stats.component.html',
  styleUrls: ['./employee-sign-stats.component.scss'],
  providers: [EmployeeHelperService],
})
export class EmployeeSignStatsComponent extends ABaseComponent implements OnInit {
  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      { dataField: 'tenantId', visible: false },
      { dataField: 'employeeId', visible: false },
      { dataField: 'vdate', visible: false },
      { dataField: 'weekday', visible: false },

      { caption: 'Year', dataField: 'vdate', dataType: 'date', groupInterval: 'year', displayFolder: 'date' },
      { caption: 'Month', dataField: 'vdate', dataType: 'date', groupInterval: 'month', displayFolder: 'date' },
      {
        caption: 'Day of Week',
        dataField: 'vdate',
        dataType: 'date',
        groupInterval: 'dayOfWeek',
        displayFolder: 'date',
      },
      // {caption: 'Day', area: 'column', dataField: 'vdate', dataType: 'date', groupInterval: 'day', displayFolder: 'date'},
      {
        caption: 'Day',
        displayFolder: 'date',
        area: 'column',
        selector: data => {
          const dateMoment = utc(data.vdate);
          return [dateMoment.format('YYYY-MM-DD'), dateMoment.format('dd')].join(' ');
        },
      },

      { caption: 'Tenant', area: 'row', dataField: '__tenant', dataType: 'string', isMeasure: false },
      { caption: 'Driver', area: 'row', dataField: '__driver', dataType: 'string', isMeasure: false },

      {
        caption: 'Signatures Collected',
        area: 'data',
        dataField: 'count',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },
    ];
  }

  dso: any = {
    store: [],
    fields: this.pivotFields,
  } as DataSourceOptions;

  facilityDso$: Observable<DataSourceOptions> = of([]);
  employeeDso$: Observable<DataSourceOptions> = of([]);

  facilitySet: Set<string>;
  driverSet: Set<string>;

  grid_stateStoring: any;

  showColumnTotals = true;
  showRowTotals = true;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  selectedFromValue?: Date = moment().subtract(1, 'week').toDate();
  selectedToValue?: Date = moment().toDate();

  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  get exportFileName(): string {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to);

    return [
      moment().format('YYYY_MM_DD'),
      'Attendance For',
      [fromMoment.format('YYYY_MM_DD'), toMoment.format('YYYY_MM_DD')].join(' - '),
    ].join(' ');
  }

  constructor(
    protected logger: LoggerService,
    private ui: UiService,
    public config: ConfigService,
    private gridHelper: GridHelperService,
    private dss: DataSourceService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    @Inject(HttpClient) private http: HttpClient,
    private api: ByEmployeeSignatureStatsApi,
    private userApi: MyUserApi,
    public common: CommonService,
    public employeeHelper: EmployeeHelperService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'c77de65f-85c1-49c0-80ae-5802a4a00ec3',
    };

    this.facilityDso$ = this.buildFacilityDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.dso = {
            store: as,
            fields: this.pivotFields,
          } as PivotGridDataSourceOptions;
          // this.grid.instance.refresh();
        }),
        tap(() => {
          this.ui.hideLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    const _from = this.selectedFromValue;
    const _to = this.selectedToValue;

    const fromMoment = _from && moment(_from);
    const toMoment = _to && moment(_to).add(1, 'days');

    // if (!fromMoment || !toMoment) {
    //   throw new Error('Period should be defined');
    // }

    // if (toMoment.diff(fromMoment, 'months') > 1) {
    //   throw new Error('Period should be less or equal to 1 month');
    // }

    const fromInc = fromMoment && fromMoment.format('YYYY-MM-DD');
    const toExcl = toMoment && toMoment.format('YYYY-MM-DD');

    return combineLatest([this.config.roles$, this.dss.getApi<FacilityApi>(Facility).find<Facility>()]).pipe(
      switchMap(([roles, facilities]) =>
        combineLatest([
          of(facilities),

          this.dss.getViewApi<EmployeeViewApi>(Employee).find<EmployeeView>({}, (headers: HttpHeaders) => {
            if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
              headers = hAll(headers);
            }
            return headers;
          }),

          this.api.find<ByEmployeeSignatureStats>(
            {
              where: {
                and: [
                  ...(fromInc ? [{ vdate: { gte: fromInc } }] : []),
                  ...(toExcl ? [{ vdate: { lt: toExcl } }] : []),
                ],
              },
            },
            (headers: HttpHeaders) => {
              if (intersection(['SU', 'MANAGER', 'BILLER'], roles).length) {
                headers = hAll(headers);
              }
              return headers;
            },
          ),
        ]),
      ),

      map(([facilities, employees, driverSignStats]) => {
        this.facilitySet = new Set(facilities.map(f => f.shortname));
        this.driverSet = new Set(employees.map(e => this.employeeHelper.displayExpr(e)));

        driverSignStats.forEach(stat => {
          const facility = facilities.find(f => f.id === stat.tenantId);
          const driver = employees.find(e => e.id === stat.employeeId);

          (stat as any).__tenant = oc(facility).shortname('UNKNOWN');
          (stat as any).__driver = driver ? this.employeeHelper.displayExpr(driver) : 'UNKNOWN';
        });

        return driverSignStats;
      }),

      map(stats => {
        const aso: ArrayStoreOptions = {
          key: 'id',
          data: stats,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeDataSource() {
    const store = this.dss.getStore(Employee);
    const dso: DataSourceOptions = {
      store,
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  onCellPrepared(e) {}
}
