import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import ArrayStore from 'devextreme/data/array_store';
import query from 'devextreme/data/query';
import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import head from 'lodash-es/head';
import isDate from 'lodash-es/isDate';
import isEmpty from 'lodash-es/isEmpty';
import isString from 'lodash-es/isString';
import sortBy from 'lodash-es/sortBy';
import toPairs from 'lodash-es/toPairs';
import moment, { utc } from 'moment';
import { oc } from 'ts-optchain';
//
import { environment } from '../../../../environments/environment';
import { dxStoreInsertHooks, dxStoreLoadHooks, dxStoreRemoveHooks, dxStoreUpdateHooks, } from '../../../shared/classes/loopback-custom-store/generic/store.utils';
import { asShortDate, asTime } from '../../../shared/classes/utils/time.utils';
import { hTenant } from '../../../shared/classes/utils/utils';
import { Config, Facility, TripManifestApi, TripManifestRec, } from '../../../shared/sdk';
import { CommonService } from '../../../shared/modules/my-common/services/common.service';
import { ConfigProps, ConfigService } from '../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../shared/modules/my-common/services/pusher.service';
import { UploadHelperService } from '../../../shared/modules/ui/services/upload-helper.service';
import { HelperService as ConsumerHelperService } from '../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../employee/services/helper.service';
import { HelperService as VehicleHelperService } from '../../vehicle/services/helper.service';
import { DESTINATIONS, DESTINATIONS_FOR_BASE } from '../classes/enums';
import uniqBy from 'lodash-es/uniqBy';
import { of } from 'rxjs';
import StringSimilarity from 'string-similarity';
export class HelperService {
    constructor(http, api, common, config, dss, window, employeeHelper, consumerHelper, vehicleHelper, pusher, uploadHelper) {
        this.http = http;
        this.api = api;
        this.common = common;
        this.config = config;
        this.dss = dss;
        this.window = window;
        this.employeeHelper = employeeHelper;
        this.consumerHelper = consumerHelper;
        this.vehicleHelper = vehicleHelper;
        this.pusher = pusher;
        this.uploadHelper = uploadHelper;
        this.manifest_saveFieldMapper = (field, value) => {
            if (field === HelperService.REC_FIELD_MAP.time && isDate(value)) {
                value = asTime(value);
            }
            return [field, value];
        };
    }
    get getTimeInterval() {
        return HelperService.TIME_INTERVAL;
    }
    get getDriverPositionNames() {
        return this.config.get(ConfigProps.driverList, []);
    }
    get getMaxTrips() {
        return HelperService.MAX_TRIPS;
    }
    get getRecFieldMap() {
        return HelperService.REC_FIELD_MAP;
    }
    buildArrayStoreAsync(manifest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const helper = this;
            const data = (manifest.data || []).filter(rec => {
                const cId = rec[HelperService.REC_FIELD_MAP.consumerId];
                const seat = rec[HelperService.REC_FIELD_MAP.seat];
                return oc(cId)(0) > 0 || seat !== -1; // TODO: remove hardcode
            });
            const vFilter = {};
            const eFilter = {
                fields: ['id', 'status', 'personId', 'tenantIds'],
                include: [
                    {
                        relation: 'person',
                        scope: {
                            fields: ['firstname', 'lastname', 'contactId'],
                        },
                    },
                ],
            };
            const cFilter = {
                fields: { data: false },
                include: [
                    {
                        relation: 'relatedNotes',
                        scope: { order: 'dateTime DESC', limit: 1 },
                    },
                    {
                        relation: 'person',
                        scope: {
                            fields: ['firstname', 'lastname', 'contactId'],
                            include: [
                                {
                                    relation: 'contact',
                                    scope: {
                                        fields: ['id'],
                                        include: ['addresses', 'phones', 'emails'],
                                    },
                                },
                            ],
                        },
                    },
                ],
            };
            const prepareRec = (rec, vehicleMap, employeeMap, escortMap, consumerMap) => {
                rec[HelperService.REC_FIELD_MAP.vehicle] = rec.v ? vehicleMap[rec.v] : null;
                rec[HelperService.REC_FIELD_MAP.employee] = rec.e ? employeeMap[rec.e] : null;
                rec[HelperService.REC_FIELD_MAP.escort] = rec.esc ? escortMap[rec.esc] : null;
                rec[HelperService.REC_FIELD_MAP.consumer] = rec.c ? consumerMap[rec.c] : null;
                rec[HelperService.REC_FIELD_MAP.tripId] = oc(rec).tId('');
                rec[HelperService.REC_FIELD_MAP.broker] = oc(rec).b('');
                Object.defineProperty(rec, HelperService.REC_FIELD_MAP._employee_fullName, {
                    get() {
                        const self = this;
                        return helper.employeeHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.employee));
                    },
                });
                Object.defineProperty(rec, HelperService.REC_FIELD_MAP._escort_fullName, {
                    get() {
                        const self = this;
                        return helper.employeeHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.escort));
                    },
                });
                Object.defineProperty(rec, HelperService.REC_FIELD_MAP._consumer_fullName, {
                    get() {
                        const self = this;
                        return helper.consumerHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.consumer));
                    },
                });
                rec.toResidence = function () {
                    const self = this;
                    return self[HelperService.REC_FIELD_MAP.destination] === 'RESIDENCE';
                }.bind(rec);
                rec.getVehicleTitle = function () {
                    const self = this;
                    return helper.vehicleHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.vehicle));
                }.bind(rec);
                rec.getEmployeeFullName = function () {
                    const self = this;
                    return helper.employeeHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.employee));
                }.bind(rec);
                rec.getEscortFullName = function () {
                    const self = this;
                    return helper.employeeHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.escort));
                }.bind(rec);
                rec.getConsumerFullName = function () {
                    const self = this;
                    return helper.consumerHelper.displayExpr(get(self, HelperService.REC_FIELD_MAP.consumer));
                }.bind(rec);
                rec.getFullAddress = function () {
                    const self = this;
                    const addresses = get(self, `${HelperService.REC_FIELD_MAP.consumer}.person.contact.addresses`) || [];
                    const address = (self.aid && addresses.find(a => a.id === self.aid)) || head(addresses);
                    return address ? `${address.street}, ${address.city}, ${address.state}, ${address.zip}` : undefined;
                }.bind(rec);
                rec.getMainPhone = function () {
                    const self = this;
                    const phone = head(get(self, `${HelperService.REC_FIELD_MAP.consumer}.person.contact.phones`) || []);
                    return phone ? phone.value : undefined;
                }.bind(rec);
                rec.getTranspInstrs = function () {
                    const self = this;
                    return get(self, `${HelperService.REC_FIELD_MAP.consumer}.transpInstrs`) || '';
                }.bind(rec);
                rec.getSpecialInstrs = function () {
                    const self = this;
                    return get(self, `${HelperService.REC_FIELD_MAP.consumer}.specialInstrs`) || '';
                }.bind(rec);
                rec.getNotes = function () {
                    const self = this;
                    const note = head(get(self, `${HelperService.REC_FIELD_MAP.consumer}.relatedNotes`) || []);
                    const info = note
                        ? [
                            [
                                note.infoBy ? `by ${note.infoBy}` : '',
                                note.infoDate ? `on ${utc(note.infoDate).format('YYYY/MM/DD')}` : '',
                            ]
                                .filter(chnk => !isEmpty(chnk))
                                .join(' '),
                            note.followUpDate ? `Follow up on ${utc(note.followUpDate).format('YYYY/MM/DD')}` : '',
                        ]
                            .filter(chnk => !isEmpty(chnk))
                            .join(', ')
                        : '';
                    return note
                        ? [
                            moment(note.dateTime).format('YYYY/MM/DD HH:mm'),
                            note.author,
                            note.text + (!isEmpty(info) ? ` (${info})` : ''),
                        ].join(' : ')
                        : '';
                }.bind(rec);
            };
            const loadDetailsAsync = (manifestId, recs) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!manifestId || isEmpty(recs)) {
                    return;
                }
                const { vehicles: vehicleMap, employees: employeeMap, escorts: escortMap, consumers: consumerMap, } = yield this.api
                    .loadDataDetails(manifestId, JSON.stringify(vFilter), JSON.stringify(eFilter), JSON.stringify(eFilter), JSON.stringify(cFilter))
                    .toPromise();
                employeeMap[this.employeeHelper.selfEntity.id] = this.employeeHelper.selfEntity;
                recs.forEach((rec) => prepareRec(rec, vehicleMap, employeeMap, escortMap, consumerMap));
            });
            const loadRecDetailsAsync = (rec) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const vehicleMap = {};
                const employeeMap = {};
                const escortMap = {};
                const consumerMap = {};
                if (rec.v > 0) {
                    vehicleMap[rec.v] = yield this.employeeHelper.vehicleApi
                        .findById(rec.v, vFilter)
                        .toPromise()
                        .catch(() => null);
                }
                if (rec.e > 0) {
                    employeeMap[rec.e] = yield this.employeeHelper.api
                        .findById(rec.e, eFilter)
                        .toPromise()
                        .catch(() => null);
                }
                if (rec.esc > 0) {
                    escortMap[rec.esc] = yield this.employeeHelper.api
                        .findById(rec.esc, eFilter)
                        .toPromise()
                        .catch(() => null);
                }
                if (rec.c > 0) {
                    consumerMap[rec.c] = yield this.consumerHelper.api
                        .findById(rec.c, cFilter)
                        .toPromise()
                        .catch(() => null);
                }
                employeeMap[this.employeeHelper.selfEntity.id] = this.employeeHelper.selfEntity;
                prepareRec(rec, vehicleMap, employeeMap, escortMap, consumerMap);
            });
            //
            yield loadDetailsAsync(manifest.id, data);
            //
            const aso = {
                key: TripManifestRec.getModelDefinition().idName,
                data,
            };
            const as = new ArrayStore(aso);
            dxStoreInsertHooks(as, (values) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const recData = Object.keys(values)
                    .filter(k => Object.keys(TripManifestRec.getModelDefinition().properties).includes(k))
                    .reduce((o, k) => (Object.assign({}, o, { [k]: values[k] })), {});
                const instance = yield this.api.createRecords(manifest.id, recData).toPromise();
                yield loadRecDetailsAsync(instance);
                return [instance];
            }), ([values], [resValues, resKey]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return [resValues, resKey];
            }));
            dxStoreUpdateHooks(as, (key, values) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const recData = Object.keys(values)
                    .filter(k => Object.keys(TripManifestRec.getModelDefinition().properties).includes(k))
                    .reduce((o, k) => (Object.assign({}, o, { [k]: values[k] })), {});
                const instance = yield this.api.updateByIdRecords(manifest.id, key, recData).toPromise();
                yield loadRecDetailsAsync(instance);
                return [key, instance];
            }), ([key, values], [resValues, resKey]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return [resValues, resKey];
            }));
            dxStoreRemoveHooks(as, (key) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.api.destroyByIdRecords(manifest.id, key).toPromise();
                // await this.api.patchAttributes(manifest.id,
                //   {data: data.filter(rec => rec.id !== key)}).toPromise();
                return [key];
            }), ([key], [resKey]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return [resKey];
            }));
            dxStoreLoadHooks(as, (obj) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const tripAutoSequence = this.config.get(ConfigProps.tripAutoSequence, false);
                if (tripAutoSequence) {
                    this.calcSeats(data);
                }
                obj.sort = [
                    ...(obj.sort || []),
                    'getEmployeeFullName',
                    'toResidence',
                    HelperService.REC_FIELD_MAP.trip,
                    HelperService.REC_FIELD_MAP.seat,
                ];
                return [obj];
            }), ([obj], [recs]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                // await loadDetailsAsync(manifest.id, recs);
                return [recs];
            }));
            return as;
        });
    }
    calcSeats(manifestData) {
        manifestData = manifestData || [];
        const byEmployee = groupBy(manifestData, HelperService.REC_FIELD_MAP.employeeId);
        toPairs(byEmployee).forEach(([, itemsByEmp]) => {
            const byDestination = groupBy(itemsByEmp, rec => rec[HelperService.REC_FIELD_MAP.destination] === 'RESIDENCE');
            toPairs(byDestination).forEach(([, itemsByDest]) => {
                const byTrip = groupBy(itemsByDest, HelperService.REC_FIELD_MAP.trip);
                toPairs(byTrip).forEach(([, itemsByTrip]) => {
                    sortBy(itemsByTrip, [
                        HelperService.REC_FIELD_MAP.time,
                        HelperService.REC_FIELD_MAP._consumer_fullName,
                    ]).forEach((rec, idx) => (rec.s = idx + 1));
                });
            });
        });
    }
    buildOrFilter(field, values) {
        return (values || [])
            .map(v => [field, '=', v])
            .reduce((arr, itm, idx) => {
            if (idx > 0)
                arr.push('or');
            arr.push(itm);
            return arr;
        }, []);
    }
    buildDriversFilter(field) {
        return (this.getDriverPositionNames || [])
            .map(pn => [field, '=', pn])
            .reduce((arr, itm) => {
            if (arr && arr.length) {
                arr.push('or');
            }
            arr.push(itm);
            return arr;
        }, []);
    }
    loadManifestOrTemplateAsync(date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!date) {
                return null;
            }
            const manifest = yield this.api.getCurrentOrLastManifest(asShortDate(date)).toPromise();
            // fix DateString object
            if (isString(manifest.date) && manifest.date.indexOf('when') >= 0) {
                manifest.date = JSON.parse(manifest.date).when;
            }
            manifest.date = asShortDate(manifest.date);
            return manifest;
        });
    }
    validateManifest(tenantId, manifest, tripsMap) {
        if (!manifest.data)
            return [];
        const helper = this;
        const errors = [];
        const invalidTrips = [];
        const notMatchAppointment = [];
        const notMatchConsumerName = [];
        const notMatchConsumerAddr = [];
        const pendingConsumer = [];
        const noAccessEmployees = [];
        manifest.data.forEach(d => {
            const trip = tripsMap[d[HelperService.REC_FIELD_MAP.tripId]];
            if (trip) {
                if (d.at && trip._time !== d.at.replace(':', ''))
                    notMatchAppointment.push(Object.assign({}, d, { validationError: `From Broker: ${`${trip._time.slice(0, 2)}:${trip._time.slice(-2)}`}<br> On Manifest: ${d.at}` }));
                const consumerFullName = helper.consumerHelper.displayExpr({
                    person: { firstname: trip._firstname, lastname: trip._lastname },
                });
                const [a1, a2] = [consumerFullName, d.getConsumerFullName()].map(a => a.toLowerCase().replace(/[,\s]+/g, ''));
                if (a1 != a2 && [trip._firstname, trip._lastname].find(n => !a2.includes(n.toLowerCase())))
                    notMatchConsumerName.push(Object.assign({}, d, { validationError: `From Broker: ${consumerFullName}<br> On Manifest: ${d.getConsumerFullName()}` }));
                if (trip._broker === 'MTM') {
                    if (trip['Trip Status'] !== 'S1')
                        invalidTrips.push(Object.assign({}, d, { validationError: `Trip Status: ${trip['Trip Status']}` }));
                    const type = trip['Trip Type'] == 'T' ? 'Pickup' : 'Delivery';
                    const consumerAddr = `${trip[`${type} Address`]}, ${trip[`${type} City`]}, ${trip[`${type} State`]}, ${trip[`${type} Zip Code`]}`;
                    const [a1, a2] = [d.getFullAddress(), consumerAddr].map(a => a.toLowerCase().replace(/[,\s]+/g, ''));
                    const [[b1], [b2]] = [d.getFullAddress(), consumerAddr].map(a => a.split(/[,\s]/g));
                    const [c1, c2] = [d.getFullAddress(), consumerAddr].map(a => a.split(/[,\s]/g).pop());
                    if (a1 != a2 && (b1 != b2 || c1 != c2 || StringSimilarity.compareTwoStrings(a1, a2) < 0.55))
                        notMatchConsumerAddr.push(Object.assign({}, d, { validationError: `From Broker: ${consumerAddr}<br> On Manifest: ${d.getFullAddress()}` }));
                }
            }
            if (d.__consumer && d.__consumer.status == 'PENDING')
                pendingConsumer.push(Object.assign({}, d, { validationError: `Consumer Status: ${d.__consumer.status}` }));
            if (d.__employee && d.__employee.id !== -1 && !(d.__employee.tenantIds || []).includes(tenantId))
                noAccessEmployees.push(Object.assign({}, d, { validationError: `Driver does not have access to current tenant` }));
        });
        if (invalidTrips.length)
            errors.push({
                code: 'INVALID_TRIP_ID',
                error: 'There are invalid Trip IDs',
                recHint: '· Invalid Trip ID',
                recs: invalidTrips,
                priority: 'danger-crossed',
            });
        if (notMatchAppointment.length)
            errors.push({
                code: 'NOT_MATCH_APPOINTMENT',
                error: 'There are mismatch appointments',
                recHint: '· Appointment Mismatch',
                recs: notMatchAppointment,
                priority: 'danger',
            });
        if (notMatchConsumerName.length)
            errors.push({
                code: 'NOT_MATCH_CONSUMER',
                error: 'There are mismatch consumers` names',
                recHint: '· Consumer`s Name Mismatch',
                recs: notMatchConsumerName,
                priority: 'warning',
            });
        if (notMatchConsumerAddr.length)
            errors.push({
                code: 'NOT_MATCH_ADDR_CONSUMER',
                error: 'There are mismatch consumers` addresses',
                recHint: '· Consumer`s Address Mismatch',
                recs: notMatchConsumerAddr,
                priority: 'warning',
            });
        if (pendingConsumer.length)
            errors.push({
                code: 'PENDING_CONSUMER',
                error: 'There are pending consumers',
                recHint: '· Pending Consumer',
                recs: pendingConsumer,
                priority: 'warning',
            });
        if (noAccessEmployees.length)
            errors.push({
                code: 'NO_ACCESS_EMPLOYEES',
                error: `There are drivers without access to current tenant`,
                recHint: '· Driver does not have access to current tenant',
                recs: noAccessEmployees,
                priority: 'warning',
            });
        return errors;
    }
    validateManifest$(manifest) {
        return manifest && manifest.id ? this.api.validateManifest(manifest.id) : of([]);
    }
    buildConsumerPerDriverMap(recs) {
        const pairs = query(recs || [])
            .filter(rec => rec.e && rec.e > 0)
            .groupBy(HelperService.REC_FIELD_MAP.employeeId)
            .toArray()
            .map(({ key, items }) => [
            key,
            new Set(items.map((rec) => rec.c)),
        ]);
        return new Map(pairs);
    }
    buildConsumerTotalSet(recs) {
        return new Set((recs || []).filter(rec => rec.c && rec.c > 0).map(rec => rec.c));
    }
    buildVehicleTotalSet(recs) {
        return new Set((recs || []).filter(rec => rec.v && rec.v > 0).map(rec => rec.v));
    }
    buildDriverTotalSet(recs) {
        return new Set((recs || []).filter(rec => rec.e && rec.e > 0).map(rec => rec.e));
    }
    getManifestConsumerTripsMap(recs) {
        return new Map(toPairs(groupBy(recs || [], HelperService.REC_FIELD_MAP.consumerId)).map(([consumerId, subRecs]) => [
            parseInt(consumerId, 10),
            new Map(toPairs(groupBy(subRecs, HelperService.REC_FIELD_MAP.destination)).map(([dest, subSubRecs]) => [dest, new Set(subSubRecs.map(rec => rec.id))])),
        ]));
    }
    buildDateManifestMapAsync(weeksBefore = 5, weeksAfter = 1) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const from = asShortDate(moment().subtract(weeksBefore, 'weeks'));
            const to = asShortDate(moment().add(weeksAfter, 'weeks'));
            const manifests = (yield this.api
                .find({
                where: {
                    and: [{ date: { gt: from } }, { date: { lte: to } }],
                },
                fields: ['date', 'id'],
            })
                .toPromise()) || [];
            const pairs = manifests.map((m) => [asShortDate(m.date), m.id]);
            return new Map(pairs);
        });
    }
    exportManifestFileName(employee = null) {
        const timestamp = moment().format('YYYY.MM.DD');
        const firstName = get(employee, 'person_firstname', get(employee, 'person.firstname', ''));
        const lastName = get(employee, 'person_lastname', get(employee, 'person.lastname', ''));
        return `${employee ? `${firstName}_${lastName}` : 'all_drivers'}__${timestamp}`;
    }
    getWarningGroupsWithRecs(validationSummary, cellInfo, codes) {
        return (validationSummary || [])
            .filter(group => codes.includes(group.code))
            .map(group => (Object.assign({}, group, { rec: group.recs.find(rec => rec.id === cellInfo.data.id) })))
            .filter(warning => warning.rec);
    }
    processCellWarnings(validationSummary, cellInfo, codes, noHint = false) {
        if (cellInfo.rowType === 'data' && cellInfo.data && cellInfo.data.id) {
            const groups = this.getWarningGroupsWithRecs(validationSummary, cellInfo, codes);
            if (groups && groups.length) {
                const [hint, priorities] = groups.reduce(([str, prs], g) => [
                    str.concat((str.length ? '\n' : '') + g.recHint),
                    g.priority ? prs.concat(g.priority) : prs,
                ], ['', []]);
                cellInfo.cellElement.classList.add(priorities.find(p => p.includes('danger')) ? 'cell-danger' : 'cell-yellow');
                if (priorities.find(p => p.includes('crossed')))
                    cellInfo.cellElement.setAttribute('style', 'text-decoration: line-through');
                if (!noHint)
                    cellInfo.cellElement.setAttribute('title', hint);
            }
        }
    }
    printManifestAsync(manifest, settings) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            settings = Object.assign({}, settings, { baseUrl: environment.apiBaseUrl });
            const jobID = yield this.api.pdfManifestJob(manifest.id, JSON.stringify(settings)).toPromise();
            const { bucket, filePdf, fileHtml, uri } = yield this.pusher.requestResponse(jobID).toPromise();
            const resp = yield this.http
                .get(uri, {
                responseType: 'blob',
                withCredentials: false,
            })
                .toPromise();
            const src = URL.createObjectURL(resp);
            // console.log(resp, src);
            yield this.common.printHtmlSrc(document, src).toPromise();
        });
    }
    getXlsxManifestAsync(manifest, settings) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            settings = Object.assign({}, settings, { baseUrl: environment.apiBaseUrl });
            const jobID = yield this.api.xlsxManifestJob(manifest.id, JSON.stringify(settings)).toPromise();
            const { bucket, file, url } = yield this.pusher.requestResponse(jobID).toPromise();
            // const url = `https://storage.googleapis.com/${bucket}/${file}`;
            window.open(url);
        });
    }
    tripsFromBrokerToManifestJob(manifest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const jobID = yield this.api.fillUpBrokerDataJob(manifest.id).toPromise();
            const { id } = yield this.pusher.requestResponse(jobID).toPromise();
            return id;
        });
    }
    getDestinationsDsAsync(currentTenantId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let ds = [];
            if (currentTenantId) {
                const currentTenant = yield this.dss.getApi(Facility).findById(currentTenantId).toPromise();
                const destList = (yield this.dss.getApi(Config).getAllDestinations(hTenant(currentTenantId)).toPromise()).map(dest => ({ ID: dest.short, Name: dest.name, Group: dest.group }));
                // TODO: remove harcode
                if (currentTenant.type === 'BASE') {
                    ds = DESTINATIONS_FOR_BASE;
                }
                else {
                    ds = DESTINATIONS;
                }
                ds = uniqBy(ds.concat(destList), 'ID');
            }
            return ds;
        });
    }
}
// TODO: remove hardcode
// static readonly DRIVER_POSITION_NAMES = ['Driver', 'CDL Driver', 'Supervisor'];
HelperService.TIME_INTERVAL = 5;
HelperService.MAX_TRIPS = 99;
HelperService.REC_FIELD_MAP = {
    time: 't',
    trip: 'tr',
    seat: 's',
    isRoundTrip: 'rt',
    origin: 'o',
    destination: 'd',
    dailyNote: 'dn',
    vehicleId: 'v',
    employeeId: 'e',
    escortId: 'esc',
    consumerId: 'c',
    addressId: 'aid',
    broker: 'b',
    tripId: 'tId',
    brokerNote: 'bn',
    appointmentTime: 'at',
    pickUpTime: 'put',
    dropOffTime: 'dot',
    serviceType: 'st',
    mealsUnits1: 'mu1',
    mealsUnits2: 'mu2',
    cancelled: 'x',
    oneTimeTrip: 'ot',
    vehicle: '__vehicle',
    employee: '__employee',
    _employee_fullName: '__employee_fullName',
    escort: '__escort',
    _escort_fullName: '__escort_fullName',
    consumer: '__consumer',
    _consumer_fullName: '__consumer_fullName',
};
HelperService.FIELD_VALIDATION_CODES_MAP = {
    [HelperService.REC_FIELD_MAP.destination]: ['NO_DESTINATION'],
    [HelperService.REC_FIELD_MAP.time]: ['NO_TIME'],
    [HelperService.REC_FIELD_MAP.trip]: ['NO_TRIP'],
    [HelperService.REC_FIELD_MAP.tripId]: [
        'INVALID_TRIP_ID',
        'NOT_MATCH_APPOINTMENT',
        'NOT_MATCH_CONSUMER',
        'NOT_MATCH_ADDR_CONSUMER',
        'DUPLICATE_TRIPIDS',
    ],
    [HelperService.REC_FIELD_MAP.seat]: ['NO_SEAT', 'DUPLICATE_SEATS'],
    [HelperService.REC_FIELD_MAP.employeeId]: [
        'NOT_EXIST_EMPLOYEES',
        'NOT_ACTIVE_EMPLOYEES',
        'VEHICLE_MULTI_ASSIGNMENTS',
        'NO_ACCESS_EMPLOYEES',
    ],
    [HelperService.REC_FIELD_MAP.vehicleId]: ['VEHICLE_SEVERAL_ASSIGNMENTS', 'EMPLOYEE_VEHICLE_NO_ASSIGNMENT'],
    [HelperService.REC_FIELD_MAP.consumerId]: [
        'NOT_EXIST_CONSUMERS',
        'NOT_ACTIVE_CONSUMERS',
        'DUPLICATE',
        'NO_RESIDENCE_ROUTE',
        'NO_FACILITY_ROUTE',
        'NO_ACTIVE_MEALS_AUTH',
        'PENDING_CONSUMER',
    ],
};
