import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import ArrayStore, { ArrayStoreOptions } from 'devextreme/data/array_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import compact from 'lodash-es/compact';
import groupBy from 'lodash-es/groupBy';
import uniq from 'lodash-es/uniq';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { hAll } from '../../../../shared/classes/utils/utils';
import { ExtLoopBackAuth } from '../../../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import {
  Covid19Checks,
  Covid19ChecksApi,
  Facility,
  LoggerService,
  LoopBackAuth,
  Signature,
  SignatureApi,
} from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';

@Component({
  selector: 'app-covid19-checks-weekly',
  templateUrl: './covid19-checks-weekly.component.html',
  styleUrls: ['./covid19-checks-weekly.component.scss'],
})
export class Covid19ChecksWeeklyComponent extends ABaseComponent implements OnInit {
  dso = new DataSource([]);

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  facilityDso$: Observable<DataSourceOptions> = of([]);

  grid_stateStoring: any;

  facility: Facility | undefined;

  @Input() selectedDateValue?: Date = new Date();

  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    @Inject(LoopBackAuth) protected auth: ExtLoopBackAuth,
    private dss: DataSourceService,
    private covidChecksApi: Covid19ChecksApi,
    public consumerHelper: ConsumerHelperService,
    public empHelper: EmployeeHelperService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    private dialog: MatDialog,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'c2d7e9c0-814f-479d-b58a-ef7e8c88994a',
    };
    this.facilityDso$ = this.buildFacilityDataSource();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(async () => {
          this.grid.instance.endCustomLoading();
          this.grid.instance.beginCustomLoading('Filtering...');

          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.dso = new DataSource([]);
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.dso = new DataSource(as);
          this.grid.instance.refresh();
        }),
        tap(async () => {
          await this.grid.instance.deselectAll();
          this.grid.instance.clearSelection();
          this.grid.instance.clearFilter();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    return of(true).pipe(
      switchMap(() => this.config.tenant$),

      map((tenant: Facility) => {
        if (!this.selectedDateValue) {
          throw new Error('Date should be defined');
        }

        // if (!this.facilityId) {
        //   throw new Error('Facility should be defined');
        // }

        this.facility = tenant;

        const date = this.selectedDateValue;
        const dateMoment = date && moment(date);
        const strDate = dateMoment && dateMoment.format('YYYY-MM-DD');

        return {
          date: strDate,
          facilityIds: [tenant.id],
        };
      }),

      switchMap(fltr => {
        const strBeginWeekDate = moment(fltr.date).startOf('week').format('YYYY-MM-DD');
        const strEndWeekDate = moment(fltr.date).endOf('week').format('YYYY-MM-DD');

        return this.covidChecksApi.find<Covid19Checks>(
          {
            where: {
              and: [
                { vdate: { gte: strBeginWeekDate } },
                { vdate: { lte: strEndWeekDate } },
                { tenantId: { inq: fltr.facilityIds } },
                { meta: { $json_e_c: { '$.covidReportMode': JSON.stringify('final') } } },
              ],
            },
            order: ['vdate ASC'],
            include: [{ employee: 'person' }, { consumer: 'person' }],
          },
          hAll,
        );
      }),

      map((recs: Covid19Checks[]) => {
        // recs = uniqBy(recs, (chk) => oc(chk).consumerId(0) + '_' + oc(chk).employeeId(0));
        const grouped = groupBy(recs, chk => oc(chk).consumerId(0) + '_' + oc(chk).employeeId(0));

        recs = Object.values(grouped).map(g => {
          const rec = g[0];
          (rec as any)._daysOfWeek = uniq(g.map(itm => moment(itm.vdate).format('dd'))).join(', ');
          return rec;
        });

        recs.forEach(r => {
          (r as any)._adcCovid19Measures = this.config.get('adcCovid19Measures', '');

          (r as any).getEmployee = function () {
            const _self = this;
            return oc(_self).employee.person.firstname() + ' ' + oc(_self).employee.person.lastname();
          }.bind(r);

          (r as any).getConsumer = function () {
            const _self = this;
            return oc(_self).consumer.person.firstname() + ' ' + oc(_self).consumer.person.lastname();
          }.bind(r);

          (r as any).getName = function () {
            const _self = this;
            const participant = oc(_self).consumer() || oc(_self).employee();

            return oc(participant).person.firstname() + ' ' + oc(participant).person.lastname();
          }.bind(r);

          (r as any).getStaffOrParticipant = function () {
            const _self = this;
            const isParticipant = oc(_self).consumer();
            const isStaff = oc(_self).employee();

            return isParticipant ? 'Participant' : 'Staff';
          }.bind(r);

          (r as any).getStaffTitle = function () {
            const _self = this;

            return oc(_self).employee.data.title('');
          }.bind(r);

          (r as any).getReportingMode = function () {
            const _self = this;
            return oc(_self).meta.covidReportMode('basic');
          }.bind(r);
        });

        const aso: ArrayStoreOptions = {
          key: Covid19Checks.getModelDefinition().idName,
          data: recs,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),

      takeUntil(this.$onDestroy$),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      // disabled: this.config.isSU$,
      name: 'buildFinalReport',
      locateInMenu: 'auto',
      widget: 'dxButton',
      location: 'after',
      sortIndex: 30,
      showText: 'always',
      options: {
        icon: 'far fa-final-pdf',
        text: 'Build Final Covid19 Report',
        hint: 'Build Final Covid19 Report',
        onClick: () => {
          if (this.grid.instance.getSelectedRowKeys().length === 0) {
            notify('No items selected', 'error', 5000);
            return;
          }

          if (!this.selectedDateValue) {
            notify('No date selected', 'error', 5000);
            return;
          }

          const strBeginWeekDate = moment(this.selectedDateValue).startOf('week').format('YYYY-MM-DD');
          const strEndWeekDate = moment(this.selectedDateValue).endOf('week').format('YYYY-MM-DD');

          this.dss
            .getApi<SignatureApi>(Signature)
            .find<Signature>({
              where: {
                and: [{ vdate: { gte: strBeginWeekDate } }, { vdate: { lte: strEndWeekDate } }, { type: 'S' }],
              },
              fields: { id: true, employeeId: true },
            })
            .pipe(
              // switchMap((itms) => this.dialog.open(
              //   DlgSelectEmployeeComponent,
              //   {
              //     width: '450px',
              //     maxHeight: '650px',
              //     hasBackdrop: true,
              //     data: {
              //       title: 'Select ADC Staff',
              //       // filter: ['id', 'inq', uniq(itms.map((s) => s.employeeId))],
              //       filter: ['employeePosition_name', '=', 'ADC Staff'],
              //     },
              //   }
              // ).afterClosed().pipe(
              //   filter(identity),
              //   tap(async ([staffId]) => this.buildFinalReport(staffId)),
              // )),

              tap(async () => this.buildFinalReport()),

              catchError(err => of(notify(err.message || err, 'error', 5000))),
              takeUntil(this.$onDestroy$),
            )
            .subscribe();
        },
      },
    });
  }

  buildFinalReport() {
    // staffId: number
    if (!this.selectedDateValue) {
      notify('No date selected', 'error', 5000);
      return;
    }
    const strDate = moment(this.selectedDateValue).format('YYYY-MM-DD');

    const selectedRows: Covid19Checks[] = this.grid.instance.getSelectedRowsData();
    if (selectedRows.length === 0) {
      notify('No items selected', 'error', 5000);
      return;
    }

    notify('Report Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Report Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_COVID_FINAL_REPORT_RPC', {
        date: strDate,
        cIds: uniq(compact(selectedRows.map(chk => chk.consumerId))),
        eIds: uniq(compact(selectedRows.map(chk => chk.employeeId))),
        // staffId,
        useRunService: true,
      })
      .pipe(
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        tap((uri: string) => window.open(uri)),
        catchError(err => {
          notify(err.message, 'error', 5000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(res => console.log(res));
  }
}
