import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { hAll } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Facility, LoggerService } from '../../../../shared/sdk';
import ArrayStoreOptions = DevExpress.data.ArrayStoreOptions;
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import PivotGridDataSourceField = DevExpress.data.PivotGridDataSourceField;
import PivotGridDataSourceOptions = DevExpress.data.PivotGridDataSourceOptions;

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent extends ABaseComponent implements OnInit {
  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  dso: any = {
    store: [],
    fields: this.pivotFields,
  } as PivotGridDataSourceOptions;

  facilityDso$: Observable<DataSourceOptions> = of([]);

  grid_stateStoring: any;

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();

  showTotalsPrior = false;
  rowsDataFieldArea = false;
  treeHeaderLayout = false;

  getBrokerTrips = true;
  getDummyTrips = true;
  getScheduledTrips = true;

  get pivotFields(): Array<PivotGridDataSourceField> {
    return [
      { dataField: 'tenantId', visible: false },

      { area: 'column', dataField: 'date', dataType: 'date', groupInterval: 'year' },
      { area: 'column', dataField: 'date', dataType: 'date', groupInterval: 'quarter' },
      { area: 'column', dataField: 'date', dataType: 'date', groupInterval: 'month' },
      { area: 'column', dataField: 'date', dataType: 'date', groupInterval: 'day' },

      { caption: 'Tenant', area: 'row', dataField: 'tenant', dataType: 'string', isMeasure: false },

      {
        caption: 'Clients Signed',
        area: 'data',
        dataField: 'signedClients',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },
      {
        caption: 'Signatures Collected',
        area: 'data',
        dataField: 'signatures',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },

      {
        caption: 'Auth Trips',
        area: 'data',
        dataField: 'authTrips',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
        visible: this.getBrokerTrips,
      },
      {
        caption: 'UnAuth Trips',
        area: 'data',
        dataField: 'unAuthTrips',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
        visible: this.getBrokerTrips,
      },
      {
        caption: 'Dummy Trips',
        area: 'data',
        dataField: 'dummyTrips',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
        visible: this.getBrokerTrips && this.getDummyTrips,
      },

      {
        caption: 'Ineligible Trips',
        area: 'data',
        dataField: 'ineligibleTrips',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
      },

      {
        caption: 'TripIDs Received (from Broker)',
        area: 'data',
        dataField: 'brokerTrips',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
        visible: this.getBrokerTrips,
      },
      {
        caption: 'Trips Scheduled (on Manifest)',
        area: 'data',
        dataField: 'scheduled',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
        visible: this.getScheduledTrips,
      },
      {
        caption: 'Did Not Attend',
        area: 'data',
        dataField: 'didNotAttend',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
        visible: this.getScheduledTrips,
      },
      {
        caption: 'Missing Signatures',
        area: 'data',
        dataField: 'missingSignatures',
        dataType: 'number',
        summaryType: 'sum',
        isMeasure: true,
        visible: this.getScheduledTrips,
      },
    ];
  }

  // @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;
  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  constructor(
    protected logger: LoggerService,
    private ui: UiService,
    public config: ConfigService,
    private gridHelper: GridHelperService,
    private dss: DataSourceService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    @Inject(HttpClient) private http: HttpClient,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '73dbec4c-7495-42a6-adc7-48fe971dff4f',
    };

    this.facilityDso$ = this.buildFacilityDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(() => {
          this.ui.showLoading();
          // this.grid.instance.endCustomLoading();
          // this.grid.instance.beginCustomLoading('Filtering...');
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of(new ArrayStore({ data: [] }));
            }),
          ),
        ),
        tap(as => {
          this.dso = {
            store: as,
            fields: this.pivotFields,
          } as PivotGridDataSourceOptions;
          // this.grid.instance.refresh();
        }),
        tap(() => {
          this.ui.hideLoading();
          // this.grid.instance.endCustomLoading();
          // this.grid.instance.clearSelection();
          // this.grid.instance.clearFilter();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildDataSource() {
    return of(true).pipe(
      map(() => {
        const from = this.selectedFromValue;
        const to = this.selectedToValue;

        const fromMoment = from && moment(from);
        const toMoment = to && moment(to).add(1, 'days');

        if (!fromMoment || !toMoment) {
          throw new Error('Period should be defined');
        }

        if (toMoment.diff(fromMoment, 'months') > 1) {
          throw new Error('Period should be less or equal to 1 month');
        }
        const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
        const strTo = toMoment && toMoment.format('YYYY-MM-DD');

        return {
          fromIncl: strFrom,
          toExcl: strTo,
          project: {
            brokerTrips: this.getBrokerTrips,
            dummyTrips: this.getDummyTrips,
            scheduledTrips: this.getScheduledTrips,
          },
        };
      }),

      switchMap(fltr =>
        this.pusher.rpc(
          'GET_SIGNATURES_STATS',
          {
            ...fltr,
            useRunService: true,
          },
          true,
          hAll,
        ),
      ),

      switchMap(url =>
        this.http.get(url, {
          responseType: 'json',
          withCredentials: false,
        }),
      ),

      map(recs => {
        const aso: ArrayStoreOptions = {
          key: 'tenantId',
          data: recs,
        } as ArrayStoreOptions;

        return new ArrayStore(aso);
      }),
    );
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      filter: ['type', 'inq', ['ADC', 'BASE']],
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    } as DataSourceOptions;
    return of(dso);
  }

  filterClaims() {
    this.$filterEvent$.next(true);
  }
}
