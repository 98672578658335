import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
// import { getCurrentScope } from '@sentry/browser';
import { combineLatest, concat, defer, iif, of, throwError } from 'rxjs';
import { catchError, delay, exhaustMap, first, map, switchMap, tap } from 'rxjs/operators';
import { ExtLoopBackAuth } from '../../shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { CommonService } from '../../shared/modules/my-common/services/common.service';
import { FacilityApi, LoggerService, MyUser, MyUserApi } from '../../shared/sdk';
import { HideLoadingPanel, LoadConfig, LoadEnvVars, ShowLoadingPanel } from '../actions/core';
import { CHECK_AUTH, CHECK_AUTH_FAIL, CHECK_AUTH_SUCCESS, CheckAuth, CheckAuthFail, CheckAuthSuccess, LOAD_ROLES_SUCCESS, LoadRoles, LoadTenant, LoadTenantSuccess, LOGIN, LOGIN_FAIL, LOGIN_SUCCESS, LOGIN_WITH_MFA, LoginFail, LoginSuccess, LoginWithMfa, LOGOUT, LOGOUT_FAIL, LOGOUT_SUCCESS, LogoutFail, LogoutSuccess, SET_TENANT, SET_TENANT_FAIL, SET_TENANT_SUCCESS, SetTenantFail, SetTenantSuccess, } from '../actions/sign';
export class SignEffects {
    constructor(appRef, actions$, router, location, auth, logger, common, userApi, facilityApi) {
        this.appRef = appRef;
        this.actions$ = actions$;
        this.router = router;
        this.location = location;
        this.auth = auth;
        this.logger = logger;
        this.common = common;
        this.userApi = userApi;
        this.facilityApi = facilityApi;
        this.init$ = defer(() => this.appRef.isStable).pipe(first(isStable => isStable === true), delay(1), // wait for load all effects
        tap(() => {
            this.common.store.dispatch(new CheckAuth());
        }));
        this.check$ = this.actions$.pipe(ofType(CHECK_AUTH), switchMap(g => iif(() => this.userApi.isAuthenticated(), of(this.auth.getToken()).pipe(exhaustMap(token => combineLatest([
            of(token),
            of(this.auth.getCurrentTenant() || null),
            this.userApi.getTenants(token.userId),
            // this.userApi.getRoles(token.userId) as Observable<string[]>,
            this.userApi.getEnabledComponents(token.userId),
        ]))), throwError('User not authenticated')).pipe(map(([token, currentTenant, tenants, 
        // roles,
        enabledComponents,]) => new CheckAuthSuccess({
            token,
            currentTenant,
            tenants,
            // roles,
            enabledComponents,
        })), catchError(err => of(new CheckAuthFail(err))))));
        this.login$ = this.actions$.pipe(ofType(LOGIN), map((action) => action.payload), tap(() => {
            this.auth.setCurrentTenant(null);
            // this.common.store.dispatch(new SetTenantLocal(null));
            this.common.store.dispatch(new LoadTenantSuccess(undefined));
        }), exhaustMap((user) => this.userApi.login(user).pipe(switchMap((token) => token.mfaSecret
            ? of([token])
            : combineLatest([
                of(token),
                this.userApi.getTenants(token.userId),
                // this.userApi.getRoles(token.userId) as Observable<string[]>,
                this.userApi.getEnabledComponents(token.userId),
            ])), map(([token, tenants, 
        // roles,
        enabledComponents,]) => token.mfaSecret
            ? new LoginWithMfa({ token })
            : new LoginSuccess({
                token,
                tenants,
                // roles,
                enabledComponents,
            })), catchError(err => of(new LoginFail(err))))));
        this.logout$ = this.actions$.pipe(ofType(LOGOUT), exhaustMap(() => concat(this.userApi.logout(), this.router.navigate(['/sign'])).pipe(map(() => new LogoutSuccess()), catchError(err => of(new LogoutFail(err))))));
        this.loginSuccess$ = this.actions$.pipe(ofType(LOGIN_SUCCESS), map((action) => action.payload));
        this.loginWithMfa$ = this.actions$.pipe(ofType(LOGIN_WITH_MFA), map((action) => action.payload));
        this.checkSuccess$ = this.actions$.pipe(ofType(CHECK_AUTH_SUCCESS), map((action) => action.payload));
        this.checkFail$ = this.actions$.pipe(ofType(CHECK_AUTH_FAIL), tap(() => this.auth.clear()), map((action) => action.payload));
        this.loginFail$ = this.actions$.pipe(ofType(LOGIN_FAIL), tap(() => this.auth.clear()), map((action) => action.payload));
        this.logoutFinally$ = this.actions$.pipe(ofType(LOGOUT_SUCCESS, LOGOUT_FAIL), tap(() => this.auth.clear()), tap(() => this.common.store.dispatch(new ShowLoadingPanel())), switchMap(() => tslib_1.__awaiter(this, void 0, void 0, function* () { return this.router.navigate(['/sign']); })), tap(() => this.common.store.dispatch(new HideLoadingPanel())));
        this.finally$ = this.actions$.pipe(ofType(CHECK_AUTH_SUCCESS, CHECK_AUTH_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, LOGOUT_FAIL, SET_TENANT_SUCCESS, SET_TENANT_FAIL), tap(action => {
            if (this.userApi.isAuthenticated()) {
                const user = this.auth.getCurrentUserData();
                // getCurrentScope().setUser({
                //   id: '' + user.id,
                //   email: user.email,
                //   username: user.username,
                // });
            }
            else {
                // getCurrentScope().setUser(null);
            }
        }), delay(1), // wait for each action consumer processed
        tap(action => {
            this.common.store.dispatch(new LoadRoles());
            this.common.store.dispatch(new LoadEnvVars());
            this.common.store.dispatch(new LoadConfig());
            this.common.store.dispatch(new LoadTenant());
        }));
        this.setTenant$ = this.actions$.pipe(ofType(SET_TENANT), map((action) => action.payload), exhaustMap((tenantId) => this.facilityApi.setTenant(tenantId).pipe(
        // delay(1),
        map(() => new SetTenantSuccess(tenantId)), catchError(err => of(new SetTenantFail(err))))));
        // @Effect({dispatch: false})
        // setTenantLocal$ = this.actions$.pipe(
        //   ofType(SET_TENANT_LOCAL),
        //   map((action: SetTenantLocal) => action.payload),
        //   tap(tenantId => {
        //     this.auth.setCurrentTenant(tenantId);
        //   }),
        // );
        this.setTenantSuccess$ = this.actions$.pipe(ofType(SET_TENANT_SUCCESS), map((action) => action.payload), tap(tenantId => {
            this.auth.setCurrentTenant(tenantId);
            // this.common.store.dispatch(new SetTenantLocal(payload.tenantId));
            this.common.store.dispatch(new LoadTenant());
        }), switchMap(tenantId => 
        // wait for roles loaded
        this.actions$.pipe(ofType(LOAD_ROLES_SUCCESS), //
        first())), map(action => {
            // console.log('SetTenantSuccess roles', action.payload);
            if (this.router.isActive('/sign', false)) {
                return this.router.navigate(['/dashboard']);
            }
            else {
                return this.router.navigate(['/dashboard']);
            }
        }));
        this.setTenantFail$ = this.actions$.pipe(ofType(SET_TENANT_FAIL), map((action) => action.payload), tap(() => {
            // this.common.store.dispatch(new SetTenantLocal(null));
            this.auth.setCurrentTenant(null);
            this.common.store.dispatch(new LoadTenantSuccess(undefined));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "init$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "check$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "login$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "logout$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "loginSuccess$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "loginWithMfa$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "checkSuccess$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "checkFail$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "loginFail$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "logoutFinally$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "finally$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "setTenant$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "setTenantSuccess$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SignEffects.prototype, "setTenantFail$", void 0);
