import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpResponse, HttpParameterCodec } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ErrorHandler } from './error.service';
import { LoopBackAuth } from './auth.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackFilter, AccessToken } from '../../models/BaseModels';
import { SDKModels } from '../custom/SDKModels';
import { Observable, Subject } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';
// Making Sure EventSource Type is available to avoid compilation issues.
declare var EventSource: any;
class CustomQueryEncoderHelper implements HttpParameterCodec {
  encodeKey(k: string): string {
      return encodeURIComponent(k);
  }

  encodeValue(v: string): string {
      return encodeURIComponent(v);
  }

  decodeKey(k: string): string {
      return decodeURIComponent(k);
  }

  decodeValue(v: string): string {
      return decodeURIComponent(v);
  }
}

export type TCustomHeaders = (headers: HttpHeaders) => HttpHeaders;
export type TCustomOptions = {path?: string};

/**
* @description
* Abstract class that will be implemented in every custom service automatically built
* by the sdk builder.
* It provides the core functionallity for every API call, either by HTTP Calls or by
* WebSockets.
**/
@Injectable()
export abstract class BaseLoopBackApi {

  protected path: string;
  protected model: any;

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    this.model = this.models.get(this.getModelName());
  }

  /**
   * @description
   * This is a core method, every HTTP Call will be done from here, every API Service will
   * extend this class and use this method to get RESTful communication.
   **/
  public request(
    method         : string,
    url            : string,
    routeParams    : any = {},
    urlParams      : any = {},
    postBody       : any = {},
    pubsub         : boolean = false,
    customHeaders? : TCustomHeaders
  ): Observable<any> {
    // Transpile route variables to the actual request Values
    Object.keys(routeParams).forEach((key: string) => {
      url = url.replace(new RegExp(":" + key + "(\/|$)", "g"), routeParams[key] + "$1")
    });
    if (pubsub) {
      console.info('SDK: PubSub functionality is disabled, generate SDK using -io enabled');
    } else {
      let httpParams = new HttpParams({ encoder: new CustomQueryEncoderHelper() });
      // Headers to be sent
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      // Authenticate request
      headers = this.authenticate(url, headers);
      // Body fix for built in remote methods using "data", "options" or "credentials
      // that are the actual body, Custom remote method properties are different and need
      // to be wrapped into a body object
      let body: any;
      let postBodyKeys = typeof postBody === 'object' ? Object.keys(postBody) : []
      if (postBodyKeys.length === 1) {
        body = postBody[postBodyKeys.shift()];
      } else {
        body = postBody;
      }

      let queryString: string = '';

      // Separate filter object from url params and add to search query
      if (urlParams.filter) {
        if (LoopBackConfig.isHeadersFilteringSet()) {
          headers = headers.append('filter', JSON.stringify(urlParams.filter));
        } else {
          queryString = `?filter=${encodeURIComponent(JSON.stringify(urlParams.filter))}`;
        }
        delete urlParams.filter;
      }

      // Separate where object from url params and add to search query
      if (urlParams.where) {
        if (LoopBackConfig.isHeadersWhereSet()) {
          /**
          CODE BELOW WILL GENERATE THE FOLLOWING ISSUES:
          - https://github.com/mean-expert-official/loopback-sdk-builder/issues/356
          - https://github.com/mean-expert-official/loopback-sdk-builder/issues/328
          **/
          headers = headers.append('where', JSON.stringify(urlParams.where));
        } else {
          queryString = `?where=${encodeURIComponent(JSON.stringify(urlParams.where))}`;
        }
        delete urlParams.where;
      }

      if (typeof customHeaders === 'function') {
        headers = customHeaders(headers);
      }
/* enhancement/configure-where-headers
      this.searchParams.setJSON(urlParams);
      let request: Request = new Request(
        new RequestOptions({
          headers        : headers,
          method         : method,
          url            : `${url}${queryString}`,
          search         : Object.keys(urlParams).length > 0 ? this.searchParams.getURLSearchParams() : null,
          body           : body ? JSON.stringify(body) : undefined,
          withCredentials: LoopBackConfig.getRequestOptionsCredentials()
        })
      );
TODO Fix Merge Conflict */

    httpParams = new HttpParams({fromString: this._JSON2URL(urlParams, false)});
    /*
      Object.keys(urlParams).forEach(paramKey => {
        let paramValue = urlParams[paramKey];
        paramValue = typeof paramValue === 'object' ? JSON.stringify(paramValue) : paramValue;
        httpParams = httpParams.append(paramKey, paramValue);
      });
    */

    /*
        let request = new HttpRequest(method, `${url}${queryString}`, body, {
        headers        : headers,
        params         : httpParams,
        withCredentials: LoopBackConfig.getRequestOptionsCredentials()
      });
    */

      return this.http.request(method, `${url}${queryString}`, {
        headers        : headers,
        body           : body ? JSON.stringify(body) : undefined,
        params         : httpParams,
        observe: 'response',
        responseType:   'json',
        withCredentials: LoopBackConfig.getRequestOptionsCredentials(),
      }).pipe(
        filter(event => event instanceof HttpResponse),
        map((res: HttpResponse<any>) => res.body !== null && res.body !== undefined ? res.body : {}),
        catchError((e) => this.errorHandler.handleError(e))
      );
    }
  }

    private _JSON2URL(obj, parent) {
        var parts = [];
        for (var key in obj)
            parts.push(this._parseParam(key, obj[key], parent));
        return parts.join('&');
    }

    private _parseParam(key, value, parent) {
        let processedKey = parent ? parent + '[' + key + ']' : key;
        if (value && ((typeof value) === 'object' || Array.isArray(value))) {
            return this._JSON2URL(value, processedKey);
        }
        return processedKey + '=' + value;
    }

  /**
   * @description
   * This method will try to authenticate using either an access_token or basic http auth
   */
  public authenticate<T>(url: string, headers: HttpHeaders): HttpHeaders {
    if (this.auth.getAccessTokenId()) {
      headers = headers.append(
        'X-Access-Token',
        LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId()
      );
    }

    return headers;
  }

  /**
   * @description
   * Generic create method
   */
  public create<T>(data: T, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('POST', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path
    ].join('/'), undefined, undefined, { data }, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }


  /**
   * @description
   * Generic create many method
   */
  public createMany<T>(data: T[], customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T[]> {
    return this.request('POST', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path
    ].join('/'), undefined, undefined, { data }, null, customHeaders)
    .pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
  }


  /**
   * @description
   * Generic findById method
   */
  public findById<T>(id: any, filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    let _urlParams: any = {};
    if (filter) _urlParams.filter = filter;
    return this.request('GET', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      ':id'
    ].join('/'), { id }, _urlParams, undefined, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }

  /**
   * @description
   * Generic find method
   */
  public find<T>(filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T[]> {
    return this.request('GET', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path
    ].join('/'), undefined, { filter }, undefined, null, customHeaders)
    .pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
  }

  /**
   * @description
   * Generic exists method
   */
  public exists<T>(id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('GET', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      ':id/exists'
    ].join('/'), { id }, undefined, undefined, null, customHeaders);
  }

  /**
   * @description
   * Generic findOne method
   */
  public findOne<T>(filter: LoopBackFilter = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('GET', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'findOne'
    ].join('/'), undefined, { filter }, undefined, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }

  /**
   * @description
   * Generic updateAll method
   */
  public updateAll<T>(where: any = {}, data: T, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<{ count: 'number' }> {
    let _urlParams: any = {};
    if (where) _urlParams.where = where;
    return this.request('POST', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'update'
    ].join('/'), undefined, _urlParams, { data }, null, customHeaders);
  }


  /**
   * @description
   * Generic deleteById method
   */
  public deleteById<T>(id: any, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('DELETE', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      ':id'
    ].join('/'), { id }, undefined, undefined, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }


  /**
   * @description
   * Generic count method
   */
  public count(where: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<{ count: number }> {
    let _urlParams: any = {};
    if (where) _urlParams.where = where;
    return this.request('GET', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'count'
    ].join('/'), undefined, _urlParams, undefined, null, customHeaders);
  }

  /**
   * @description
   * Generic updateAttributes method
   */
  public updateAttributes<T>(id: any, data: T, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('PUT', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      ':id'
    ].join('/'), { id }, undefined, { data }, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }


  /**
   * @description
   * Generic upsert method
   */
  public upsert<T>(data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('PUT', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
    ].join('/'), undefined, undefined, { data }, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }


  /**
   * @description
   * Generic upsert method using patch http method
   */
  public upsertPatch<T>(data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('PATCH', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
    ].join('/'), undefined, undefined, { data }, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }


  /**
   * @description
   * Generic upsertWithWhere method
   */
  public upsertWithWhere<T>(where: any = {}, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    let _urlParams: any = {};
    if (where) _urlParams.where = where;
    return this.request('POST', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'upsertWithWhere'
    ].join('/'), undefined, _urlParams, { data }, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }


  /**
   * @description
   * Generic replaceOrCreate method
   */
  public replaceOrCreate<T>(data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('POST', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'replaceOrCreate'
    ].join('/'), undefined, undefined, { data }, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }


  /**
   * @description
   * Generic replaceById method
   */
  public replaceById<T>(id: any, data: any = {}, customHeaders?: TCustomHeaders, customOptions?: TCustomOptions): Observable<T> {
    return this.request('POST', [
      customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      ':id', 'replace'
    ].join('/'), { id }, undefined, { data }, null, customHeaders)
    .pipe(map((data: T) => this.model.factory(data)));
  }


  /**
   * @description
   * Generic createChangeStream method
   */
  public createChangeStream(customOptions?: TCustomOptions): Observable<any> {
    let subject = new Subject();
    if (typeof EventSource !== 'undefined') {
      let emit   = (msg: any) => subject.next(JSON.parse(msg.data));
      var source = new EventSource([
        customOptions && customOptions.path ? customOptions.path : LoopBackConfig.getPath(),
        LoopBackConfig.getApiVersion(),
        this.model.getModelDefinition().path,
        'change-stream'
      ].join('/'));
      source.addEventListener('data', emit);
      source.onerror = emit;
    } else {
      console.warn('SDK Builder: EventSource is not supported');
    }
    return subject.asObservable();
  }

  /**
   * @description
   * Abstract getModelName method
   */
  abstract getModelName(): string;
}
