import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataSourceOptions } from 'devextreme/data/data_source';
import moment from 'moment';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { ABaseComponent } from 'src/app/shared/modules/ui/components/abstract/a-base.component';
import { ShowLoopDialogComponent } from 'src/app/shared/modules/ui/components/show-loop-dialog/show-loop-dialog.component';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Employee, Facility, Vehicle, VehicleGeotab } from '../../../../shared/sdk/models';
import { EmployeeApi, FacilityApi, LoggerService } from '../../../../shared/sdk/services/custom';

@Component({
  selector: 'app-vehicle-details-tab-history',
  templateUrl: './vehicle-details-tab-history.component.html',
  styleUrls: ['./vehicle-details-tab-history.component.scss'],
  providers: [],
})
export class VehicleDetailsTabHistoryComponent extends ABaseComponent {
  _vehicle: Vehicle;

  @Input()
  set vehicle(v: Vehicle) {
    this._vehicle = v;
    this.changeHandle();
  }
  get vehicle() {
    return this._vehicle;
  }

  dso: DataSourceOptions;

  constructor(
    protected logger: LoggerService,
    protected ui: UiService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
  ) {
    super(logger);
  }

  changeHandle() {
    if (this.vehicle) this.dso = this.buildDSO();
  }

  showLoop_onClick = v => {
    this.dialog.open(ShowLoopDialogComponent, {
      panelClass: 'loop-dialog',
      backdropClass: 'loop-dialog',
      width: '80%',
      height: '80%',
      hasBackdrop: true,
      data: {
        vin: this.vehicle.vin,
        date: v.loop.startDate,
        loopId: v.loop.loopId,
        title: `${(v.tenant && v.tenant.name) || ''} ${moment(v.date, 'YYYY-MM-DD').format('M/D/YYYY')}`,
      },
    });
  };

  private buildDSO() {
    return {
      load: async () => {
        const [facilities, days, loops] = await Promise.all([
          this.dss.getApi<FacilityApi>(Facility).find<Facility>().toPromise(),
          gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getGeoTabDaysAggregate()).pipe().toPromise(),
          gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getGeoTabPositionLoopsAggregate()).pipe().toPromise(),
        ]);
        const drivers = await this.dss
          .getApi<EmployeeApi>(Employee)
          .find<Employee>(
            {
              where: { id: { inq: [...new Set([...days.map(d => d.employeeId), ...loops.map(l => l.employeeId)])] } },
              include: ['person'],
            },
            hAll,
          )
          .toPromise();
        return this.prepareData(facilities, drivers, days, loops);
      },
    } as DataSourceOptions;
  }

  private prepareData(facilities: Facility[], drivers: Employee[], days: any, loops: any) {
    const mFacilitie = facilities.reduce((p, v) => ({ ...p, [v.id]: v }), {});
    const mDriver = drivers.reduce(
      (p, { id, person: { firstname, lastname } }) => ({ ...p, [id]: `${firstname} ${lastname}` }),
      {},
    );
    const mLoop = loops.reduce((p, v) => ({ ...p, [v.day]: v }), {});
    return days.map(day => {
      const d = '' + day.day;
      const date = `${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(-2)}`;
      return {
        ...day,
        id: day.day,
        date: date,
        distance: Math.round(day.distance),
        tenant: mFacilitie[day.tenantId || (mLoop[day.day] && mLoop[day.day].tenantId)],
        driver: mDriver[day.employeeId || (mLoop[day.day] && mLoop[day.day].employeeId)],
        loop: mLoop[day.day] && mLoop[day.day].loop,
      };
    });
  }
  getGeoTabDaysAggregate() {
    return [
      { $match: { internalId: this.vehicle.internalId } },
      { $unwind: '$days' },
      { $match: { 'days.distance': { $gte: 1 } } },
      {
        $project: {
          day: '$days.day',
          distance: '$days.distance',
          tenantId: '$days.tenantId',
          employeeId: '$days.employeeId',
        },
      },
      { $sort: { day: -1 } },
    ];
  }

  getGeoTabPositionLoopsAggregate() {
    return [
      { $match: { vin: this.vehicle.vin } },
      { $project: { positions: 1 } },
      { $unwind: '$positions' },
      { $match: { 'positions.loop': { $exists: true } } },
      {
        $project: {
          day: { $dateToString: { format: '%Y%m%d', date: '$positions.dateTime' } },
          loop: '$positions.loop',
          tenantId: '$positions.tenantId',
          employeeId: '$positions.employeeId',
        },
      },
      { $group: { _id: '$day', last: { $last: '$$ROOT' } } },
      {
        $project: {
          _id: 0,
          day: { $toInt: '$_id' },
          loop: '$last.loop',
          tenantId: '$last.tenantId',
          employeeId: '$last.employeeId',
        },
      },
    ];
  }
}
