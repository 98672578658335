import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { Person, Signature, SignatureApi, VehicleGeotab, VehicleGeotabApi } from 'src/app/shared/sdk';
import { ConfigService } from '../../../my-common/services/config.service';
import { DataSourceService } from '../../../my-common/services/datasource.service';

export const TIMEZONE = 'America/New_York';

@Component({
  selector: 'app-show-loop-dialog',
  templateUrl: './show-loop-dialog.component.html',
  styleUrls: ['./show-loop-dialog.component.scss'],
})
export class ShowLoopDialogComponent implements OnInit {
  markerBaseUrl = '/assets/images/';
  markers = [];
  routes = [];
  positions = [];
  signatures = [];

  constructor(
    private dialogRef: MatDialogRef<ShowLoopDialogComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public config: ConfigService,
    protected dss: DataSourceService,
  ) {}

  async ngOnInit() {
    await this.getData(this.data);
    await this.prepareMapData(this.data);
  }
  async getData({ vin, date, loopId }: any) {
    const res = await this.dss.getApi<VehicleGeotabApi>(VehicleGeotab).getLoop({ vin, date, loopId }).toPromise();
    this.positions = (res[0] || {}).positions || [];

    this.signatures = [];
    if (this.positions.length > 5) {
      this.signatures = await this.dss
        .getApi<SignatureApi>(Signature)
        .find<Signature>(
          {
            where: {
              employeeId: this.positions[0].employeeId,
              vdate: moment(this.positions[0].dateTime).format('YYYY-MM-DD'),
            },
            include: [{ employee: 'person' }, { consumer: 'person' }],
          },
          hAll,
        )
        .toPromise();
    }
  }

  async prepareMapData(data) {
    console.log('prepareMapData');
    let routes = [];
    let locations = [];
    this.markers = [];
    const { markers: signMarkers, ePerson } = this.getSignatureMarkers(
      this.positions[0].dateTime,
      this.positions[this.positions.length - 1].dateTime,
    );

    this.positions.forEach((pos, i) => {
      const location = [pos.latitude, pos.longitude];
      locations.push(location);
      if (pos.speed === 0 && pos.duration > 90) {
        this.markers.push(this.getStopMarker(pos, ePerson));
      }

      if (locations.length > 24 || i === this.positions.length - 1) {
        routes.push(this.getRoute(locations));
        locations = [location];
      }
    });

    this.routes = [...routes];
    const tp = this.positions[this.positions.length - 1].tenantPosition;
    if (tp) this.markers.push(this.getTenantMarker(tp));
    if (data.vehicle) this.markers.push(this.getEventMarker(data.vehicle, ePerson));
    this.markers = [...this.markers, ...signMarkers];
  }

  private getRoute(locations) {
    return {
      weight: 4,
      color: 'blue',
      opacity: 0.8,
      mode: '',
      locations: [...locations],
    };
  }

  private getStopMarker({ latitude, longitude, dateTime, duration, loop }, ePerson): any {
    return {
      iconSrc: this.markerBaseUrl + 'marker-stop.png',
      location: `${latitude}, ${longitude}`,
      tooltip: {
        text:
          `${ePerson.firstname} ${ePerson.lastname}` +
          `<br/><em>Time:</em> ${moment(dateTime).format('LT')}` +
          `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}` +
          `<br/><em>Loop Id:</em> ${(loop && loop.loopId) || ''}` +
          `<br/><em>Loop Start:</em> ${(loop && moment(loop.startDate).format('LT')) || ''}`,
        isShown: false,
      },
    };
  }

  private getEventMarker(vehicle, ePerson): any {
    return {
      iconSrc: `${this.markerBaseUrl}${vehicle.icon}`,
      location: `${vehicle.latitude}, ${vehicle.longitude}`,
      tooltip: { text: `${ePerson.firstname} ${ePerson.lastname}` + `<br/>${vehicle.text}`, isShown: false },
    };
  }

  private getTenantMarker(pos: any): any {
    return {
      iconSrc: `${this.markerBaseUrl}marker-facility.png`,
      location: `${pos.latitude}, ${pos.longitude}`,
    };
  }

  private getSignatureMarkers(startDate, endDate): any {
    let ePerson = <Person>{};
    let markers = [];

    this.signatures.forEach(s => {
      ePerson = (s.employee && s.employee.person) || ePerson;
      const cPerson = (s.consumer && s.consumer.person) || {};
      const { firstname, lastname } = cPerson;
      if (s.meta.pickUpDeviceLocation) {
        const tc = moment.tz(`${s.vdate} ${s.pickupTime}`, TIMEZONE).utc().format();
        if (startDate < tc && tc < endDate) {
          markers.push(this.getSignatureMarker(true, s.meta.pickUpDeviceLocation, tc, ePerson, cPerson));
        }
        const p = this.findStopPosition(tc);
        if (p) markers.push(this.getSignatureMarkerTmp(true, p, ePerson, cPerson));
      }

      if (s.meta.dropOffDeviceLocation) {
        const tc = moment.tz(`${s.vdate} ${s.dropoffTime}`, TIMEZONE).utc().format();
        if (startDate < tc && tc < endDate) {
          markers.push(this.getSignatureMarker(false, s.meta.dropOffDeviceLocation, tc, ePerson, cPerson));
        }
        const p = this.findStopPosition(tc);
        if (p) markers.push(this.getSignatureMarkerTmp(false, p, ePerson, cPerson));
      }
    });
    return { markers, ePerson };
  }

  private findStopPosition(time) {
    return (
      time &&
      this.positions.find(
        ({ dateTime, duration, speed, loop }) =>
          !speed && moment(dateTime).add(duration + 60, 'seconds') > moment(time),
      )
    );
  }

  private getSignatureMarker(isPickUp, { lat, lng, isGeoTab }, tc, ePerson, cPerson): any {
    return {
      iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}.png`,
      location: `${lat}, ${lng}`,
      tooltip: {
        text:
          `${ePerson.firstname} ${ePerson.lastname}` +
          `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} ${cPerson.firstname} ${cPerson.lastname}` +
          `<br/><em>Time:</em> ${moment(tc).format('LT')}` +
          `<br/>${(isGeoTab && 'GeoTab') || ''}`,
        isShown: false,
      },
    };
  }

  private getSignatureMarkerTmp(
    isPickUp,
    { latitude, longitude, dateTime, duration, speed, loop },
    ePerson,
    cPerson,
  ): any {
    return {
      iconSrc: `${this.markerBaseUrl}marker-${isPickUp ? 'pickup' : 'dropoff'}-tmp.png`,
      location: `${latitude}, ${longitude}`,
      tooltip: {
        text:
          `${ePerson.firstname} ${ePerson.lastname}` +
          `<br/>${isPickUp ? 'Pick Up' : 'Drop Off'} ${cPerson.firstname} ${cPerson.lastname}` +
          `<br/><em>Time:</em> ${moment(dateTime).format('LT')}` +
          `<br/><em>State Duration:</em> ${moment.duration(duration, 'seconds').humanize()}` +
          `<br/><em>Speed:</em> ${speed}` +
          `<br/><em>Loop Id:</em> ${(loop && loop.loopId) || ''}` +
          `<br/><em>Loop Start:</em> ${(loop && moment(loop.startDate).format('LT')) || ''}` +
          `<br/>GeoTab`,
        isShown: false,
      },
    };
  }
}
