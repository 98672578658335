import { Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import moment from 'moment';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoLoad } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { hAll } from '../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { DlgSelectSignatureComponent } from '../../../../shared/modules/ui/components/dlg-select-signature/dlg-select-signature.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import {
  Consumer,
  ConsumerApi,
  LoggerService,
  LoopBackFilter,
  PersDevice,
  PersDeviceApi,
  Person,
  SignatureConsUniqImgView,
} from '../../../../shared/sdk';
import { HelperService } from '../../services/helper.service';
import { ConsumerFormComponent } from '../consumer-form/consumer-form.component';

@Component({
  selector: 'app-consumer-details-tab-profile',
  templateUrl: './consumer-details-tab-profile.component.html',
  styleUrls: ['./consumer-details-tab-profile.component.scss'],
})
export class ConsumerDetailsTabProfileComponent
  extends ABaseModelPaneWToolbarComponent<Consumer, ConsumerApi>
  implements OnInit, OnChanges, OnDestroy
{
  modelWithDetails$: BehaviorSubject<Consumer & { _brokerClient; _authClient }> = new BehaviorSubject(null);

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    public helper: HelperService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    public common: CommonService,
  ) {
    super(logger, ui, dss);

    this.caption = 'Consumer Personal Info';
  }

  ngOnInit() {
    super.ngOnInit();

    this.model$
      .pipe(
        switchMap(model => {
          if (model) {
            // const mealsAuths$ = this.api.getMealsAuths(model.id, moment().format('YYYY-MM-DD'), this.customHeaders);
            // const mealsEstimated$ = this.api.getMealsEstimated(model.id, moment().format('YYYY-MM-DD'), this.customHeaders);

            const brokerClients$ = of(true).pipe(
              switchMap(() =>
                !isEmpty(model.mci)
                  ? gqlMongoLoad(this.dss, 'ExportsConsumersCache', {
                      filter: [['_mci', '=', model.mci]],
                    })
                  : of([]),
              ),
            );

            return combineLatest([
              brokerClients$,
              // mealsAuths$,
              // mealsEstimated$,
            ]).pipe(
              map(([brokerClients]) => ({
                ...model,
                _brokerClient: brokerClients.find(bc => !(bc._broker as string).includes('.AUTH')),
                _authClient: brokerClients.find(bc => (bc._broker as string).includes('.AUTH')),
                // _mealsAuths: mealsAuths,
                // _mealsEstimated: mealsEstimated,
              })),
            );
          } else {
            return of(null);
          }
        }),
        tap(modelWithDetails => this.modelWithDetails$.next(modelWithDetails)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  protected get ModelClass(): any {
    return Consumer;
  }

  protected get FormComponent(): Type<ABaseFormComponent<Consumer>> {
    return ConsumerFormComponent;
  }

  protected get filter(): LoopBackFilter {
    return {
      include: [
        'program',
        {
          relation: 'relatedNotes',
          scope: { order: 'dateTime DESC' },
        },
        { person: { contact: ['addresses', 'phones', 'emails'] } },
        { emRelations: { person: { contact: ['addresses', 'phones', 'emails'] } } },
        'persDevices',
      ],
    };
  }

  protected get observeModels(): any[] {
    return [this.ModelClass, Person];
  }

  get activePersDevice(): PersDevice | undefined {
    const persDevices = oc(this.model).persDevices([]);
    const activePersDevice = persDevices.find(pd => pd.status === 'ACTIVE');
    return activePersDevice;
  }

  protected onAddClickHandler(): void {
    this.ui.openEditDialog({
      modelId: null,
      ModelClass: this.ModelClass,
      FormComponentClass: this.FormComponent,
    });
  }

  protected buildToolbarItems(model: any = null) {
    super.buildToolbarItems(model);

    if (this.config.tenantType === 'TC') {
      this.toolbarItems.push({
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'Check PERS device',
          hint: 'Send status check request to PERS device',
          disabled: !model || !this.activePersDevice,
          onClick: () => this.checkPersDevice(),
        },
      });
    }
  }

  changeSign_onClick(e) {
    void this.dialog
      .open<
        any,
        any,
        {
          data: SignatureConsUniqImgView;
          toAllSignatures?: boolean;
          toClient?: boolean;
        }
      >(DlgSelectSignatureComponent, {
        hasBackdrop: true,
        data: {
          consumer: this.model,
        },
      })
      .afterClosed()
      .pipe(
        switchMap(v =>
          iif(
            () => !!oc(v).data(),
            of(v).pipe(
              tap(() => this.ui.showLoading()),

              switchMap(res => {
                return this.dss
                  .getApi<ConsumerApi>(Consumer)
                  .patchAttributes(this.modelId, { signImgFileId: res.data.imgFileId }, hAll)
                  .pipe(
                    tap(() => {
                      this.model.signImgFileId = res.data.imgFileId;
                    }),
                  );
              }),
              catchError(err => of(notify(err.message, 'error', 5000))),

              tap(() => this.ui.hideLoading()),
            ),
          ),
        ),
      )
      .toPromise();
  }

  protected onEditClickHandler(): void {
    const subTitle = [
      moment.utc(this.model.person.dob).isValid()
        ? 'DOB:' + moment.utc(this.model.person.dob).utc().format('MM/DD/YYYY')
        : undefined,
      this.model.mci ? 'MCI:' + this.model.mci : undefined,
      this.model.status ? this.model.status : undefined,
      // this.model.activeMco ? this.model.activeMco : undefined,
      // this.model.activeBroker ? this.model.activeBroker : undefined,
    ]
      .filter(i => !isNil(i))
      .join(' - ');
    const title = `Edit: ${this.helper.displayExpr(this.model)}${subTitle.length ? ' - ' + subTitle : ''}`;

    this.ui.openEditDialog({
      modelId: this.modelId,
      ModelClass: this.ModelClass,
      FormComponentClass: this.FormComponent,
      title,
      headerBtns: [
        {
          icon: 'fa-file-pdf',
          tooltip: 'Get MTM transportation form',
          click() {
            const settings = {};
            this.helperService.getMtmTranspForm(this.modelId, settings).toPromise().finally();
          },
        },
      ],
    });
  }

  private checkPersDevice() {
    this.ui.showLoading();

    if (!this.activePersDevice) {
      notify('No PERS device found', 'warning', 5000);
    } else {
      void this.dss
        .getApi<PersDeviceApi>(PersDevice)
        .checkStatus(this.activePersDevice._id)
        .pipe(
          catchError(err => of(notify(err.message, 'error', 5000))),
          tap(() => this.ui.hideLoading()),
          takeUntil(this.$onDestroy$),
        )
        .toPromise();
    }
  }
}
