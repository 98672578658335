import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { DlgEmployeeWorkingTimeComponent } from 'src/app/modules/employee/components/employee-working-time/dlg-employee-working-time/dlg-employee-working-time.component';
import { asShortDate } from 'src/app/shared/classes/utils/time.utils';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseModelLoaderComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-loader.component';
import {
  Config,
  ConfigApi,
  DriverSchedule,
  DriverScheduleApi,
  Employee,
  EmployeeApi,
  EmployeeWorkingTime,
  EmployeeWorkingTimeApi,
  LoggerService,
  LoopBackAuth,
  TripManifest,
  Vehicle,
  VehicleApi,
} from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { ITotals, RouterHelperService } from '../../services/router-helper.service';
import { DlgOverwriteManifestConfirm } from './dlg-overwrite-manifest-confirm/dlg-overwrite-manifest-confirm.component';
import { DlgRoutesMapComponent } from './dlg-routes-map/dlg-routes-map.component';

@Component({
  selector: 'app-router',
  templateUrl: './router.component.html',
  styleUrls: ['./router.component.scss'],
  providers: [HelperService, RouterHelperService, ConsumerHelperService, EmployeeHelperService],
})
export class RouterComponent extends ABaseModelLoaderComponent<Employee> implements OnInit, OnChanges, OnDestroy {
  orderOptions = [
    { key: 'least', value: 'Least Busy First' },
    { key: 'most', value: 'Most Busy First' },
  ];
  destinationsMap: any = {};
  selectedDate: Date = new Date();
  vehicles: Vehicle[] = [];
  vehiclesAvailable: Vehicle[] = [];
  manifestVehiclesMap: any = {};
  vehiclesMap: any = {};
  selectedVehicleIds: number[] = [];
  employees: Employee[] = [];
  employeesAvailable: Employee[] = [];
  employeeMap: any = {};
  employeeWorkingTimeMap: any = {};
  selectedEmployeeIdsSet: Set<number> = new Set();
  keepManifestTrips = false;
  withPreferred = false;
  withAvoid = false;
  useCache = false;
  manifest: TripManifest;
  manifestGroups: any[] = [];
  manifestGroupsOrg: any[] = [];
  conflictTripsMap: any = {};
  dataSource: any[] = [];
  dataSourceOrder = 'least';
  showWorkTime = false;
  lockSelectedTrips = false;
  totals: { manifest: ITotals; proposed: ITotals } = {
    manifest: { totalVehicles: 0, combinedLoadedMinutes: 0, totalNumberOfTrips: 0 },
    proposed: { totalVehicles: 0, combinedLoadedMinutes: 0, totalNumberOfTrips: 0 },
  };

  errorStyle = 'background-color: #ffc1c1; color: #333; padding: 2px 3px; border-radius: 4px;margin-left: -3px;';
  breakStyle = 'background-color: #c1ffc1; color: #333; padding: 2px 3px; border-radius: 4px;margin-right: -3px;';

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    protected cd: ChangeDetectorRef,
    protected logger: LoggerService,
    public config: ConfigService,
    protected dss: DataSourceService,
    private ui: UiService,
    public helper: HelperService,
    public router: RouterHelperService,
    public consumerHelper: ConsumerHelperService,
    public employeeHelper: EmployeeHelperService,
    protected dialog: MatDialog,
    protected sanitizer: DomSanitizer,
    @Inject(LoopBackAuth) protected auth: ExtLoopBackAuth,
  ) {
    super(logger, dss);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadData();
  }

  protected get ModelClass(): any {
    return Employee;
  }

  async calendar_onValueChanged(e) {
    this.ui.showLoading();
    await this.loadManigest();
    await this.loadEmployeeWorkingTime();
    this.router.sortDataSource(this.dataSource, this.dataSourceOrder, this.employeeWorkingTimeMap);
    this.ui.hideLoading();
  }

  isToday = (date: Date) => moment(date).isSame(moment(), 'day');
  isTomorrow = (date: Date) => moment(date).isSame(moment().add(1, 'day'), 'day');
  getCalendarBadgeClasses = (date: Date) => ({
    'badge-small': true,
    red: this.isToday(date),
    orange: this.isTomorrow(date),
    green: !this.isToday(date) && !this.isTomorrow(date),
  });

  getCalendarText = (date: Date) => {
    const todayOrTomorrow = (this.isToday(date) && 'Today') || (this.isTomorrow(date) && 'Tomorrow');
    if (todayOrTomorrow) return `${todayOrTomorrow} (${moment(date).format('ddd')})`;
    return moment(date).format('dddd');
  };

  grid_onDragStart = (e: any) => {
    this.router.dragStart(e, this.dataSource);
  };

  grid_onDragMove = (e: any) => {
    this.router.dragMove(e, this.cd);
  };

  grid_onDragChange = (e: any) => {
    this.router.dragChange(e, this.http, this.cd, this.dataSource, asShortDate(this.selectedDate));
  };

  grid_onAdd = async (e: any) => {
    await this.router.dragAdd(e, this.http, this.dataSource, asShortDate(this.selectedDate));
    this.totals.proposed = {
      ...this.router.calculateTotals(this.dataSource.map(t => t.proposedGroup).filter(t => t)),
    };
  };

  getSequenceClass = ({ dragPushed, dragPushedBefore }: any) =>
    'badge-small ' + ((dragPushed && 'orange') || (dragPushedBefore && 'orange light') || '');

  getLoadBadgeClasses(group: any): { [key: string]: boolean } {
    return {
      'badge-yellow': group.load.percent > 10 && group.load.percent <= 45,
      'badge-green': group.load.percent > 45,
    };
  }

  getConsumerFullName = c => this.consumerHelper.displayExpr(c.__consumer);
  toDate = (time: string | null): Date | null => (time ? moment(time, 'HH:mm:ss').toDate() : null);

  getConsumerSettings = ({ __consumer: c }) => {
    if (!c) return '';
    let settings = [];
    if (c.keepStretcher) settings.push('KS');
    if (c.onBoardingDuration) settings.push('PU+');
    if (c.offBoardingDuration) settings.push('DO+');
    return settings.join(', ');
  };

  getHours(totalMinutes) {
    if (!totalMinutes) return '00:00';
    let hh = Math.floor(totalMinutes / 60);
    let mm = Math.abs(totalMinutes % 60);
    return `${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`;
  }

  distanceInMiles = (distance: number) => (distance && Math.round(distance * 0.000621371192)) || 0;

  selectedVehiclesChangeHandler(e) {
    const set = new Set(this.selectedVehicleIds);
    this.vehiclesAvailable = this.vehicles.filter(v => !set.has(v.id));
    this.dataSource.forEach(d => {
      if (d.manifestGroup && d.manifestGroup.vehicle && !set.has(d.manifestGroup.vehicle.id)) {
        d.manifestGroup.vehicle = null;
        d.manifestGroup.employee = null;
      }
      if (d.proposedGroup && d.proposedGroup.vehicle && !set.has(d.proposedGroup.vehicle.id)) {
        d.proposedGroup.vehicle = null;
        d.proposedGroup.employee = null;
      }
    });
  }

  assignVehicleHandler(group, altGroup, value) {
    if (!this.selectedVehicleIds.includes(value)) {
      this.selectedVehicleIds.push(value);
    }
    group.vehicle = this.vehiclesMap[value];
    if (altGroup) altGroup.vehicle = this.vehiclesMap[value];
  }

  assignEmployeeHandler(group, altGroup, value) {
    this.selectedEmployeeIdsSet.add(value);
    group.employee = this.employeeMap[value];
    if (altGroup) altGroup.employee = this.employeeMap[value];
    this.setEmployeesAvailable();
  }

  assignEscortHandler(group, altGroup, value) {
    this.selectedEmployeeIdsSet.add(value);
    group.escort = this.employeeMap[value];
    if (altGroup) altGroup.escort = this.employeeMap[value];
    this.setEmployeesAvailable();
  }

  removeVehicleHandler(group, altGroup) {
    const idx = this.selectedVehicleIds.indexOf(group.vehicle.id);
    if (idx !== -1) this.selectedVehicleIds.splice(idx, 1);
    group.vehicle = null;
    if (altGroup) altGroup.vehicle = null;
  }

  removeEmployeeHandler(group, altGroup) {
    this.selectedEmployeeIdsSet.delete(group.employee.id);
    group.employee = null;
    if (altGroup) altGroup.employee = null;
    this.setEmployeesAvailable();
  }

  removeEscortHandler(group, altGroup) {
    this.selectedEmployeeIdsSet.delete(group.escort.id);
    group.escort = null;
    if (altGroup) altGroup.escort = null;
    this.setEmployeesAvailable();
  }

  getSchedulingConflicts = () => Object.keys(this.conflictTripsMap).length;

  shouldShowKeepManifestTrips(): boolean {
    return !this.manifestGroups.every(g => g.vehicle);
  }

  async loadData() {
    this.ui.showLoading();
    this.destinationsMap = await this.dss.getApi<ConfigApi>(Config).getDestinationsMap().toPromise();
    this.vehicles = (await this.dss
      .getApi<VehicleApi>(Vehicle)
      .find({ where: { state: 'ACTIVE' }, order: 'internalId' })
      .toPromise()) as Vehicle[];
    this.employees = (
      await this.dss
        .getApi<EmployeeApi>(Employee)
        .find<Employee>(
          // TODO: remove hardcode
          { where: { employeePositionId: { inq: [39, 40, 273] } }, include: ['person'] },
          hAll,
        )
        .toPromise()
    ).sort((a, b) => {
      const a1 = this.employeeHelper.displayExpr(a);
      const b1 = this.employeeHelper.displayExpr(b);
      return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
    });

    this.vehiclesMap = this.vehicles.reduce((p, v) => ({ ...p, [v.id]: v }), {});
    this.employeeMap = this.employees.reduce((p, e) => ({ ...p, [e.id]: e }), {});
    await this.loadManigest();
    await this.loadEmployeeWorkingTime();
    this.router.sortDataSource(this.dataSource, this.dataSourceOrder, this.employeeWorkingTimeMap);
    this.ui.hideLoading();
  }

  async loadManigest() {
    this.manifest = await this.helper.api.getCurrentManifest(asShortDate(this.selectedDate)).toPromise();
    const as: any = await this.helper.buildArrayStoreAsync(this.manifest);
    const detailedTrips = as._array;
    this.manifestGroups = this.router.makeManifestGroups(detailedTrips);
    this.manifestGroupsOrg = JSON.parse(JSON.stringify(this.manifestGroups));
    this.manifestVehiclesMap = this.manifestGroups.reduce((p, { vehicle: v }) => (v ? { ...p, [v.id]: v } : p), {});
    this.vehiclesAvailable = this.vehicles.filter(v => !this.manifestVehiclesMap[v.id]);
    this.selectedVehicleIds = this.manifestGroups.map(g => g.vehicle && g.vehicle.id).filter(id => id);
    this.selectedEmployeeIdsSet = new Set(
      this.manifestGroups.flatMap(g => [g.employee && g.employee.id, g.escort && g.escort.id]),
    );
    this.setEmployeesAvailable();
    this.conflictTripsMap = {};
    this.dataSource = this.manifestGroups.map(g => ({ manifestGroup: g }));
    this.totals.manifest = this.router.calculateTotals(this.manifestGroups);
    this.totals.proposed = { totalVehicles: 0, combinedLoadedMinutes: 0, totalNumberOfTrips: 0 };
  }

  async loadEmployeeWorkingTime() {
    const date = asShortDate(this.selectedDate);
    const data: any[] = await this.dss
      .getApi<EmployeeWorkingTimeApi>(EmployeeWorkingTime)
      .findAllByDate(date, hAll)
      .toPromise();
    this.employeeWorkingTimeMap = data.reduce((p, v) => ({ ...p, [v.employeeId]: v }), {});
  }

  setEmployeesAvailable() {
    this.employeesAvailable = this.employees.filter(e => !this.selectedEmployeeIdsSet.has(e.id));
  }
  sortDataSourceHandler(e) {
    this.router.sortDataSource(this.dataSource, this.dataSourceOrder, this.employeeWorkingTimeMap);
  }

  async proposeHandler() {
    this.ui.showLoading();
    try {
      const selectedVehicleIdsSet = new Set(this.selectedVehicleIds);
      const keepManifestTrips = this.shouldShowKeepManifestTrips() && this.keepManifestTrips;

      const [tripsPool, vehicles, canceledTrips] = this.router.makeTripsPool(
        this.manifest.data,
        this.manifestGroups,
        keepManifestTrips,
        selectedVehicleIdsSet,
      );

      const proposedGroups = await this.router.proposeGroups(
        this.http,
        tripsPool,
        this.selectedVehicleIds.length,
        this.withPreferred,
        this.withAvoid,
        !this.useCache,
        vehicles,
        asShortDate(this.selectedDate),
        this.dss,
      );

      proposedGroups.sort((a, b) => b.workingMinutes - a.workingMinutes);
      let dataSrc = this.router.sortDataSource(
        [...this.manifestGroups].map((manifestGroup, i) => ({ manifestGroup })),
        'least',
        this.employeeWorkingTimeMap,
      );

      dataSrc = this.router.assignProposedGroups(
        dataSrc,
        proposedGroups,
        selectedVehicleIdsSet,
        this.selectedVehicleIds,
        this.manifestVehiclesMap,
        this.vehiclesMap,
        canceledTrips,
      );

      this.totals.proposed = {
        ...this.router.calculateTotals(dataSrc.map(t => t.proposedGroup).filter(t => t)),
      };
      this.router.sortDataSource(dataSrc, this.dataSourceOrder, this.employeeWorkingTimeMap);
      this.dataSource = dataSrc;
    } catch (error) {
      console.error(error);
      this.snackBar.open(error.message, 'Close', {
        duration: 3000,
      });
    }
    this.ui.hideLoading();
  }

  async calculateTravelHandler() {
    this.ui.showLoading();
    await this.router.calculateTravel(
      this.totals,
      asShortDate(this.selectedDate),
      this.dataSource,
      this.destinationsMap,
      this.dss,
      this.employeeWorkingTimeMap,
    );
    this.ui.hideLoading();
  }

  lockFirstTripsOrClearAllHandler() {
    if (this.lockSelectedTrips) this.clearSelectedTrips();
    else this.lockFirstTrips();
  }

  lockFirstTrips() {
    this.dataSource.forEach(({ manifestGroup }) => {
      if (manifestGroup && manifestGroup.trips && manifestGroup.vehicle) {
        const t = manifestGroup.trips[0];
        if (t) t.lock = true;
      }
    });
    this.lockSelectedTrips = true;
  }

  clearSelectedTrips() {
    this.dataSource.forEach(({ manifestGroup }) => {
      if (manifestGroup && manifestGroup.trips) {
        manifestGroup.trips.forEach(t => (t.lock = false));
      }
    });
    this.lockSelectedTrips = false;
  }

  lockChangeHandler() {
    this.lockSelectedTrips = this.dataSource.some(
      ({ manifestGroup }) => manifestGroup && manifestGroup.trips.some(t => t.lock),
    );
  }

  openRoutesMap(group) {
    if (!group || !group.trips) return;
    const title = `Veh #${group.vehicle.internalId} ${this.employeeHelper.displayExpr(group.employee)}`;
    const { routes, markers } = this.router.getRoutesAndMarkers(group.trips, this.destinationsMap);
    this.dialog.open<any, any, number>(DlgRoutesMapComponent, {
      hasBackdrop: true,
      data: {
        title: `Routes Map for ${title}`,
        markers: markers,
        routes: routes,
      },
    });
  }

  openWorkingHours() {
    this.dialog.open<any, any, number>(DlgEmployeeWorkingTimeComponent, {
      hasBackdrop: true,
      data: { selectedDate: asShortDate(this.selectedDate) },
    });
  }

  getFullAddress(cellInfo) {
    return this.router.getAddr(cellInfo.value, cellInfo.data.__consumer, this.destinationsMap);
  }

  getAddressCell(cellInfo) {
    const val = cellInfo.value;
    const addr = this.getFullAddress(cellInfo);
    const fn = this.getConsumerFullName(cellInfo.row.data);
    const noAddr = `No address provided for ${val === 'RESIDENCE' ? fn : val}`;
    const title = addr ? `Address: ${addr}` : noAddr;
    const style = !addr ? this.errorStyle : '';
    let html = `<span style="${style}" title="${title}">${val}</span>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getPickupCell(cellInfo, trips) {
    const puTime = moment(cellInfo.value, 'HH:mm:ss').format('hh:mm A');
    let html = `<span>${puTime}</span>`;
    try {
      if (cellInfo.rowIndex > 0) {
        const prev = trips[cellInfo.rowIndex - 1];
        const curr = trips[cellInfo.rowIndex];
        if (prev.dot > curr.t && !prev.x && !curr.x) {
          this.conflictTripsMap[curr.id] = true;
          const doTime = moment(prev.dot, 'HH:mm:ss').format('hh:mm A');
          const title = `Previous Dropoff Time (${doTime}) is after Current Pickup Time (${puTime})`;
          html = `<span style="${this.errorStyle}" title="${title}">${puTime}</span>`;
        }
      }
    } catch (error) {}

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getFreeTimeCell(nextFreeMinutes) {
    if (!nextFreeMinutes) return '';
    const style = nextFreeMinutes >= 60 ? this.breakStyle : nextFreeMinutes < 0 ? this.errorStyle : '';
    return this.sanitizer.bypassSecurityTrustHtml(`<span style="${style}">${this.getHours(nextFreeMinutes)}h</span>`);
  }

  grid_onCellPrepared(e) {
    if (e && e.data && e.data[this.helper.getRecFieldMap.cancelled]) {
      (e.cellElement as HTMLElement).style.textDecoration = 'line-through';
    }
  }

  // async exportGroups(groupName: string) {
  //   const workbook = new Workbook();

  //   for (const [index, dataGrid] of this.dataGrids.toArray().entries()) {
  //     const worksheet = workbook.addWorksheet(`Grid ${index + 1}`);

  //     await exportDataGrid({
  //       component: dataGrid.instance,
  //       worksheet: worksheet,
  //       autoFilterEnabled: true
  //     });
  //   }

  //   // const buffer = await workbook.xlsx.writeBuffer();
  //   // saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrids.xlsx');
  // }

  async overwriteAllManifestHandler() {
    this.dialog
      .open<any, any, number>(DlgOverwriteManifestConfirm, {
        hasBackdrop: true,
        data: { selectedDate: moment(this.selectedDate).format('dddd M/D/YYYY') },
      })
      .afterClosed()
      .subscribe(async (result: any) => {
        const today = this.isToday(this.selectedDate);
        if (result && (!today || confirm('Are you sure you want to overwrite the manifest for today?'))) {
          this.ui.showLoading();
          await this.overwriteAllManifest();
          if (result.overwriteSchedules) await this.overwriteSchedules();
          await this.router.logGmapOverwriteAllManifest(this.http, asShortDate(this.selectedDate));
          await this.saveEmployeeWorkingTime();
          await this.loadManigest();
          await this.loadEmployeeWorkingTime();
          this.router.sortDataSource(this.dataSource, this.dataSourceOrder, this.employeeWorkingTimeMap);
          this.ui.hideLoading();
        }
      });
  }

  async overwriteAllManifest() {
    const proposedTripsPool = this.dataSource.flatMap(({ proposedGroup }) =>
      ((proposedGroup && proposedGroup.trips) || []).map((t: any, i) => ({
        ...t,
        e: (proposedGroup.employee && proposedGroup.employee.id) || -1,
        esc: (proposedGroup.escort && proposedGroup.escort.id) || null,
        v: (proposedGroup.vehicle && proposedGroup.vehicle.id) || null,
        tr: i + 1,
      })),
    );
    await this.helper.api.updateManifest(this.manifest.id, proposedTripsPool).toPromise();
  }

  async saveEmployeeWorkingTime() {
    const date = asShortDate(this.selectedDate);
    const data = this.dataSource.flatMap(({ proposedGroup: pg }) => {
      if (!pg || !pg.load || !pg.trips) return [];
      const { workingMinutes } = pg;
      const arr = [];
      if (pg.employee && pg.employee.id > 0) arr.push({ status: 'DRIVER', employeeId: pg.employee.id, workingMinutes });
      if (pg.escort && pg.escort.id > 0) arr.push({ status: 'ESCORT', employeeId: pg.escort.id, workingMinutes });
      return arr;
    });
    await this.dss.getApi<EmployeeWorkingTimeApi>(EmployeeWorkingTime).saveManyByDate(date, data, hAll).toPromise();
  }

  async overwriteSchedules() {
    const date = asShortDate(this.selectedDate);
    await this.dss.getApi<DriverScheduleApi>(DriverSchedule).dropAll(date, true).toPromise();
    const data = this.dataSource.flatMap(({ proposedGroup: pg }) => {
      if (!pg || !pg.load || !pg.trips) return [];
      const { startTime, finishTime } = pg;

      const [startTimeCalculatedAt, finishTimeCalculatedAt] = [new Date(), new Date()];
      const facilityId = this.auth.getCurrentTenant();
      const { startLocationAddress, finishLocationAddress, startLocationCoords, finishLocationCoords } = pg;
      const { startLocationCalculated, finishLocationCalculated, startTravelDuration, finishTravelDuration } = pg;
      const { startTripTime, finishTripTime, startTripLocationAddress, finishTripLocationAddress } = pg;
      const { startTripLocation, startTripConsumerName, finishTripLocation, finishTripConsumerName } = pg;
      const data = {
        ...{ mode: 'AUTO', day: date, date: `${date}T16:00:00.000Z` },
        ...{ facilityId, startFacilityId: facilityId, finishFacilityId: facilityId },
        ...{ startTime, finishTime, startTimeCalculatedAt, finishTimeCalculatedAt },
        ...{ startInstructions: '', finishInstructions: '' },
        ...{ startLocationAddress, finishLocationAddress, startLocationCoords, finishLocationCoords },
        ...{ startLocationCalculated, finishLocationCalculated, startTravelDuration, finishTravelDuration },
        ...{ startTripTime, finishTripTime, startTripLocationAddress, finishTripLocationAddress },
        ...{ startTripLocation, startTripConsumerName, finishTripLocation, finishTripConsumerName },
      };
      const arr = [];
      if (pg.employee && pg.employee.id > 0) arr.push({ status: 'DRIVER', driverId: pg.employee.id, ...data });
      if (pg.escort && pg.escort.id > 0) arr.push({ status: 'ESCORT', driverId: pg.escort.id, ...data });
      return arr;
    });
    try {
      await this.dss.getApi<DriverScheduleApi>(DriverSchedule).createMany(data, hAll).toPromise();
    } catch (error) {
      console.log('Ignore it if you have manual schedules', error);
    }
  }
}
