import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import fromPairs from 'lodash-es/fromPairs';
import groupBy from 'lodash-es/groupBy';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import orderBy from 'lodash-es/orderBy';
import uniq from 'lodash-es/uniq';
import moment, { utc } from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
//
import {
  dxStoreLoadHooks,
  gqlMongoByKey,
  gqlMongoCount,
  gqlMongoDoc,
  gqlMongoLoad,
} from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { hAll, headersAllTenantsAppend, isAlpha } from '../../../../shared/classes/utils/utils';
import {
  ByClientsSignatureStats,
  ByClientsSignatureStatsApi,
  Consumer,
  ConsumerApi,
  ConsumerView,
  Facility,
  LoggerService,
  MyUserApi,
  MyUtilsApi,
} from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { getUser } from '../../../../store/reducers/sign';
import { PERSON_SEXES, REPETITIVE_MODES } from '../../../employee/classes/enums';
import { CONSUMER_STATUSES, CONSUMER_TRANSPORTATION_CODES } from '../../classes/enums';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-all-consumer-grid',
  templateUrl: './all-consumer-grid.component.html',
  styleUrls: ['./all-consumer-grid.component.scss'],
})
export class AllConsumerGridComponent extends ABaseComponent {
  sexes = PERSON_SEXES;
  statuses = CONSUMER_STATUSES;
  transpCodes = CONSUMER_TRANSPORTATION_CODES;
  transpCodesMap = fromPairs(CONSUMER_TRANSPORTATION_CODES.map(({ ID, Name }) => [ID, Name]));
  consumerStatuses = CONSUMER_STATUSES;
  repetitiveModes = REPETITIVE_MODES;

  weekdaysDso = [
    { ID: 0, Name: 'Monday' },
    { ID: 1, Name: 'Tuesday' },
    { ID: 2, Name: 'Wednesday' },
    { ID: 3, Name: 'Thursday' },
    { ID: 4, Name: 'Friday' },
    { ID: 5, Name: 'Saturday' },
    { ID: 6, Name: 'Sunday' },
  ];

  // instructions: string[] = CONSUMER_INSTRUCTIONS.map(i => i.Name);

  signatureHeaderFilter: any;

  private mciDsMap: {
    [mci: string]: {
      mtmBrokerDS?: any;
      ctsBrokerDS?: any;
      hhaDS?: any;
    };
  } = {};

  private consDsMap: {
    [id: string]: {
      manifestsDS?: any;
      signStatsDS?: any;
      fullInstance?: any;
      eligibility?: any;
      mealsAuths?: any;
    };
  } = {};

  dso$: Observable<DataSourceOptions> = of([]);
  facilityDso: DataSourceOptions = [];
  grid_stateStoring: any;

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    public common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private utilsApi: MyUtilsApi,
    private consumerApi: ConsumerApi,
    private signStatsApi: ByClientsSignatureStatsApi,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    private userApi: MyUserApi,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '37b7a4be-f1bb-4c8b-a653-1715b38aab78',
    };

    this.signatureHeaderFilter = [
      {
        text: 'No Signature',
        value: ['signImgFileId', '=', null],
      },
      {
        text: 'With Signature',
        value: ['signImgFileId', '<>', null],
      },
    ];

    this.dso$ = this.buildDataSource();
    this.facilityDso = this.buildFacilityDataSource();
  }

  // calcCellDateValue(rowData: any): Date {
  //   const column = this as any;
  //   const momentDate = moment.utc(rowData[column.dataField]);
  //   return momentDate.isValid() ? momentDate.utc().toDate() : rowData[column.dataField];
  // }

  // calcDisplayDateValue(rowData: any): string {
  //   const column = this as any;
  //   const momentDate = moment.utc(rowData[column.dataField]);
  //   return momentDate.isValid() ? momentDate.utc().format('YYYY-MM-DD') : rowData[column.dataField];
  // }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  detailGrid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items
      .unshift
      // {
      //   name: 'showFromBroker',
      //   locateInMenu: 'auto',
      //   widget: 'dxSwitch',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'always',
      //   options: {
      //     width: '100%',
      //     switchedOnText: 'Broker Exports Mode',
      //     switchedOffText: 'Regular Mode',
      //     hint: 'Switch only exported or regular mode',
      //     value: this.$showFromBroker$.value,
      //     onValueChanged: this.grid_toolbar_modeSwitch_onChange.bind(this),
      //   }
      // },
      ();
  }

  grid_onCellPrepared(e) {
    // console.log(e);

    if (e.rowType === 'data') {
      if (oc(e).data.status('') === 'INACTIVE') {
        (e.cellElement as HTMLElement).style.color = '#aaa';
      }

      if (oc(e).data.lastEligibility('').toString().toUpperCase() === 'DECEASED') {
        (e.cellElement as HTMLElement).style.textDecoration = 'line-through';
        (e.cellElement as HTMLElement).style.color = '#aaa';

        if (e.column.dataField === 'status' && oc(e).data.status('') === 'ACTIVE') {
          (e.cellElement as HTMLElement).style.color = 'orange';
        }
      }
    }
  }

  mtmBrokerTripsDS(mci: string) {
    const self = this;

    if (!self.mciDsMap[mci]) {
      self.mciDsMap[mci] = {};
    }

    if (!self.mciDsMap[mci].mtmBrokerDS) {
      const store = new CustomStore({
        useDefaultSearch: true,
        cacheRawData: true,
        load: async (loadOptions: LoadOptions): Promise<any> => {
          const col = 'ExportsDataCache';
          const aggregate = [
            // {
            //   $addFields: {_ctime: {$toDate: '$_id'}},
            // },
            {
              $sort: { _date: -1 },
            },
          ];
          return (
            gqlMongoLoad(self.dss, col, loadOptions, aggregate)
              // .pipe(
              // switchMap(async (docs: any[]) => {
              //   await Promise.all(
              //     docs
              //       .map(async (doc) => {}),
              //   );
              //   return docs;
              // }),
              // )
              .toPromise()
          );
        },
        byKey: async (key: any | string | number): Promise<any> => {
          const col = 'ExportsDataCache';
          return gqlMongoByKey(self.dss, col, key).toPromise();
        },
        totalCount: async (loadOptions: LoadOptions): Promise<number> => {
          const col = 'ExportsDataCache';
          return gqlMongoCount(self.dss, col, loadOptions).toPromise();
        },
      });

      const dso: DataSourceOptions = {
        store,
        sort: [{ selector: '_date', desc: true }],
        filter: [
          ['_broker', '=', 'MTM'],
          ['_mci', '=', mci],
          ['_mci', '<>', ''],
          ['_mci', '<>', null],
        ],
        // postProcess: (data: Array<any>): Array<any> => {
        //   return data;
        // },
      };

      self.mciDsMap[mci].mtmBrokerDS = dso;
    }

    return self.mciDsMap[mci].mtmBrokerDS;
  }

  ctsBrokerTripsDS(mci: string) {
    const self = this;

    if (!self.mciDsMap[mci]) {
      self.mciDsMap[mci] = {};
    }

    if (!self.mciDsMap[mci].ctsBrokerDS) {
      const store = new CustomStore({
        useDefaultSearch: true,
        cacheRawData: true,
        load: async (loadOptions: LoadOptions): Promise<any> => {
          const col = 'ExportsDataCache';
          const aggregate = [
            // {
            //   $addFields: {_ctime: {$toDate: '$_id'}},
            // },
            {
              $sort: { _date: -1 },
            },
          ];
          return (
            gqlMongoLoad(self.dss, col, loadOptions, aggregate)
              // .pipe(
              // switchMap(async (docs: any[]) => {
              //   await Promise.all(
              //     docs
              //       .map(async (doc) => {}),
              //   );
              //   return docs;
              // }),
              // )
              .toPromise()
          );
        },
        byKey: async (key: any | string | number): Promise<any> => {
          const col = 'ExportsDataCache';
          return gqlMongoByKey(self.dss, col, key).toPromise();
        },
        totalCount: async (loadOptions: LoadOptions): Promise<number> => {
          const col = 'ExportsDataCache';
          return gqlMongoCount(self.dss, col, loadOptions).toPromise();
        },
      });

      const dso: DataSourceOptions = {
        store,
        sort: [{ selector: '_date', desc: true }],
        filter: [
          ['_broker', '=', 'CTS'],
          ['_mci', '=', mci],
          ['_mci', '<>', ''],
          ['_mci', '<>', null],
        ],
        // postProcess: (data: Array<any>): Array<any> => {
        //   return data;
        // },
      };

      self.mciDsMap[mci].ctsBrokerDS = dso;
    }

    return self.mciDsMap[mci].ctsBrokerDS;
  }

  consumerManifests(id) {
    const self = this;

    if (!self.consDsMap[id]) {
      self.consDsMap[id] = {};
    }

    if (!self.consDsMap[id].manifestsDS) {
      const dso$ = self.consumerApi.getManifests(id, 0, 0, '', hAll);

      self.consDsMap[id].manifestsDS = dso$;
    }

    return self.consDsMap[id].manifestsDS;
  }

  consumerSignatureStats(id) {
    const self = this;

    if (!self.consDsMap[id]) {
      self.consDsMap[id] = {};
    }

    if (!self.consDsMap[id].signStatsDS) {
      const so = this.dss.getStoreOptions(ByClientsSignatureStats, undefined, false);
      so.customHeaders = headersAllTenantsAppend;
      so.customFilter = {
        where: { consumerId: id },
        order: ['vdate DESC'],
      };

      const store: CustomStore = new CustomStore(so);
      const dso: DataSourceOptions = { store };

      dxStoreLoadHooks(store, undefined, async ([obj], [recs]: [any[]]) => {
        // await loadDetailsAsync(manifest.id, recs);

        if (isArray(recs) && recs.length) {
          const dates = uniq(recs.map(rec => rec.vdate)).map(d => utc(d).utc().toDate());

          const client = await self.dss
            .getApi<ConsumerApi>(Consumer)
            .findById<Consumer>(id, { fields: { id: true, mci: true } }, hAll)
            .toPromise();

          const lgtcClient = await gqlMongoDoc(self.dss, 'LGTC_ExportsConsumersCache', {
            _consumerIds: { $in: [id] },
          }).toPromise();

          //

          const trips = await gqlMongoLoad(
            self.dss,
            'ExportsDataCache',
            {
              filter: [
                ['_mci', '=', client.mci],
                ['_mci', '<>', ''],
                ['_mci', '<>', null],
                [['_broker', '=', 'CTS'], 'or', ['Trip Status', 'startswith', 'S']],
                [
                  '_date',
                  '$in',
                  isArray(dates) ? dates.map(d => ({ $date: { v: utc(d).toDate().toISOString() } })) : [],
                ],
              ],
              select: { _mci: true, _date: true, _tripId: true, 'Trip Status': true },
            },
            // , [{
            //   $addFields: {_dateStr: {$toString: '$_date'}},
            // }]
          ).toPromise();

          const trips2 = await gqlMongoLoad(self.dss, 'LGTC_ExportsDataCache', {
            filter: [
              ['id', '=', oc(lgtcClient)._clientId()],
              ['_date', '$in', isArray(dates) ? dates.map(d => ({ $date: { v: utc(d).toDate().toISOString() } })) : []],
            ],
            select: { _date: true, _tripId: true },
          }).toPromise();

          recs.forEach(
            r =>
              (r._trips = [...trips, ...trips2]
                .filter(t => utc(t._date).utc().format('YYYY-MM-DD') === utc(r.vdate).utc().format('YYYY-MM-DD'))
                .map(t => t._tripId)
                .join(', ')),
          );
        }

        return [recs];
      });

      self.consDsMap[id].signStatsDS = of(dso);
    }

    return self.consDsMap[id].signStatsDS;
  }

  consumerEligibility(id) {
    const self = this;

    if (!self.consDsMap[id]) {
      self.consDsMap[id] = {};
    }

    if (!self.consDsMap[id].eligibility) {
      const MCO_BROKER_MAP = {
        UPMC: 'CTS',
        PHW: 'MTM',
        PAHW: 'MTM',
        KEYSTONE: 'MTM',
        AMERIHEALTH: 'MTM',
      };

      const inst$ = self.consumerApi.findById(id, {}, hAll);

      self.consDsMap[id].eligibility = inst$.pipe(
        map((c: Consumer) =>
          orderBy(
            Object.entries(c.eligibility || {}).map(([k, v]: [string, any]) => ({
              _month: k,
              _monthName: moment(k, 'YYYY-MM').format('MMMM'),
              _broker: MCO_BROKER_MAP[oc(v).mco('')] || '',
              ...v,
            })),
            ['_month'],
            ['desc'],
          ),
        ),
      );
    }

    return self.consDsMap[id].eligibility;
  }

  consumerMealsAuths(c: Consumer) {
    const self = this;

    if (!self.consDsMap[c.id]) {
      self.consDsMap[c.id] = {};
    }

    if (!self.consDsMap[c.id].mealsAuths) {
      const mealsAuths$ = self.consumerApi.getMealsAuths(c.id, moment().format('YYYY-MM-DD'), hAll);
      const mealsEstimated$ = self.consumerApi.getMealsEstimated(c.id, moment().format('YYYY-MM-DD'), hAll);
      const chcE9ies$ = self.consumerApi.getChcEligibility(c.id, moment().format('YYYY-MM-DD'), hAll);

      const brokerClients$ = of(true).pipe(
        switchMap(() =>
          !isEmpty(c.mci)
            ? gqlMongoLoad(this.dss, 'ExportsConsumersCache', {
                filter: [['_mci', '=', c.mci]],
              })
            : of([]),
        ),
      );

      self.consDsMap[c.id].mealsAuths = combineLatest([mealsAuths$, mealsEstimated$, brokerClients$, chcE9ies$]).pipe(
        map(([mealsAuths, mealsEstimated, brokerClients, chcE9ies]) => ({
          auths: Object.entries(groupBy(mealsAuths, 'Code')).map(([code, auths]) => ({
            ...auths[0],
            _estimated: mealsEstimated[code],
          })),
          authClient: brokerClients.find(bc => (bc._broker as string).includes('.AUTH')),
          chcEligibility: oc(chcE9ies)[0].response(),
        })),
      );
    }

    return self.consDsMap[c.id].mealsAuths;
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(Consumer, ConsumerView, false);
    (so as LoopBackStoreOptions).customHeaders = headersAllTenantsAppend;
    (so as LoopBackStoreOptions).customFilter = {
      // TODO: remove hardcode
      ...(isAlpha() ? {} : { where: { tenantId: { nin: [25, 29] } } }), // exclude HomeCare, +1
    };

    const store: CustomStore = new CustomStore(so);
    const dso: DataSourceOptions = {
      store,
    };
    return of(dso);
  }

  private buildFacilityDataSource() {
    const store = this.dss.getStore(Facility);
    const dso: DataSourceOptions = {
      store,
      ...(isAlpha() ? {} : { filter: ['type', 'inq', ['ADC', 'BASE', 'MEALS']] }),
      sort: [{ selector: 'type' }, { selector: 'shortname' }],
    };
    return dso;
  }
}
