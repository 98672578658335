import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import moment from 'moment';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { AnyNote, Facility, LoggerService, MyUser } from 'src/app/shared/sdk';
import { FormHelperService } from '../../../services/form-helper.service';
import { ABaseFormComponent } from '../../abstract/a-base-form.component';

@Component({
  selector: 'app-any-note-form',
  templateUrl: './any-note-form.component.html',
  styleUrls: ['./any-note-form.component.scss'],
})
export class AnyNoteFormComponent extends ABaseFormComponent<AnyNote> implements OnInit {
  @Input()
  set fields(fs: any) {
    this.form &&
      Object.keys(fs).forEach(k => {
        const ctrl = this.form.get(k);
        if (ctrl) ctrl.reset(fs[k]);
        else this.form.addControl(k, new FormControl(fs[k]));
      });
  }
  @Input() templateFormTop?: TemplateRef<any>;
  @Input() templateFormBottom?: TemplateRef<any>;
  @Input() dso: DataSourceOptions;

  facilityDso: DataSourceOptions = [];
  notificationGroupDso: DataSourceOptions = [];

  constructor(
    protected logger: LoggerService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    protected helper: FormHelperService<AnyNote>,
  ) {
    super(logger, fb, dss, helper);
  }

  ngOnInit() {
    super.ngOnInit();

    this.facilityDso = this.buildFacilityDSO();
    this.notificationGroupDso = this.buildNotificationGroupDSO();
  }

  displayExpr(note: AnyNote) {
    return (
      note && `${moment(note.updatedAt).format('M/D/YYYY, h:mm A')} | ${note.createdBy.username} | ${note.subject}`
    );
  }

  protected get ModelClass(): any {
    return AnyNote;
  }

  protected get dateFields(): string[] {
    return [];
  }

  protected buildForm(): void {
    this.formConfigMap.set('', {
      id: [],
      relatedNoteId: [],
      notificationGroupUserIds: [],
      subject: [],
      description: [],
    });

    this.form = this.fb.group(this.formConfigMap.get(''));
  }

  private buildFacilityDSO() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = { order: 'shortname' };
    const store = new CustomStore(so);
    return { store } as DataSourceOptions;
  }

  private buildNotificationGroupDSO() {
    const so = this.dss.getStoreOptions(MyUser, undefined, false);
    // so.customHeaders = hAll;
    return {
      store: new CustomStore(so),
      sort: [{ selector: 'username' }],
    } as DataSourceOptions;
  }
}
