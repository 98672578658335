import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Client, TokenProvider } from '@pusher/push-notifications-web';
// import { captureException } from '@sentry/browser';
import notify from 'devextreme/ui/notify';
import isEmpty from 'lodash-es/isEmpty';
import isFunction from 'lodash-es/isFunction';
import Pusher from 'pusher-js';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { environment } from '../../../../../environments/environment';
import { getEnvVars } from '../../../../store/reducers/core';
import { MyJobApi, MyUserApi, MyUtils, MyUtilsApi } from '../../../sdk';
import { ExtLoopBackAuth } from '../../ext-sdk/services/ext-sdk-auth.service';
import { CommonService } from './common.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../sdk/services/core/auth.service";
import * as i2 from "@ngrx/store";
import * as i3 from "./common.service";
import * as i4 from "../../../sdk/services/custom/MyUser";
import * as i5 from "../../../sdk/services/custom/MyJob";
import * as i6 from "../../../sdk/services/custom/MyUtils";
export class PusherService {
    constructor(auth, store, common, userApi, jobApi, utilsApi) {
        this.auth = auth;
        this.store = store;
        this.common = common;
        this.userApi = userApi;
        this.jobApi = jobApi;
        this.utilsApi = utilsApi;
        this.$pusher$ = new BehaviorSubject(undefined);
        this.$beam$ = new BehaviorSubject(undefined);
        this.$onDestroy$ = new Subject();
        this.store
            .pipe(select(getEnvVars), 
        // distinct(),
        map(vars => this.setup(vars)), catchError(err => {
            // captureException(err);
            console.error(err);
            return of(null);
        }), takeUntil(this.$onDestroy$))
            .subscribe();
    }
    get userChannel() {
        if (this.userApi.isAuthenticated()) {
            return 'presence-user@' + this.userApi.getCurrentId();
        }
        else {
            console.warn('User is not authenticated');
            // throw new Error('User is not authenticated');
        }
    }
    get tenantChannel() {
        if (this.userApi.isAuthenticated()) {
            return 'private-tenant@' + this.auth.getCurrentTenant();
        }
        else {
            console.warn('User is not authenticated');
            // throw new Error('User is not authenticated');
        }
    }
    get pusher() {
        return this.$pusher$.value;
    }
    get beam() {
        return this.$beam$.value;
    }
    ngOnDestroy() {
        this.$onDestroy$.next();
        void this.dispose();
    }
    rpc(job, args, heavy = true, customHeaders = {}) {
        return this.jobApi
            .job(job, {
            heavy,
            args,
        }, (headers) => {
            if (isFunction(customHeaders)) {
                headers = customHeaders(headers);
            }
            else {
                Object.entries(customHeaders).forEach(([k, v]) => (headers = headers.append(k, v)));
            }
            return headers;
        })
            .pipe(tap(console.log), switchMap(jobId => this.requestResponse(jobId)));
    }
    requestResponse(msgId) {
        return new Observable(observer => {
            try {
                if (!this.pusher) {
                    return observer.error(new Error('Pusher undefined'));
                }
                this.pusher.user.bind(`response:${msgId}`, ({ id, data, error }) => {
                    console.log(`response:${msgId}`, id, data, error);
                    this.pusher.user.unbind(`response:${msgId}`);
                    if (error) {
                        return observer.error(new Error(error));
                    }
                    observer.next(data);
                    observer.complete();
                });
            }
            catch (err) {
                observer.error(err);
            }
        });
    }
    requestResponseOld(msgId) {
        return new Observable(observer => {
            try {
                if (!this.pusher) {
                    return observer.error(new Error('Pusher undefined'));
                }
                const channel = this.pusher.channel(this.userChannel);
                channel.bind(`response:${msgId}`, ({ id, data, error }) => {
                    console.log(`response:${msgId}`, id, data, error);
                    channel.unbind(`response:${msgId}`);
                    if (error) {
                        return observer.error(new Error(error));
                    }
                    observer.next(data);
                    observer.complete();
                });
            }
            catch (err) {
                observer.error(err);
            }
        });
    }
    setup(vars) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.dispose();
            if (vars && !isEmpty(vars)) {
                if (!isEmpty(vars.PUSHER_APP_KEY)) {
                    Pusher.logToConsole = true; // !environment.production;
                    const pusher = new Pusher(vars.PUSHER_APP_KEY, {
                        cluster: vars.PUSHER_APP_CLUSTER,
                        forceTLS: true,
                        // enabledTransports: ['ws', 'wss', 'xhr_streaming', 'xhr_polling'],
                        // disabledTransports: ['ws', 'xhr_streaming'],
                        // encrypted: true
                        enableStats: false,
                        userAuthentication: {
                            transport: 'ajax',
                            endpoint: this.common.buildUrlPath(MyUtils) + '/pusherUserAuth',
                            headers: { 'X-Access-Token': this.auth.getAccessTokenId() },
                        },
                        channelAuthorization: {
                            transport: 'ajax',
                            endpoint: this.common.buildUrlPath(MyUtils) + '/pusherChannelAuth',
                            headers: { 'X-Access-Token': this.auth.getAccessTokenId() },
                        },
                    });
                    const _errHandler = err => {
                        try {
                            if (oc(err).error.data.code() || oc(err).data.code()) {
                                throw new Error([
                                    oc(err).error.type() || oc(err).type(),
                                    oc(err).error.data.code() || oc(err).data.code(),
                                    oc(err).error.data.message() || oc(err).data.message(),
                                ].join(': '));
                            }
                            else {
                                throw err;
                            }
                        }
                        catch (e) {
                            console.error('Pusher error', e);
                            // captureException(e, {
                            //   extra: { error: err },
                            //   tags: { service: 'Pusher' },
                            // });
                        }
                        // if (oc(err).error.data.code() === 4004) {
                        //   notify('Pusher: Over limit!', 'error', 5000);
                        // }
                    };
                    pusher.connection.bind('error', _errHandler);
                    pusher.bind('pusher:error', _errHandler);
                    pusher.bind('pusher:signin_success', console.log);
                    pusher.user.bind_global(console.log);
                    pusher.user.bind('notify', ({ message, type, duration }) => notify(message, type, duration));
                    if (this.userChannel) {
                        // const userChannel = pusher.subscribe(this.userChannel);
                        // userChannel.bind('pusher:subscription_error', captureException);
                        // userChannel.bind('pusher:subscription_succeeded', console.log);
                        // userChannel.bind('notify', ({ message, type, duration }) => notify(message, type, duration));
                    }
                    if (this.tenantChannel) {
                        // const tenantChannel = pusher.subscribe(this.tenantChannel);
                        // tenantChannel.bind('pusher:subscription_error', captureException);
                        // tenantChannel.bind('pusher:subscription_succeeded', console.log);
                        // tenantChannel.bind('notify', ({message, type, duration}) => notify(message, type, duration));
                    }
                    const channelShared = pusher.subscribe('shared');
                    channelShared.bind('pusher:subscription_error', console.error);
                    // channelShared.bind('pusher:subscription_error', captureException);
                    channelShared.bind('pusher:subscription_succeeded', console.log);
                    // channelShared.bind('configChanged', () => this.store.dispatch(new LoadConfig()));
                    // channelShared.bind('envChanged', () => this.store.dispatch(new LoadEnvVars()));
                    pusher.signin();
                    this.$pusher$.next(pusher);
                }
                if (environment.production && !isEmpty(vars.PUSHER_BEAM_INSTANCE_ID)) {
                    const beamsClient = new Client({
                        instanceId: vars.PUSHER_BEAM_INSTANCE_ID,
                    });
                    const tokenProvider = new TokenProvider({
                        url: this.common.buildUrlPath(MyUtils) + '/beamAuth',
                        // queryParams: {someQueryParam: 'parameter-content'},
                        headers: { 'X-Access-Token': this.auth.getAccessTokenId() },
                    });
                    yield beamsClient
                        .start()
                        .then(() => beamsClient.getDeviceId())
                        .then(deviceId => console.log('Successfully registered with Beams. Device ID:', deviceId))
                        .then(() => beamsClient.setUserId('' + this.auth.getCurrentUserId(), tokenProvider))
                        .then(() => beamsClient.getUserId())
                        .then(userId => console.log('Successfully set User. User ID:', userId))
                        .then(() => beamsClient.addDeviceInterest('broadcast'))
                        // .then(() => beamsClient.getDeviceInterests())
                        // .then((interests) => console.log('Current interests:', interests))
                        // .catch(captureException);
                        .catch(console.error);
                    this.$beam$.next(beamsClient);
                }
            }
            else {
                this.$pusher$.next(null);
                this.$beam$.next(null);
            }
        });
    }
    dispose() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.pusher) {
                // this.pusher.allChannels().forEach((c) => {
                //   c.unbind();
                //   this.pusher.unsubscribe(c.name);
                // });
                // PusherStatic.log(pusher.connection.state);
                this.pusher.allChannels().forEach(c => c.unbind_all());
                this.pusher.user.unbind_all();
                this.pusher.unbind_all().disconnect();
            }
            if (this.beam) {
                yield this.beam.stop();
            }
        });
    }
}
PusherService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PusherService_Factory() { return new PusherService(i0.ɵɵinject(i1.LoopBackAuth), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.CommonService), i0.ɵɵinject(i4.MyUserApi), i0.ɵɵinject(i5.MyJobApi), i0.ɵɵinject(i6.MyUtilsApi)); }, token: PusherService, providedIn: "root" });
