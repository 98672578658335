import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import { hAll } from 'src/app/shared/classes/utils/utils';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';
import { dxStoreLoadHooks, gqlMongoByKey, gqlMongoCount, gqlMongoLoad, } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Consumer, EmployeeView, Facility, LoggerService, MyUser, } from '../../../../shared/sdk';
import { DlgManifestGridComponent } from '../dlg-manifest-grid/dlg-manifest-grid.component';
export class CloneLogGridComponent extends ABaseComponent {
    constructor(logger, config, common, dialog, dss, gridHelper, ui) {
        super(logger);
        this.logger = logger;
        this.config = config;
        this.common = common;
        this.dialog = dialog;
        this.dss = dss;
        this.gridHelper = gridHelper;
        this.ui = ui;
        this.mySelected = new EventEmitter();
        this.tenantsMap = null;
        this.mode_calculateValue = (row) => (row.isManual ? 'Manual' : 'Auto');
        this.cloningContent_calculateFilterExpression = (filterValue, selectedFilterOperation) => {
            if (filterValue === 'Manifest')
                return [['createdManifest', '<>', null], 'and', ['createdSchedules', '=', null]];
            if (filterValue === 'Schedules')
                return [['createdSchedules', '<>', null], 'and', ['createdManifest', '=', null]];
            if (filterValue === 'Mixt')
                return [['createdSchedules', '<>', null], 'and', ['createdManifest', '<>', null]];
            return [];
        };
        // this.grid_stateStoring = {
        //   enabled: false,
        //   type: 'localStorage',
        //   storageKey: '7776d037-26fa-4e37-ab41-14076ee8b4s1',
        // };
        this.dso = this.buildDataSource();
    }
    ngOnInit() {
        this.modifiedSubscription = this.dss.modifiedEvent.subscribe(modelName => {
            // if ([AutoDispatchLog.getModelName()].includes(modelName)) {
            //   if (this.grid) {
            //     this.grid.instance.refresh();
            //   }
            // }
        });
    }
    ngOnDestroy() {
        this.modifiedSubscription.unsubscribe();
    }
    repaint() {
        this.grid && this.grid.instance && this.grid.instance.repaint();
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            notifyErrors: true,
        });
        e.component.deselectAll();
        this.mySelected.emit([]);
    }
    grid_onSelectionChanged(event) {
        const items = event.component.getDataSource().items();
        if (items.find(i => event.currentSelectedRowKeys.some(k => k._id === i._id))) {
            this.mySelected.emit(event.selectedRowsData);
        }
    }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items.push(Object.assign({ name: 'refresh', locateInMenu: 'auto', location: 'after', widget: 'dxButton', showText: 'inMenu' }, { options: { icon: 'refresh', text: 'Refresh', hint: 'Refresh', onClick: () => e.component.refresh() } }));
    }
    ngAfterViewInit() { }
    manifest_onClick(manifest, tenant) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ui.showLoading();
            const [consumers, drivers] = yield Promise.all([
                this.dss
                    .getApi(Consumer)
                    .find({
                    where: { id: { inq: [...new Set(manifest.data.map(({ c }) => c))] } },
                    include: [{ person: { contact: ['addresses', 'phones'] } }],
                }, hAll)
                    .toPromise(),
                this.dss
                    .getApi(EmployeeView)
                    .find({ where: { employeeId: { inq: [...new Set(manifest.data.map(({ e }) => e))] } } }, hAll)
                    .toPromise(),
            ]);
            this.ui.hideLoading();
            const driversMap = drivers.reduce((p, d) => (Object.assign({}, p, { [d.id]: d })), {});
            const consumersMap = consumers.reduce((p, c) => (Object.assign({}, p, { [c.id]: c })), {});
            const d = moment(manifest.date).format('M/D/YYYY');
            const title = `${tenant} Manifest for ${d}`;
            void this.dialog.open(DlgManifestGridComponent, {
                hasBackdrop: true,
                data: { title, manifest, consumersMap, driversMap },
                minWidth: 1200,
            });
        });
    }
    buildDataSource() {
        const self = this;
        const col = 'ManifestScheduleCloneLog';
        const aggregate = [{ $sort: { createdAt: -1 } }];
        const store = new CustomStore({
            useDefaultSearch: true,
            cacheRawData: false,
            load: (loadOptions) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
            }),
            byKey: (key) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return gqlMongoByKey(self.dss, col, key).toPromise();
            }),
            totalCount: (loadOptions) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
            }),
        });
        dxStoreLoadHooks(store, undefined, (args, [rows]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.tenantsMap) {
                const tenants = yield this.dss.getApi(Facility).find().toPromise();
                this.tenantsMap = tenants.reduce((p, t) => (Object.assign({}, p, { [t.id]: t.shortname })), {});
            }
            const users = yield this.dss
                .getApi(MyUser)
                .find({
                where: { id: { inq: rows.map(r => r.userId) } },
                include: [{ employee: ['person'] }],
            })
                .toPromise();
            const usersMap = users.reduce((p, u) => (Object.assign({}, p, { [u.id]: new FullNamePipe(this.config).transform(u.employee) })), {});
            return [
                rows.map(r => (Object.assign({}, r, { user: usersMap[r.userId], tenant: r.createdManifest && this.tenantsMap[r.createdManifest.tenantId] }))),
            ];
        }));
        return { store };
    }
}
