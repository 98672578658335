import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import { UploadHelperService } from 'src/app/shared/modules/ui/services/upload-helper.service';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { Config, ConfigApi, EmployeePosition, LoggerService, MyUtils, MyUtilsApi } from '../../../../shared/sdk';
import { LoadConfig } from '../../../../store/actions/core';
import { PRINT_MODE, SERVICE_TYPE } from '../../../trip-manifest/classes/enums';

@Component({
  selector: 'app-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss'],
})
export class SettingsFormComponent extends ABaseComponent implements AfterViewInit {
  @ViewChild('uploadCompanyLogoSmall', /* TODO: check static flag */ { static: false })
  uploadCompanyLogoSmall: DxFileUploaderComponent;
  @ViewChild('uploadCompanyLogoLarge', /* TODO: check static flag */ { static: false })
  uploadCompanyLogoLarge: DxFileUploaderComponent;

  processing = false;
  form: FormGroup;

  private formConfigMap: Map<string, any> = new Map<string, any>();
  private defaultData: any;

  driverList$: Promise<string[]>;
  timezoneList$: Observable<string[]>;
  availableReports: any;

  adcMealsCheckReadOnly = false;

  adcMealsReportingModeDs = [
    { v: 'cacfp_monthly', t: 'Monthly CACFP Meals Counts' },
    { v: 'cacfp_monthly_v2', t: 'Monthly CACFP Meals Counts v2' },
    { v: 'cacfp_monthly_v3', t: 'Monthly CACFP Meals Counts v3' },
    { v: 'cacfp_w_total', t: 'CACFP POS & Meals Count' },
    { v: 'cacfp_daily_v2', t: 'Daily CACFP - V/X/W' },
    { v: 'cacfp_daily_v3', t: 'Daily CACFP - 1/2/3' },
  ];

  tripCancelReasonsDs = [
    { v: 'XN1', t: 'Cancelled (prior to en-route)' },
    { v: 'XB1', t: 'Cancelled en-route' },
    { v: 'XB2', t: 'Cancelled to driver' },
    { v: 'XB3', t: 'Client not at place of pickup' },
    { v: 'XB4', t: 'Client not ready for pickup' },
    { v: 'XN6', t: 'Skipped by driver' },
  ];

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
    protected uploadHelper: UploadHelperService,
  ) {
    super(logger);

    this.driverList$ = this.dss.getDataSource(EmployeePosition).load();
    this.timezoneList$ = this.dss.getApi<MyUtilsApi>(MyUtils).getTimezoneList();

    this.availableReports = new ArrayStore({
      data: this.getAvailableReports(),
      key: 'ID',
    });

    this.buildForm();
  }

  ngAfterViewInit() {
    // this.initCropper();
    this.uploadHelper.handle(this.uploadCompanyLogoSmall.instance, {
      folder: 'pictures',
      onUploaded: async e => {
        const [file] = this.uploadCompanyLogoSmall.value;
        if (file.uploaded) this.form.get('companyLogoSmall').setValue(file.uploaded);
      },
    });
    this.uploadHelper.handle(this.uploadCompanyLogoLarge.instance, {
      folder: 'pictures',
      onUploaded: async e => {
        const [file] = this.uploadCompanyLogoLarge.value;
        if (file.uploaded) this.form.get('companyLogoLarge').setValue(file.uploaded);
      },
    });
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.form.pristine;
  }

  adcMealsCheck_initialized(e) {
    setTimeout(() => {
      const ctrl1 = this.form.get('allowAdcSignatures');
      const ctrl2 = this.form.get('adcMealsCheck');

      if (ctrl1.value === false) {
        // this.adcMealsCheckReadOnly = true;
      } else {
        // this.adcMealsCheckReadOnly = false;
      }
    }, 0);
  }

  allowAdcSignatures_changed(e) {
    const ctrl = this.form.get('adcMealsCheck');
    if (e.value === false) {
      ctrl.setValue(false);
      // this.adcMealsCheckReadOnly = true;
    } else {
      // this.adcMealsCheckReadOnly = false;
    }
  }

  adcTimeCompliance_onValueChanged(e) {
    if (e.value === false) {
      this.form.get('adcSignatureExtendedMode').setValue(false);
      this.form.get('showAdcTimeInFacilityPrompt').setValue(false);
      this.form.get('enableTimeInFacilityScreen').setValue(false);
      this.form.get('shiftRemainingTimeReminder').setValue(4);
    }
  }

  form_ngSubmit(e): boolean {
    e.preventDefault();

    void (async () => {
      this.processing = true;

      if (this.form.valid) {
        const data = this.form.value;
        await this.dss.getApi<ConfigApi>(Config).saveConfigData(data).toPromise();
        this.common.store.dispatch(new LoadConfig());
        this.form.markAsPristine();
        notify('Done!', 'success');
      } else {
        notify('There are not valid fields', 'warning', 5000);
      }
    })()
      .catch(err => notify(err.message, 'error', 5000))
      .finally(() => (this.processing = false));

    return false;
  }

  reset_onClick(e): void {
    setTimeout(() => {
      if (this.config.config) {
        this.form.reset(this.config.config);
        this.form.markAsPristine();
      } else {
        this.form.reset(this.defaultData);
      }
    });
  }

  private buildForm(): void {
    this.formConfigMap.set('', {
      timezone: [],
      fullNameFormat: ['$F $L', Validators.required],
      reportFullNameFormat: ['$L, $F', Validators.required],
      // dateFormat: ['YYYY-MM-DD', Validators.required],
      itemsPerPage: [20, Validators.required],
      reportList: [[]],
      tripAutoSequence: [false],
      driverList: [[]],
      consumerMoreFields: [[]],
      allowEditClientOnTab: [false],
      allowAdcSignatures: [false],

      allowCollectSignsNotByDriver: [true],
      allowEditUnvalidatedSignsOnDApp: [true],
      allowEditAutoValidatedSignsOnDApp: [false],
      allowAmbuletteTrips: [false],

      adcTimeCompliance: [false],
      adcSignatureExtendedMode: [false],
      showAdcTimeInFacilityPrompt: [false],
      enableTimeInFacilityScreen: [false],
      shiftRemainingTimeReminder: [4],

      showInactiveClients: [true],
      enableMobileDispatch: [false],
      adcManifestAutoClone: [false],

      adcMealsShifts: [{}],
      adcMealsShift1Times: [[]],
      adcMealsShift2Times: [[]],
      adcMealsCheck: [false],
      adcMealsDefaultBreakfastCheck: [false],
      adcMealsDefaultSnackCheck: [false],
      adcMealsDefaultLunchCheck: [false],
      adcMealsDefaultBreakfastPerDayCheck: [{}],
      adcMealsDefaultSnackPerDayCheck: [{}],
      adcMealsDefaultLunchPerDayCheck: [{}],

      meals_Hot_W1759_Enabled: [false],
      meals_Frozen_W1760_Enabled: [false],
      meals_Sandwich_W1761_Enabled: [false],
      meals_Emergency_W1762_Enabled: [false],
      meals_Special_W1764_Enabled: [false],

      mealPrepInvoicingRates: this.fb.group({
        keystone: [0],
        upmc: [0],
        pahw: [0],
        unauthorizedDiscount: [0],
        ineligibleDiscount: [0],
      }),

      mealInvoicingInfo: this.fb.group({
        invoicingEntityName: [],
        invoicingEntityAddress: [],
        invoicingEntityPhone: [],
        invoicingEntityEmail: [],
        invoicingTerms: [],
      }),

      signatureByPictureEnabled: [true],
      smsToClientOnMealDropOff: [false],

      frequentUpdate: [false],
      certificationOnSignModify: [false],

      driverCanCollectSignatures: [true],
      driverCanCollectTemperature: [true],
      enableGeotabZoneDropOffReminder: [false],
      enablePrematureGeoZoneDropOffNotification: [false],

      serviceType: [SERVICE_TYPE.PARATRANSIT],
      vehicleLocationList: [[]],
      enableMtmIntegration: false,
      //
      fleetSetupWinterReady: [false],
      fleetSetupDisinfectionRequired: [false],
      fleetSetupCovid19Mode: [false],
      enforceDriverSchedule: [false],
      automaticallyCalculateDriverStartTime: [false],
      isDepartureEnabled: [false],

      broadcastPostCIMsg: [],
      broadcastMsgAckBtnTxt: [],

      adcDaysOpen: [{}],
      adcDailyShifts: [],
      adcCovidReporting: [false],
      showUncollectedTemperaturePrompt: [false],
      backup2AssignedAfter: [3],

      adcAttendanceReportingMode: ['none'],
      adcAttendanceSummary: [true],
      adcAttendanceSummary$: [true],
      adcMealsReportingMode: [[]],
      cacfpNumber: [],
      adcCovidReportingMode: ['none'],
      adcCovidReportingMode2: ['none'],

      adcCovid19Measures: [''],
      adcSignatureByQr: [false],
      qrAdcArrivalTimeOverride: [false],
      qrAdcDepartureTimeOverride: [false],
      tripCancelReasons: [[]],
      tripDurationMin: [5], // minutes

      signatureByQr: [false],
      dropOffByQrOnly: [false],
      bypassCode: [],

      showGt1hourTripAlert: [false],
      showGt2hourTripAlert: [false],
      companyName: [''],
      companyLogoSmall: [{}],
      companyLogoLarge: [{}],
    });

    this.form = this.fb.group(this.formConfigMap.get(''));

    this.defaultData = this.form.value;

    if (this.config.config) {
      this.form.reset(this.config.config);
    }
  }

  private getAvailableReports() {
    return [
      { ID: PRINT_MODE.PRINT_ATTENDANCE_ALL, name: 'Print Attendance Report - All Clients', desc: '' },
      // {ID: PRINT_MODE.PRINT_ATTENDANCE_BY_DRIVER, name: 'Print Attendance Report - By Driver', desc: ''},

      { ID: PRINT_MODE.DISPATCHER_LOG, name: 'Dispatcher`s Log', desc: '' },
      { ID: PRINT_MODE.ROUTE_ADHERENCE_LOG, name: 'Route Adherence Log', desc: '' },
      { ID: PRINT_MODE.ATTENDANCE, name: 'ADC Signatures', desc: '' },

      { ID: PRINT_MODE.MERCY, name: 'MERCY Signatures', desc: '' },
      { ID: PRINT_MODE.MERCY_PER_DRIVER, name: 'MERCY Signatures By Driver', desc: '' },

      { ID: PRINT_MODE.MANIFEST, name: 'Manifest', desc: '' },
      { ID: PRINT_MODE.MANIFEST_PER_DRIVER, name: 'Manifest By Driver', desc: '' },

      { ID: PRINT_MODE.VEHICLE_INSPECTION_SHEET, name: 'Vehicle Inspection Sheet', desc: '' },
      {
        ID: PRINT_MODE.DRIVERS_DAILY_BUNDLE,
        name: 'Drivers Daily Bundle',
        desc: 'Vehicle Inspection Sheet, Manifest and MERCY Signatures by driver reports',
      },

      { ID: PRINT_MODE.MERCY_PER_DRIVER_WITH_DROP_OFF, name: 'Manifest By Driver With Drop Off Time', desc: '' },
      { ID: PRINT_MODE.MERCY_SIGN_IN_FORM_WO_TIMES, name: 'Print Mercy Signatures - All Clients No Times', desc: '' },
      {
        ID: PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES,
        name: 'Mercy Signatures - All Clients (Times By Hand)',
        desc: '',
      },
      {
        ID: PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER_WO_CALC_TIMES,
        name: 'Mercy Signatures - By Driver (Times By Hand)',
        desc: '',
      },
      {
        ID: PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE,
        name: 'Mercy Signatures - One Client (Times By Hand)',
        desc: '',
      },
      {
        ID: PRINT_MODE.MERCY_SIGN_IN_FORM_WO_CALC_TIMES_FOR_ONE_FILLED,
        name: 'Mercy Signatures - One Client (Filled Up)',
        desc: '',
      },

      { ID: PRINT_MODE.MERCY_SIGN_IN_FORM, name: 'Sign-In Form - All Clients', desc: '' },
      { ID: PRINT_MODE.MERCY_SIGN_IN_FORM_PER_DRIVER, name: 'Sign-In Form - By Driver', desc: '' },
      { ID: PRINT_MODE.MERCY_SIGN_IN_FORM_OVERALL, name: 'Sing-In Form - Empty Page', desc: '' },
      //      {ID: PRINT_MODE.MERCY_SIGN_IN_FORM_NATIVE, name: 'Sign-In Form - Native Lang', desc: ''},
    ];
  }
}
