import { Component, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { intersectionBy } from 'lodash-es';
import isNil from 'lodash-es/isNil';
import uniqBy from 'lodash-es/uniqBy';
import moment, { utc } from 'moment';
import { from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoByKey } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { hAll } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseFormComponent } from '../../../../shared/modules/ui/components/abstract/a-base-form.component';
import { ABaseModelPaneWToolbarComponent } from '../../../../shared/modules/ui/components/abstract/a-base-model-pane-w-toolbar.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import {
  EmployeeDayService,
  EmployeeDayServiceApi,
  FacilityApi,
  LoggerService,
  LoopBackFilter,
  MobileDevice,
  MobileDeviceApi,
  MyUtilsApi,
  Person,
  PersonApi,
  PostShiftReport,
  VehicleCheckUp,
  VehicleCheckUpApi,
} from '../../../../shared/sdk';
import { HelperService } from '../../../consumer/services/helper.service';

@Component({
  selector: 'app-daily-checks-details-tab-info',
  templateUrl: './daily-checks-details-tab-info.component.html',
  styleUrls: ['./daily-checks-details-tab-info.component.scss'],
})
export class DailyChecksDetailsTabInfoComponent
  extends ABaseModelPaneWToolbarComponent<VehicleCheckUp, VehicleCheckUpApi>
  implements OnInit, OnChanges, OnDestroy
{
  showAdd = false;
  showEdit = false;

  fullDoc:
    | (VehicleCheckUp & {
        checkout: PostShiftReport & { _vehicleUsageDuration: number };
        _ci_picturesEntries;
        _co_picturesEntries;
        _baseEmRelations;
        _mobileDevice: MobileDevice;
        _pi: Date;
        _po: Date;
        _shiftDuration: number;
      })
    | undefined;
  descAndReport: { ci?: any[]; co?: any[] } | undefined;
  descAndReportMap: { ci?: Map<string, any>; co?: Map<string, any> } | undefined;
  summary = {
    expirations: [],
    issues: [],
    missingStuff: [],
    other: [],
  };

  constructor(
    public config: ConfigService,
    protected logger: LoggerService,
    protected ui: UiService,
    protected helper: HelperService,
    protected dss: DataSourceService,
    protected dialog: MatDialog,
    private pusher: PusherService,
    private utilsApi: MyUtilsApi,
    private fApi: FacilityApi,
  ) {
    super(logger, ui, dss);

    this.caption = 'Summary';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected get ModelClass(): any {
    return VehicleCheckUp;
  }

  protected get filter(): LoopBackFilter {
    return { include: ['vehicle', { employee: ['person'] }, 'user'] };
  }

  protected async beforeModelLoadingAsync(id: number | string): Promise<void> {
    this.fullDoc = undefined;
    this.descAndReport = undefined;
    this.descAndReportMap = { ci: new Map(), co: new Map() };
    this.summary = {
      expirations: [],
      issues: [],
      missingStuff: [],
      other: [],
    };

    return super.beforeModelLoadingAsync(id);
  }

  private getAdministrativeContacts() {
    const rels = oc(this.model).meta.baseEMPersons([]);
    const personApi = this.dss.getApi<PersonApi>(Person);

    return from(
      Promise.all<Person>(rels.map(p => personApi.getRevision(p.personId, this.model.dateTime, hAll).toPromise())),
    ).pipe(
      tap(persons =>
        persons.forEach(p => {
          const rel = rels.find(r => r.personId === p.id);
          p.data._relation = oc(rel).relation();
        }),
      ),
      map(persons => persons.filter(p => ['Safety Director', 'Fleet Manager'].includes(p.data._relation))),
      catchError(err => of([] as Person[])),
    );
  }

  protected async afterModelLoadedAsync(model: VehicleCheckUp): Promise<void> {
    if (this.modelId) {
      try {
        this.loading = true;

        this.fullDoc = await gqlMongoByKey(this.dss, 'VehicleChecks_view', this.modelId).toPromise();
        this.descAndReport = await this.api.getAllFieldsDescriptions(this.modelId).toPromise();
        this.descAndReportMap = {
          ci: new Map((this.descAndReport.ci || []).map(itm => [itm.field, itm] as [string, any])),
          co: new Map((this.descAndReport.co || []).map(itm => [itm.field, itm] as [string, any])),
        };

        this.fullDoc._baseEmRelations = await this.getAdministrativeContacts().toPromise();

        this.fullDoc._mobileDevice = await this.dss
          .getApi<MobileDeviceApi>(MobileDevice)
          .findOne<any>({
            where: {
              deviceUid: this.model.deviceUid,
            },
          })
          .toPromise();

        if (this.fullDoc.checkout) {
          this.fullDoc.checkout._vehicleUsageDuration = utc(this.fullDoc.checkout.completedDateTime).diff(
            this.fullDoc.startedDateTime,
            'minutes',
          );
        }

        const _moment = moment(model.dateTime);
        const shiftData: { pi: string; po: string } = await this.dss
          .getApi<EmployeeDayServiceApi>(EmployeeDayService)
          .getShiftData(this.model.employeeId, _moment.format('YYYY-MM-DD'), [
            'PUNCH-IN',
            'PUNCH-OUT',
            'PAUSE',
            'RESUME',
          ])
          .toPromise();

        this.fullDoc._pi = shiftData.pi ? moment(shiftData.pi).toDate() : undefined;
        this.fullDoc._po = shiftData.po ? moment(shiftData.po).toDate() : undefined;
        this.fullDoc._shiftDuration =
          this.fullDoc._po && this.fullDoc._pi ? moment(this.fullDoc._po).diff(this.fullDoc._pi, 'minutes') : undefined;

        const sharedFields = intersectionBy(oc(this).descAndReport.ci([]), oc(this).descAndReport.co([]), 'field');

        const allCiValueIssues = this.descAndReport.ci.filter(
          meta => !isNil(meta.value.value) && (meta.value.issues || []).some(i => i.type === 'value'),
        );
        const allCoValueIssues = this.descAndReport.co.filter(
          meta => !isNil(meta.value.value) && (meta.value.issues || []).some(i => i.type === 'value'),
        );

        // const filteredCiValueIssues = differenceBy(allCiValueIssues, sharedFields, 'field');

        // const allValueIssues = [...filteredCiValueIssues, ...allCoValueIssues];
        const allValueIssues = [...allCiValueIssues, ...allCoValueIssues];

        this.summary.expirations = uniqBy(
          allValueIssues.filter(meta => meta.desc.type === 'date' && meta.desc.daysExpWarning),
          'field',
        ).map(meta => [meta.field, meta.value.issues.find(i => i.type === 'value')]);

        this.summary.issues = uniqBy(
          allValueIssues.filter(meta => meta.desc.reportIssue),
          'field',
        ).map(meta => [meta.field, meta.value.issues.find(i => i.type === 'value')]);

        this.summary.missingStuff = uniqBy(
          allValueIssues.filter(meta => meta.desc.reportMissingStuff),
          'field',
        ).map(meta => [meta.field, meta.value.issues.find(i => i.type === 'value')]);

        this.summary.other = allValueIssues
          .filter(
            meta =>
              ![...this.summary.expirations, ...this.summary.issues, ...this.summary.missingStuff]
                .map(itm => itm[0])
                .includes(meta.field),
          )
          .map(meta => [meta.field, meta.value.issues.find(i => i.type === 'value')]);
      } finally {
        this.loading = false;
      }
    }

    // console.log(this.descAndReport);
    return super.afterModelLoadedAsync(model);
  }

  protected get FormComponent(): Type<ABaseFormComponent<VehicleCheckUp>> {
    return undefined;
  }
}
